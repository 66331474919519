<script>
  export let topView
  export let closeSelf
  export let closeHigher
  export let openAndReplace

  import ResourceView from '../components/ResourceView.svelte'
  import ViewAuditDetails from './ViewAuditDetails.svelte'
  import { Icon, Button } from 'svelma'
  import { dateToYmdString, formatCustomDate, numPad } from '../lib/utils'
  import SelectField from '../components/SelectField.svelte'
  import FormField from '../components/FormField.svelte'
  import { monthsEntries } from '../lib/months'
  import { tick } from 'svelte'
  import { apiUrl } from '../lib/env'

  const augmentedMonthsEntries = [
    ...monthsEntries,
    ['q1', '1. Quartal'],
    ['q2', '2. Quartal'],
    ['q3', '3. Quartal'],
    ['q4', '4. Quartal'],
    ['y', 'Ganzes Jahr']
  ]

  const auditTypeTabs = {
    all: 'Alle',
    regular: 'Regulär',
    civil: 'Ziviltechniker'
  }

  let auditTypeTab = 'regular'

  // There are too many things with "load" in the name... :-|
  let tab = 'open'
  let loadingPromise
  let load
  let loaded
  let items = []

  let loading = false

  const today = new Date()
  const todayYmd = dateToYmdString(today)
  let month = today.getMonth() + 1
  let year = today.getFullYear()
  let includeOldOpen = true

  $: filterStartDate = `${numPad(Number(year) || today.getFullYear(), 4)}-${numPad({ q1: 1, q2: 4, q3: 7, q4: 10, y: 1 }[month] ?? month, 2)}-00`
  $: filterEndDate = `${numPad(Number(year) || today.getFullYear(), 4)}-${numPad({ q1: 3, q2: 6, q3: 9, q4: 12, y: 12 }[month] ?? month, 2)}-99`

  async function reload () {
    await tick()
    loadingPromise = load()
  }

  async function generateReport () {
    loading = true

    try {
      window.open(`${apiUrl}/audits/list?ids=${getItems(items, tab).map(item => encodeURIComponent(item.id)).join(',')}`)
    } finally {
      loading = false
    }
  }

  function getItems (items, tab) {
    if (auditTypeTab !== 'all') items = items.filter(item => item.type === auditTypeTab)

    if (tab === 'all') return items
    if (tab === 'open') return items.filter(item => item.status === 'planned' || item.status === 'inProgress')
    if (tab === 'waitingForPublication') return items.filter(item => item.status === 'completed')
    if (tab === 'unbilled') return items.filter(item => item.status === 'published' && !item.billed)
    if (tab === 'other') return items.filter(item => item.status !== 'planned' && item.status !== 'inProgress' && item.status !== 'completed' && !(item.status === 'published' && !item.billed))

    return []
  }
</script>

<ResourceView
  {topView} {closeSelf} {closeHigher} {openAndReplace}
  title="Wartungsplan"
  width={500}
  order="startDate"
  filter={JSON.stringify({
    $or: [{
      startDate: { $gte: filterStartDate, $lte: filterEndDate }
    }, {
      endDate: { $gte: filterStartDate, $lte: filterEndDate }
    }, {
      startDate: { $lte: filterEndDate },
      endDate: { $gte: filterStartDate }
    }, ...includeOldOpen
      ? [{
        startDate: { $lte: filterEndDate },
        status: { $in: ['planned', 'inProgress', 'completed'] }
      }, {
        startDate: { $lte: filterEndDate },
        status: 'published',
        billed: { $ne: true }
      }]
      : []
    ]
  })}
  select="type,startDate,endDate,user,property,status,billed,month,year"
  populate={JSON.stringify([['user', 'fullName'], { path: 'property', select: 'name,customer', populate: { path: 'customer', select: 'company,customerNo' } }])}
  {getItems}
  bind:items
  childIcon="calendar-check"
  childResourcePath="/audits"
  childResourceName="Prüftermin"
  childResourceGender="m"
  ViewChildDetails={ViewAuditDetails}
  childDetailsProps={{ isolated: true }}
  tabs={{ all: 'Alle', open: 'Offen', waitingForPublication: 'Freigabe wartend', unbilled: 'Abzurechnen', other: 'Andere' }}
  bind:tab
  bind:loadingPromise
  bind:load
  bind:loaded
  search={(searchQuery, searchRegex, record) => record.user?.username?.match(searchRegex) || record.user?.fullName?.match(searchRegex) || record.property?.name?.match(searchRegex) || record.property?.customer?.company?.match(searchRegex) || record.property?.customer?.customerNo === Number(searchQuery) || (record.startDate && formatCustomDate(record.startDate).match(searchRegex)) || (record.endDate && formatCustomDate(record.endDate).match(searchRegex))}
  let:record
  allowCreate={false}
>
  <svelte:fragment slot="header">
    <div class="panel-block">
      <div class="container">
        <div class="columns is-mobile">
          <SelectField icon="calendar-alt" col=6 bind:value={month} options={augmentedMonthsEntries} on:input={() => { includeOldOpen = false; reload() }} />
          <FormField type="number" icon="calendar" col=6 bind:value={year} on:input={() => { includeOldOpen = false; reload() }} />
        </div>
      </div>
    </div>

    <label class="panel-block">
      <input type="checkbox" bind:checked={includeOldOpen} on:change={() => reload()}>
      + ältere, unfertige Termine
    </label>
  </svelte:fragment>

  <p slot="belowSearch" class="panel-tabs">
    {#each Object.entries(auditTypeTabs) as [id, label] (id)}
      <a class:is-active={auditTypeTab === id} href={undefined} on:click={() => (auditTypeTab = id)}>{label}</a>
    {/each}
  </p>

  <span class="list-entry" class:is-disabled={record.status === 'cancelled'} class:has-text-danger={record.startDate < todayYmd && (!record.endDate || record.endDate < todayYmd) && record.status !== 'cancelled' && record.status !== 'published' && record.status !== 'completed'}>
    {record.property?.customer?.company}: {record.property?.name}
    {#if record.status === 'inProgress'}
      <span title="In Arbeit" class="has-text-warning"><Icon icon="hourglass-half" /></span>
    {/if}
    {#if record.status === 'completed'}
      <span title="Abgeschlossen" class="has-text-success"><Icon icon="check" /></span>
    {/if}
    {#if record.status === 'published'}
      <span title="Freigegeben" class="has-text-info"><Icon icon="check-double" /></span>
    {/if}
    <br />
    <small>
      {formatCustomDate(record.startDate)}
      {#if record.endDate && record.endDate !== record.startDate}
        {' - '}
        {formatCustomDate(record.endDate)}
      {/if}
      {#if !record.startDate?.startsWith(`${numPad(record.year, 4)}-${numPad(record.month, 2)}`)}
        ({numPad(record.month, 2)}.{numPad(record.year, 4)})
      {/if}
      {#if record.type === 'civil'}
        (<strong>Ziviltechniker</strong>)
      {:else if record.user}
        ({record.user.fullName})
      {/if}
    </small>
  </span>

  <svelte:fragment slot="footer">
    <div class="panel-block">
      <Button iconLeft="print" type="is-primary" outlined class="is-fullwidth" disabled={!loaded} on:click={generateReport} {loading}>Liste drucken</Button>
    </div>
  </svelte:fragment>
</ResourceView>
