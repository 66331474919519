<script>
  export let error

  export let frontendVersion
  export let serverVersion

  import ErrorDisplay from './components/ErrorDisplay.svelte'
  import { Button } from 'svelma'
</script>

<style lang="scss">
  .container {
    margin: auto;
    padding: 3rem;

    z-index: 9999;
    background: white;
  }

  :global(body > .notice) {
    display: none !important;
  }
</style>

<div class="container">
  <ErrorDisplay title="Ein schwerer Fehler ist aufgetreten!" {error} />

  <p>
    Die Applikation muss neu geladen werden. Ungespeicherte Änderungen (falls vorhanden) gehen dabei leider verloren.
  </p>

  <div class="level">
    <div class="level-left">
      <small>F {frontendVersion} / S {serverVersion ?? '-'}</small>
    </div>
    <div class="level-right">
      <div class="buttons is-right">
        <Button type="is-primary" on:click={() => location.reload()}>Neu laden</Button>
      </div>
    </div>
  </div>
</div>
