<script>
  export let label = null
  export let icon = null
  export let type = 'text'
  export let passwordReveal = false
  export let autocomplete = null
  export let value = null
  export let error = null
  export let hint = null
  export let col = null
  export let placeholder = null
  export let readonly = false
  export let disabled = false
  export let inputClass = null
  export let min = null
  export let max = null
  export let step = null
  export let prefix = null
  export let suffix = null
  export let presets = []

  import { Field, Input, Button, Icon } from 'svelma'

  let dropdownElement
  let presetsOpen = false
  $: if (!presets?.length || disabled || readonly) presetsOpen = false

  $: if (value === undefined) value = null

  function togglePresets () {
    presetsOpen = !presetsOpen
  }

  function bodyClick (event) {
    if (event.target.closest('.dropdown') !== dropdownElement) presetsOpen = false
  }
</script>

<style lang="scss">
  .field-body {
    width: 100%;

    .field {
      width: 100%;
    }

    &.dropdown .field.has-addons {
      margin-right: 0;
    }

    .dropdown-menu {
      padding-top: 0;
      width: 100%;
      padding-left: 1px;
      padding-right: 1px;

      .dropdown-item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        padding-right: 1rem !important;
      }
    }
  }
</style>

<svelte:body on:click={bodyClick} />

{#key error}
  <Field {label} type={error ? 'is-danger' : ''} message={error || hint} class="{$$props.class ?? ''} {col ? `column is-${col}` : ''}">
    {#if prefix || suffix || (presets?.length && !readonly)}
      <div class="field-body" class:dropdown={presets?.length && !readonly} class:is-active={presetsOpen} bind:this={dropdownElement}>
        <div class="field has-addons">
          {#if prefix}
            <div class="control">
              <Button type="is-static">{prefix}</Button>
            </div>
          {/if}
          <Input {type} bind:value {icon} class="{error ? 'is-danger' : ''} {inputClass ?? ''}" on:change on:input {...readonly ? { readonly: true } : {}} {...disabled ? { disabled: true } : {}} {...step ? { step } : {}} {...min ? { min } : {}} {...max ? { max } : {}} {...placeholder ? { placeholder } : {}} on:focus expanded />
          {#if presets?.length && !readonly}
            <div class="control dropdown-trigger">
              <Button on:click={togglePresets} {disabled}><Icon icon="chevron-down" /></Button>
            </div>
          {/if}
          {#if suffix}
            <div class="control">
              <Button type="is-static">{suffix}</Button>
            </div>
          {/if}
        </div>
        {#if presets?.length && !readonly}
          <div class="dropdown-menu" role="menu">
            <div class="dropdown-content">
              {#each presets as preset}
                <a href={undefined} class="dropdown-item" on:click={() => { value = preset; presetsOpen = false }}>{preset}</a>
              {/each}
            </div>
          </div>
        {/if}
      </div>
    {:else}
      <Input {type} bind:value {icon} class="{error ? 'is-danger' : ''} {inputClass ?? ''}" on:change on:input {passwordReveal} {...autocomplete ? { autocomplete } : {}} {...readonly ? { readonly: true } : {}} {...disabled ? { disabled: true } : {}} {...step ? { step } : {}} {...min ? { min } : {}} {...max ? { max } : {}} {...placeholder ? { placeholder } : {}} on:focus />
    {/if}
  </Field>
{/key}
