<script>
  export let topView
  export let closeSelf
  export let closeHigher
  export let openAndReplace

  export let id
  export let title
  export let onSave
  export let isolated = false
  export let openedFrom = null

  import ResourceView from '../components/ResourceView.svelte'
  import { apiCall } from '../lib/api'
  import { appInfo } from '../lib/appInfo'
  import { escape, formatCustomDate } from '../lib/utils'
  import ViewCustomerDetails from './ViewCustomerDetails.svelte'
  import ViewPropertyDetails from './ViewPropertyDetails.svelte'
  import ViewUserForm from './ViewUserForm.svelte'
  import ViewAuditDetails from './ViewAuditDetails.svelte'
  import { Icon, Toast, Dialog } from 'svelma'
  import { tick } from 'svelte'
  import Detail from '../components/Detail.svelte'

  let selected
  let silentReload
  let actionInProgress

  async function openCustomer (customer) {
    if (!customer) throw new Error('No customer set')
    selected = null
    await tick()
    openAndReplace(ViewCustomerDetails, { id: customer.id, onSave: silentReload, isolated: true })
  }

  async function openProperty (property) {
    if (!property) throw new Error('No property set')
    selected = null
    await tick()
    openAndReplace(ViewPropertyDetails, { id: property.id, onSave: silentReload, isolated: true })
  }

  async function suspend (mainRecord) {
    actionInProgress = true
    try {
      await apiCall('PATCH', `/users/${id}`, { suspended: true })
      Toast.create({ message: `Zugang für ${mainRecord.fullName} wurde deaktiviert.`, type: 'is-success' })
      silentReload({ id })
    } catch (e) {
      console.error(e)
      Dialog.alert({ message: escape(e.serverErrorMessage || `Die Änderungen konnten nicht gespeichert werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
    } finally {
      actionInProgress = false
    }
  }

  async function unsuspend (mainRecord) {
    actionInProgress = true
    try {
      await apiCall('PATCH', `/users/${id}`, { suspended: false })
      Toast.create({ message: `Zugang für ${mainRecord.fullName} wurde aktiviert.`, type: 'is-success' })
      silentReload({ id })
    } catch (e) {
      console.error(e)
      Dialog.alert({ message: escape(e.serverErrorMessage || `Die Änderungen konnten nicht gespeichert werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
    } finally {
      actionInProgress = false
    }
  }
</script>

<ResourceView
  {topView} {closeSelf} {closeHigher} {openAndReplace}
  {id} {title}
  icon="user"
  defaultTitle="Benutzerdetails"
  {onSave}
  bind:actionInProgress
  mainPopulate={JSON.stringify([['customer', 'company customerNo'], ['properties', 'name']])}
  getTitle={record => `${record.fullName} (${record.username})`}
  childIcon="calendar-check"
  handleLoad={async (id, mainRecord) => {
    let filter
    if (mainRecord.isCustomer) {
      let propertyIds
      if (mainRecord.limitProperties) {
        propertyIds = mainRecord.properties.map(p => p.id)
      } else {
        const properties = await apiCall('GET', '/properties', {
          filter: JSON.stringify({ customer: mainRecord.customer.id }),
          select: 'id'
        })

        propertyIds = properties.map(({ id }) => id)
      }

      filter = JSON.stringify({
        property: { $in: propertyIds },
        status: 'published'
      })
    } else {
      filter = JSON.stringify({ user: id })
    }

    const items = await apiCall('GET', '/audits', {
      order: '-startDate',
      select: 'property,startDate,endDate,status',
      filter,
      populate: JSON.stringify([['property', 'name']])
    })

    return { items }
  }}
  resourcePath="/users"
  resourceName="Benutzer"
  childResourceName="Prüftermin"
  childResourceGender="m"
  ViewMainForm={ViewUserForm}
  ViewChildDetails={ViewAuditDetails}
  childDetailsProps={{ isolated: true }}
  search={(searchQuery, searchRegex, record) => (record.startDate && formatCustomDate(record.startDate).match(searchRegex)) || (record.endDate && formatCustomDate(record.endDate).match(searchRegex)) || record.property?.name?.match(searchRegex)}
  allowCreate={false}
  allowEdit={true}
  allowDelete={true}
  canDelete={(mainRecord, items) => appInfo.user.id !== mainRecord.id && (mainRecord.isCustomer || !items.length)}
  readonly={!appInfo.user.admin}
  hideId={appInfo.user.isCustomer}
  let:record
  bind:silentReload
  bind:selected
>
  <svelte:fragment slot="header" let:mainRecord>
    {#if mainRecord}
      {#if mainRecord.admin}
        <div class="panel-block">
          <strong>Administrator</strong>
          <span title="Administrator"><Icon icon="star" /></span>
        </div>
      {:else if mainRecord.isCustomer}
        {#if isolated || openedFrom !== 'customer'}
          <div class="panel-block">
            <Detail title="Kunde" icon="industry">
              <a href={undefined} on:click={() => openCustomer(mainRecord.customer)}>{mainRecord.customer?.company} (#{mainRecord.customer?.customerNo})</a>
            </Detail>
          </div>
        {/if}
        <div class="panel-block">
          <Detail title="Liegenschaften" icon="building">
            {#if mainRecord.limitProperties}
              {#if !mainRecord.properties?.length}
                Keine Liegenschaften zugewiesen!
              {:else}
                {#each mainRecord.properties?.slice(0, 3) ?? [] as property, i}
                  <a href={undefined} on:click={() => openProperty(property)}>{property.name}</a>{i < Math.min(mainRecord.properties.length, 3) - 1 ? ', ' : ''}
                {/each}
                {#if mainRecord.properties.length > 3}
                  <span> und {mainRecord.properties.length - 3} weitere</span>
                {/if}
              {/if}
            {:else}
              Alle Liegenschaften des Kunden
              <span title="Zugriff auf alle Liegenschaften"><Icon icon="crown" /></span>
            {/if}
          </Detail>
        </div>
      {:else}
        <div class="panel-block">
          Regulärer Mitarbeiter
        </div>
      {/if}
      {#if mainRecord.suspended}
        <div class="panel-block">
          <span class="has-text-danger">Zugang gesperrt</span>
        </div>
      {/if}
    {:else}
      <div class="panel-block">...</div>
    {/if}
  </svelte:fragment>

  <svelte:fragment slot="actions" let:mainRecord>
    {#if mainRecord && mainRecord.id !== appInfo.user.id && appInfo.user.admin}
      {#if mainRecord.suspended}
        <a href={undefined} class="dropdown-item" on:click={() => unsuspend(mainRecord)}><Icon icon="unlock" /> Zugang aktivieren</a>
      {:else}
        <a href={undefined} class="dropdown-item" on:click={() => suspend(mainRecord)}><Icon icon="lock" /> Zugang deaktivieren</a>
      {/if}
    {/if}
  </svelte:fragment>

  <span class="list-entry" class:is-disabled={record.status === 'cancelled'}>
    {formatCustomDate(record.startDate)}
    {#if record.endDate && record.endDate !== record.startDate}
      {' - '}
      {formatCustomDate(record.endDate)}
    {/if}
    {#if record.status === 'inProgress'}
      <span title="In Arbeit" class="has-text-warning"><Icon icon="hourglass-half" /></span>
    {/if}
    {#if record.status === 'completed'}
      <span title="Abgeschlossen" class="has-text-success"><Icon icon="check" /></span>
    {/if}
    {#if record.status === 'published'}
      <span title="Freigegeben" class="has-text-info"><Icon icon="check-double" /></span>
    {/if}
    <br />
    <small>{record.property?.name}</small>
  </span>
</ResourceView>
