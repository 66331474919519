<script>
  export let label = null
  export let icon = null
  export let value = null
  export let error = null
  export let hint = null
  export let col = null
  export let readonly = false
  export let disabled = false
  export let min = null
  export let max = null
  export let omitContainer = false

  import { Field, Input } from 'svelma'
  import { onMount, createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  let internalValue
  $: {
    const newValue = value ?? ''
    if (internalValue !== newValue) internalValue = newValue
  }

  function handleChange (event) {
    // Not sure why but we sometimes see event.target and sometimes event.detail.target
    internalValue = ((event.target || event.detail.target).value)

    const newValue = internalValue || null
    if (value !== newValue) value = ((event.target || event.detail.target).value)

    dispatch(event.type, event.detail ?? event)
  }

  // This is a super ugly workaround for the problem that the way Svelma's <Input> is implemented, any action like typing or even focusing causes all the passed extra props
  // to become dirty somehow, and this ends up setting min/max again every time, which unfortunately resets the cursor position while typing a date.
  const id = `workaroundId${Math.floor(Math.random() * 1e9)}`
  onMount(() => {
    class HookedInputElement extends HTMLInputElement {
      get min () {
        return super.min
      }

      get max () {
        return super.max
      }

      set min (v) {
        if (this.min !== v) super.min = v
      }

      set max (v) {
        if (this.max !== v) super.max = v
      }
    }

    const element = document.getElementById(id)
    if (element && Object.getPrototypeOf(element) === HTMLInputElement.prototype) {
      Object.setPrototypeOf(element, HookedInputElement.prototype)
    }
  })
</script>

{#if omitContainer}
  <!-- Not using bind:value here because this caused resetting the cursor inside the numbers! -->
  <Input type="date" value={internalValue ?? ''} {icon} class={error ? 'is-danger' : ''} {id} on:change={handleChange} on:input={handleChange} {...readonly ? { readonly: true } : {}} {...disabled ? { disabled: true } : {}} {...min ? { min } : {}} {...max ? { max } : {}} />
{:else}
  {#key error}
    <Field {label} type={error ? 'is-danger' : ''} message={error || hint} class="{$$props.class ?? ''} {col ? `column is-${col}` : ''}">
      <!-- Not using bind:value here because this caused resetting the cursor inside the numbers! -->
      <Input type="date" value={internalValue ?? ''} {icon} class={error ? 'is-danger' : ''} {id} on:change={handleChange} on:input={handleChange} {...readonly ? { readonly: true } : {}} {...disabled ? { disabled: true } : {}} {...min ? { min } : {}} {...max ? { max } : {}} />
    </Field>
  {/key}
{/if}
