<script>
  export let topView
  export let closeSelf
  export let closeHigher
  export let openAndReplace

  import ResourceView from '../components/ResourceView.svelte'
  import ViewCustomerDetails from './ViewCustomerDetails.svelte'
  import ViewCustomerForm from './ViewCustomerForm.svelte'
  import { appInfo } from '../lib/appInfo'

  let items = []
  $: byName = items.slice().sort((a, b) => (a.company ?? '').localeCompare(b.company ?? ''))
</script>

<style lang="scss">
  .logo {
    width: 2em;
    height: 1.5em;
    object-fit: contain;
    margin-left: 10px;
  }
</style>

<ResourceView
  {topView} {closeSelf} {closeHigher} {openAndReplace}
  title="Kundenliste"
  getChildTitle={record => record.company ? `${record.company} (#${record.customerNo})` : undefined}
  order="-customerNo"
  select="customerNo,company,logoUrl"
  bind:items
  getItems={(items, tab) => tab === 'byName' ? byName : items}
  childIcon="industry"
  childResourcePath="/customers"
  childResourceName="Kunde"
  childResourceGender="m"
  ViewChildDetails={ViewCustomerDetails}
  ViewChildForm={ViewCustomerForm}
  tabs={{ byName: 'Nach Name', byCustomerNo: 'Nach Kundennr.' }}
  search={(searchQuery, searchRegex, record) => record.company?.match(searchRegex) || record.customerNo === Number(searchQuery)}
  readonly={!appInfo.user.admin}
  hideId={appInfo.user.isCustomer}
  let:record
>
  <span class="list-entry">{record.company} (#{record.customerNo})</span>
  {#if record.logoUrl}
    <img src={record.logoUrl} class="logo" alt="Logo" />
  {/if}
</ResourceView>
