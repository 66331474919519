<script>
  export let icon = null
  export let iconPack = 'fa'
  export let active = false

  // To simplify usage of PaneRow, instead of manually setting active and listening on click, we
  // can set an `id` and just forward {select} {selected}
  export let id // undefined
  export let selected
  export let select = () => {}
  let prevSelected // undefined

  import { createEventDispatcher, onMount } from 'svelte'
  const dispatch = createEventDispatcher()

  let mounted = false
  onMount(() => {
    mounted = true
    return () => {
      mounted = false
    }
  })

  $: if (mounted && id !== undefined && selected !== undefined) {
    active = id === selected

    if (selected !== prevSelected) {
      prevSelected = selected
      if (active) dispatch('select')
    }
  }
</script>

<style lang="scss">
  .panel-block.is-active {
    font-weight: bold;

    @media (hover: none) {
      background-color: #f8f8f8;
    }

    @media not all and (hover: none) {
      &:not(:hover) {
        background-color: #f8f8f8;
      }
    }
  }
</style>

<a class="panel-block {$$props.class}" class:is-active={active} href={undefined} on:click on:click={() => select(id)}>
  {#if icon}
    <span class="panel-icon">
      <i class="{iconPack} fa-{icon}" aria-hidden="true"></i>
    </span>
  {/if}
  <slot />
</a>
