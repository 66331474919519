<script>
  export let closeSelf
  export let onSave = () => {}

  import ResourceForm from '../components/ResourceForm.svelte'
  import { trimFields } from '../lib/validation'
  import FormField from '../components/FormField.svelte'
  import auditObjectTypes from '../lib/auditObjectTypes'
  import { appInfo } from '../lib/appInfo'

  let record
  let fieldErrors = {}
</script>

<style lang="scss">
  hr:last-child {
    display: none;
  }
</style>

<ResourceForm
  {closeSelf} {onSave}
  id='~'
  title="Hinweise und Normen"
  select="auditObjectTypeSpecificSettings,generalAuditNotes"
  validateRecord={async record => {
    trimFields(record, [
      'generalAuditNotes'
    ])

    for (const type of Object.keys(auditObjectTypes)) {
      trimFields(record.auditObjectTypeSpecificSettings[type], [
        'notes',
        'standards'
      ])
    }

    // Currently there can never be field errors here...
    return { record, fieldErrors: {} }
  }}
  resourcePath="/settings"
  resourceName="Einstellungen"
  readonly={!appInfo.user.admin}
  hideId
  bind:record
  bind:fieldErrors
>
  <div class="columns">
    <FormField label="Allgemeine Hinweise für Protokollmappe" type="textarea" col=12 bind:value={record.generalAuditNotes} error={fieldErrors?.generalAuditNotes} />
  </div>

  <hr />

  {#each Object.entries(auditObjectTypes) as [type, label] (type)}
    <div class="columns">
      <FormField label="Allgemeine Hinweise für {label}" type="textarea" col=12 bind:value={record.auditObjectTypeSpecificSettings[type].notes} error={fieldErrors?.auditObjectTypeSpecificSettings?.[type]?.notes} />
    </div>

    <div class="columns">
      <FormField label="Angewendete Normen für {label}" icon="certificate" col=12 bind:value={record.auditObjectTypeSpecificSettings[type].standards} error={fieldErrors?.auditObjectTypeSpecificSettings?.[type]?.standards} />
    </div>

    <hr />
  {/each}
</ResourceForm>
