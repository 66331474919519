<script>
  export let topView
  export let openAndReplace
  export let closeSelf

  import Pane from '../components/Pane.svelte'
  import PaneRow from '../components/PaneRow.svelte'
  import PaneList from '../components/PaneList.svelte'
  import ViewAuditQuestionsForm from './ViewAuditQuestionsForm.svelte'

  let selected
</script>

<Pane title="Prüfkriterien" closeable on:close={closeSelf} width={300}>
  <PaneList bind:selected let:select {topView} on:select={() => { if (selected) openAndReplace(ViewAuditQuestionsForm, { type: selected }) }}>
    <PaneRow {selected} {select} id="door" icon="dice-d6">Automatische Türe</PaneRow>
    <PaneRow {selected} {select} id="gate" icon="dice-d6">Automatisches Tor</PaneRow>
    <PaneRow {selected} {select} id="fireDoor" icon="dice-d6">Brandschutz-/Fluchtwegtüre</PaneRow>
    <PaneRow {selected} {select} id="fireGate" icon="dice-d6">Brandschutztor</PaneRow>
    <PaneRow {selected} {select} id="boomBarrier" icon="dice-d6">Schranken</PaneRow>
  </PaneList>
</Pane>
