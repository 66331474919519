<script>
  export let topView
  export let openAndReplace
  export let closeSelf

  import Pane from '../components/Pane.svelte'
  import PaneRow from '../components/PaneRow.svelte'
  import ViewGeneralSettingsForm from './ViewGeneralSettingsForm.svelte'
  import ViewNotesAndStandardsForm from './ViewNotesAndStandardsForm.svelte'
  import ViewAuditQuestionsList from './ViewAuditQuestionsList.svelte'
  import PaneList from '../components/PaneList.svelte'
</script>

<Pane title="Einstellungen" closeable on:close={closeSelf} width={200}>
  <PaneList let:selected let:select {topView}>
    <PaneRow {selected} {select} id="general" icon="cog" on:select={() => openAndReplace(ViewGeneralSettingsForm)}>Allgemein</PaneRow>
    <PaneRow {selected} {select} id="notesAndStandards" icon="certificate" on:select={() => openAndReplace(ViewNotesAndStandardsForm)}>Hinweise und Normen</PaneRow>
    <PaneRow {selected} {select} id="auditQuestions" icon="tasks" on:select={() => openAndReplace(ViewAuditQuestionsList)}>Prüfkriterien</PaneRow>
  </PaneList>
</Pane>
