<script>
  export let topView
  export let closeSelf
  export let closeHigher
  export let openAndReplace

  import regexEscape from 'regex-escape'

  export let searchQuery
  $: searchRegex = searchQuery?.trim() ? { $regex: `\\b${regexEscape(searchQuery.trim())}`, $options: 'i' } : null

  import ResourceView from '../components/ResourceView.svelte'
  import { apiCall, StatusCodeError } from '../lib/api'
  import { formatCustomDate, dateToYmdString, ellipsis, numPad } from '../lib/utils'
  import ViewAuditDetails from './ViewAuditDetails.svelte'
  import ViewAuditLogDetails from './ViewAuditLogDetails.svelte'
  import ViewAuditObjectDetails from './ViewAuditObjectDetails.svelte'
  import ViewCustomerDetails from './ViewCustomerDetails.svelte'
  import ViewPropertyDetails from './ViewPropertyDetails.svelte'
  import ViewUserDetails from './ViewUserDetails.svelte'
  import ViewServiceLogDetails from './ViewServiceLogDetails.svelte'
  import { appInfo } from '../lib/appInfo'
  import { Notification } from 'svelma'
  import serviceLogTypes from '../lib/serviceLogTypes'

  let items = []
  let silentReload

  let networkError = false
</script>

<ResourceView
  {topView} {closeSelf} {closeHigher} {openAndReplace}
  title="Suchergebnisse für {searchQuery}"
  getChildTitle={record => {
    return record.id // Not the best, but it's OK
  }}
  bind:items
  handleLoad={async (id, mainRecord) => {
    // To be honest, I don't know why eslint complains here. It's not unreachable, in the catch it can set networkError to true.
    // eslint-disable-next-line no-unreachable-loop
    do {
      if (!searchQuery || !searchRegex) return { items: [] }

      const map = async (type, promise) => {
        let items
        try {
          items = await promise
        } catch (e) {
          if (e.code === 'record_not_found' || e.code === 'access_denied') return []
          throw e
        }

        if (!Array.isArray(items)) items = [items]
        for (const item of items) item._type = type
        return items
      }

      const args = {
        customer: { select: 'company,customerNo' },
        property: { select: 'name,customer', populate: [['customer', 'company,customerNo']] },
        auditObject: { select: 'publicId,manufacturer,serialNo,property', populate: [['property', 'name']] },
        audit: { select: 'type,startDate,endDate,month,year,property', populate: [['property', 'name']] },
        auditLog: { select: 'type,date,auditObjectData.publicId,auditObjectData.manufacturer,auditObjectData.serialNo' },
        serviceLog: { select: 'createdAt,type,user,text', populate: [['user', 'fullName']] },
        user: { select: 'fullName,username' }
      }

      const defaults = {
        order: '-updatedAt',
        limit: 20
      }

      let dateSearch
      let timestampSearch
      if (searchQuery.match(/^\d\d?\.\s*\d\d\d\d$/)) {
        const [m, y] = searchQuery.split('.').map(x => x.trim())
        dateSearch = { $regex: `^${numPad(Number(y), 4)}-${numPad(Number(m), 2)}-\\d\\d$` }
        timestampSearch = { $gte: new Date(Number(y), Number(m) - 1, 1).toISOString(), $lt: new Date(Number(y), Number(m) + 1 - 1, 1).toISOString() }
      } else if (searchQuery.match(/^\d\d?\.\s*\d\d?\.\s*\d\d\d\d$/)) {
        const [d, m, y] = searchQuery.split('.').map(x => x.trim())
        dateSearch = `${numPad(Number(y), 4)}-${numPad(Number(m), 2)}-${numPad(Number(d), 2)}`
        timestampSearch = { $gte: new Date(Number(y), Number(m) - 1, Number(d)).toISOString(), $lt: new Date(Number(y), Number(m) - 1, Number(d) + 1).toISOString() }
      }

      try {
        const results = await Promise.all([
          ...searchQuery.match(/^[0-9a-f]{24}$/)
            ? [
              ...!appInfo.user.isCustomer ? [map('customer', apiCall('GET', `/customers/${searchQuery}`, args.customer))] : [],
              map('property', apiCall('GET', `/properties/${searchQuery}`, args.property)),
              map('auditObject', apiCall('GET', `/auditObjects/${searchQuery}`, args.auditObject)),
              ...!appInfo.user.isCustomer ? [map('audit', apiCall('GET', `/audits/${searchQuery}`, args.audit))] : [],
              ...!appInfo.user.isCustomer ? [map('auditLog', apiCall('GET', `/auditLogs/${searchQuery}`, args.auditLog))] : [],
              ...appInfo.user.admin ? [map('user', apiCall('GET', `/users/${searchQuery}`, args.user))] : []
            ]
            : [],
          ...!appInfo.user.isCustomer ? [map('customer', apiCall('GET', '/customers', { ...defaults, ...args.customer, filter: JSON.stringify({ $or: [{ company: searchRegex }, { customerNo: Number(searchQuery.replace(/^#/, '')) || 0 }] }) }))] : [],
          map('property', apiCall('GET', '/properties', { ...defaults, ...args.property, filter: JSON.stringify({ $or: [{ name: searchRegex }, { address: searchRegex }, { contact: searchRegex }] }) })),
          map('auditObject', apiCall('GET', '/auditObjects', { ...defaults, ...args.auditObject, filter: JSON.stringify({ $or: [{ publicId: searchQuery.toUpperCase() }, { manufacturer: searchRegex }, { serialNo: searchRegex }, { barcode: searchQuery }] }) })),
          ...(dateSearch && !appInfo.user.isCustomer) ? [map('audit', apiCall('GET', '/audits', { ...defaults, ...args.audit, filter: JSON.stringify({ $or: [{ startDate: dateSearch }, { endDate: dateSearch }, ...!dateSearch.$regex ? [{ startDate: { $lte: dateSearch }, endDate: { $gte: dateSearch } }] : []] }) }))] : [],
          ...(dateSearch && !appInfo.user.isCustomer) ? [map('auditLog', apiCall('GET', '/auditLogs', { ...defaults, ...args.auditLog, filter: JSON.stringify({ date: dateSearch }) }))] : [],
          ...!appInfo.user.isCustomer ? [map('serviceLog', apiCall('GET', '/serviceLogs', { ...defaults, ...args.serviceLog, filter: JSON.stringify({ $or: [{ text: searchRegex }, ...timestampSearch ? [{ createdAt: timestampSearch }] : []] }) }))] : [], // Not for customer, because we cannot filter this by property
          ...appInfo.user.admin ? [map('user', apiCall('GET', '/users', { ...defaults, ...args.user, filter: JSON.stringify({ username: searchRegex }) }))] : []
        ])

        networkError = false
        return { items: results.flat() }
      } catch (e) {
        networkError = !(e instanceof StatusCodeError)

        if (networkError) {
          console.warn('Search request failed', e)
          await new Promise(resolve => setTimeout(resolve, 5000))
        } else {
          throw e
        }
      }
    } while (networkError)
  }}
  getItems={(items, tab) => tab === 'all' ? items : items.filter(item => item._type === tab)}
  childIcon={(record, tab) => ({
    customer: 'industry',
    property: 'building',
    auditObject: 'dice-d6',
    audit: 'calendar-check',
    auditLog: 'clipboard-list',
    serviceLog: 'comment',
    user: 'user'
  })[record._type] ?? 'question'}
  tabs={appInfo.user.isCustomer
    ? {
      all: 'Alle',
      property: 'Liegenschaften',
      auditObject: 'Prüfkörper'
    }
    : {
      all: 'Alle',
      customer: 'Kund.',
      property: 'Lieg.',
      auditObject: 'P.körp.',
      audit: 'P.term.',
      auditLog: 'P.prot.',
      serviceLog: 'Serv.h.',
      ...appInfo.user.admin ? { user: 'Benutz.' } : {}
    }
  }
  handleOpenDetails={record => {
    const View = {
      customer: ViewCustomerDetails,
      property: ViewPropertyDetails,
      auditObject: ViewAuditObjectDetails,
      audit: ViewAuditDetails,
      auditLog: ViewAuditLogDetails,
      serviceLog: ViewServiceLogDetails,
      user: ViewUserDetails
    }[record._type]
    if (!View) return
    openAndReplace(View, { id: record.id, onSave: silentReload, isolated: true })
  }}
  allowCreate={false}
  bind:silentReload
  let:record
>
  <svelte:fragment slot="header" let:mainRecord let:items>
    {#if networkError}
      <div class="m-3">
        <Notification type="is-warning" icon="wifi" showClose={false}>
          Die Suche konnte aktuell nicht durchgeführt werden, weil die Verbindung zum Server nicht möglich war. Die Suche wird automatisch wiederholt.<br><br>Bitte <strong>stellen Sie die Internetverbindung wieder her</strong>, um die Ergebnisse abrufen zu können.
        </Notification>
      </div>
    {/if}
  </svelte:fragment>

  <span class="list-entry">
    {#if record._type === 'customer'}
      {record.company} (#{record.customerNo})
    {:else if record._type === 'property'}
      {record.name}<br />
      <small>{record.customer?.company}</small>
    {:else if record._type === 'auditObject'}
      {record.publicId}: {record.manufacturer ?? ''} {record.serialNo ?? ''} {!record.manufacturer && !record.serialNo ? 'Unbekannt' : ''}<br />
      <small>{record.property?.name}</small>
    {:else if record._type === 'audit'}
      {(formatCustomDate(record.startDate) + (record.endDate && record.endDate !== record.startDate ? ` - ${formatCustomDate(record.endDate)}` : '') + (!record.startDate?.startsWith(`${numPad(record.year, 4)}-${numPad(record.month, 2)}`) ? ` (${numPad(record.month, 2)}.${numPad(record.year, 4)})` : '')) + (record.type === 'civil' ? ' (Ziviltechniker)' : '')}<br />
      <small>{record.property?.name}</small>
    {:else if record._type === 'auditLog'}
      {record.type === 'civil' ? 'Ziviltechniker-' : ''}Protokoll vom {formatCustomDate(record.date)}<br />
      <small>{record.auditObjectData?.publicId}: {record.auditObjectData?.manufacturer} {record.auditObjectData?.serialNo} {!record.auditObjectData?.manufacturer && !record.auditObjectData?.serialNo ? 'Unbekannt' : ''}</small>
    {:else if record._type === 'serviceLog'}
      {serviceLogTypes[record.type] ?? 'Historie-Eintrag'} vom {formatCustomDate(dateToYmdString(new Date(record.createdAt)))}<br />
      <small>{record.user?.fullName}: {ellipsis(record.text, 25)}</small>
    {:else if record._type === 'user'}
      {record.fullName} ({record.username})
    {/if}
  </span>
</ResourceView>
