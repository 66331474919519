<script>
  export let label = null
  export let text = null
  export let value = null
  export let error = null
  export let hint = null
  export let col = null
  export let type = 'is-primary'
  export let readonly = false
  export let disabled = false

  import { Field, Switch } from 'svelma'

  $: if (value == null) value = false
</script>

<style lang="scss">
  .switch {
    line-height: 2rem;
    padding: 0.3333rem 0;
  }
</style>

{#key error}
  <Field {label} type={error ? 'is-danger' : ''} message={error || hint} class="{$$props.class ?? ''} {col ? `column is-${col}` : ''}">
    <div class="switch" class:has-cursor-not-allowed={disabled || readonly}>
      <Switch bind:checked={value} type={error ? 'is-danger' : type} on:input on:change disabled={disabled || readonly}>{text || ''}</Switch>
    </div>
  </Field>
{/key}
