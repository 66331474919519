<script>
  export let closeSelf
  export let onSave = () => {}
  export let create = false
  export let id = null
  export let parentId = null
  export let title

  import ResourceForm from '../components/ResourceForm.svelte'
  import { apiCall } from '../lib/api'
  import { appInfo } from '../lib/appInfo'
  import { applyRequired, trimFields } from '../lib/validation'
  import FormField from '../components/FormField.svelte'
  import SelectField from '../components/SelectField.svelte'
  import CustomDateField from '../components/CustomDateField.svelte'
  import SwitchField from '../components/SwitchField.svelte'
  import auditStatuses from '../lib/auditStatuses'
  import { monthsEntries } from '../lib/months'
  import { formatCustomDate, monthToYmdString, numPad } from '../lib/utils'
  import { Dialog } from 'svelma'
  import TypePreSelector from '../components/TypePreSelector.svelte'
  import auditTypes from '../lib/auditTypes'

  let record
  let fieldErrors = {}
  let propertyName = '...'
  let customerName = '...'

  let users = []
  let allowPlanChange = !!create
  let planMonth
  let planYear
  let planChangeAttemptAlertOpen = false

  const today = new Date()

  async function onPlanChangeAttempt () {
    if (planChangeAttemptAlertOpen) return
    planChangeAttemptAlertOpen = true
    try {
      if (!allowPlanChange) {
        if (!await Dialog.confirm({
          message: 'Achtung, Sie sind im Begriff, den Planzeitpunkt zu ändern. Fortfahren?',
          confirmText: 'Ja',
          cancelText: 'Nein',
          type: 'is-warning'
        })) {
          record.month = planMonth
          record.year = planYear
        } else {
          allowPlanChange = true
        }
      }
    } finally {
      planChangeAttemptAlertOpen = false
    }
  }
</script>

<ResourceForm
  {closeSelf} {onSave} {create} {id} {parentId} {title}
  getTitle={record => `${record.type === 'civil' ? 'Ziviltechniker-' : ''}Prüftermin vom ${formatCustomDate(record.startDate || monthToYmdString(record.month, record.year))}${record.endDate && record.endDate !== record.startDate ? ` - ${formatCustomDate(record.endDate)}` : ''}${(record.startDate && !record.startDate?.startsWith(`${numPad(record.year, 4)}-${numPad(record.month, 2)}`) ? ` (${numPad(record.month, 2)}.${numPad(record.year, 4)})` : '')}`}
  parentIdField="property"
  initRecord={async parentId => ({
    property: parentId,
    user: null,
    month: today.getMonth() + 1,
    year: today.getFullYear(),
    status: 'planned',
    billed: false
  })}
  handleLoad={async (id, record, create, parentId) => {
    if (record.startDate?.endsWith('-00')) {
      record.startDate = null
    }

    try {
      users = await apiCall('GET', '/users', { order: 'fullName', filter: JSON.stringify({ isCustomer: { $ne: true } }), select: 'fullName' })
    } catch (e) {
      console.error('Failed to load user list', e)
      users = []
    }

    if (record.user && !users.find(u => u.id === record.user)) {
      users.push({ id: record.user, fullName: record.user })
    }

    try {
      if (record.property) {
        const property = await apiCall('GET', `/properties/${record.property}`, { select: 'name,customer', populate: JSON.stringify([['customer', 'company,customerNo']]) })
        propertyName = property.name
        customerName = property.customer ? `${property.customer.company} (#${property.customer.customerNo})` : null
      } else {
        propertyName = null
        customerName = null
      }
    } catch (e) {
      console.error(`Failed to load name for ${record.property}`, e)
      propertyName = record.property
      customerName = '?'
    }

    planMonth = record.month
    planYear = record.year
  }}
  validateRecord={async record => {
    const fieldErrors = {}

    if (record.startDate?.endsWith('-00')) { // Temporary measure in case there is a crash during validation
      record.startDate = null
    }

    applyRequired(record, fieldErrors, [
      'month',
      'year',
      'status'
    ])

    trimFields(record, [
      'notes',
      'coverNotes'
    ])

    if (!Object.keys(fieldErrors).length && !record.startDate) {
      record.startDate = monthToYmdString(record.month, record.year)
    }

    return { record, fieldErrors }
  }}
  handleSave={async record => {
    if (record.startDate?.endsWith('-00')) {
      record.startDate = null
    }
    return record
  }}
  resourcePath="/audits"
  resourceName="Prüftermin"
  resourceGender="m"
  readonly={!appInfo.user.admin}
  hideId={appInfo.user.isCustomer}
  saveEnabled={!!record?.type}
  bind:record
  bind:fieldErrors
>
  <TypePreSelector types={auditTypes} bind:value={record.type}>
    <div class="columns">
      <CustomDateField label="Erster Tag" icon="calendar-day" col=3 bind:value={record.startDate} error={fieldErrors.startDate} />
      <CustomDateField label="Letzter Tag" icon="calendar-day" col=3 bind:value={record.endDate} error={fieldErrors.endDate} />
      <SelectField label="Monat lt. Plan" icon="calendar-alt" col=2 bind:value={record.month} error={fieldErrors.month} options={monthsEntries} on:input={onPlanChangeAttempt} />
      <FormField label="Jahr lt. Plan" type="number" icon="calendar" col=2 bind:value={record.year} error={fieldErrors.year} on:input={onPlanChangeAttempt} />
      <SwitchField label="Abgerechnet?" col=2 bind:value={record.billed} error={fieldErrors.billed} />
    </div>

    <div class="columns">
      <FormField label="Kunde" icon="industry" col=3 value={customerName} readonly />
      <FormField label="Liegenschaft" icon="building" col=3 value={propertyName} readonly />
      {#if record.type === 'civil'}
        <FormField label="Mitarbeiter" icon="user-ninja" col=3 value="(Ziviltechniker)" readonly />
      {:else}
        <SelectField label="Mitarbeiter" icon="user" col=3 bind:value={record.user} error={fieldErrors.user} options={[[null, '(nicht zugewiesen)'], ...users.map(u => [u.id, u.fullName])]} />
      {/if}
      <SelectField label="Status" icon="traffic-light" col=3 bind:value={record.status} error={fieldErrors.status} options={Object.entries(auditStatuses)} />
    </div>

    <div class="columns">
      <FormField label="Notizen" type="textarea" col={record.type === 'civil' ? 12 : 6} bind:value={record.notes} error={fieldErrors.notes} />
      {#if record.type !== 'civil'}
        <FormField label="Hinweise am Deckblatt" type="textarea" col=6 bind:value={record.coverNotes} error={fieldErrors.coverNotes} />
      {/if}
    </div>
  </TypePreSelector>
</ResourceForm>
