'use strict'

/* eslint-env browser */

import ExtendableError from 'es6-error'
import { removeUndefined } from './utils'
import { apiUrl, frontendVersion } from './env'
import { appInfo } from './appInfo'
import { Toast } from 'svelma'

export class StatusCodeError extends ExtendableError {
  constructor (response, responseBody = null) {
    const msg = responseBody?.error || JSON.stringify(responseBody)
    super(`Fehler ${response.status} (${response.statusText})` + (msg ? `, Info: ${msg}` : ''))
    this.response = response
    this.serverErrorMessage = msg || response.statusText
    this.responseBody = responseBody
    this.code = responseBody?.code
  }
}

function getFormDataForFile (file) {
  const formData = new FormData()
  formData.append('file', file)
  return formData
}

export async function apiCall (method, path, data = {}) {
  if (!path.startsWith('/')) throw new Error('Bad path')

  const isInfoRequest = method === 'GET' && path === '/info'

  try {
    const hasBody = method !== 'GET'
    let queryString = ''
    if (!hasBody && Object.keys(data).length) {
      const params = new URLSearchParams()
      for (const [k, v] of Object.entries(data)) {
        if (v !== undefined) params.set(k, v)
      }
      const str = params.toString()
      if (str) queryString = (path.includes('?') ? '&' : '?') + str
    }

    const response = await fetch(apiUrl + path + queryString, {
      method,
      mode: 'cors',
      cache: 'no-store',
      credentials: 'include',
      headers: removeUndefined({
        'Content-Type': (hasBody && !(data instanceof File)) ? 'application/json' : undefined,
        'X-Frontend-Version': frontendVersion
      }),
      redirect: 'follow',
      body: hasBody ? (data instanceof File ? getFormDataForFile(data) : JSON.stringify(data)) : undefined
    })

    let responseBody
    try {
      if (response.status !== 204) responseBody = await response.json()
    } catch (e) {
      console.warn('Failed to parse response body', e)
    }

    if (!response.ok) {
      if (response.status === 401 && appInfo.user?.id) {
        Toast.create({ message: 'Die Sitzung ist abgelaufen!', type: 'is-warning' })
        location.reload()
        await new Promise(resolve => {}) // We hang here intentionally because the redirect should take care of it
      }

      throw new StatusCodeError(response, responseBody)
    }

    if (Array.isArray(responseBody)) responseBody.totalCount = response.headers.get('X-Total-Count')

    if (isInfoRequest) localStorage.infoCache = JSON.stringify({ responseBody, frontendVersion })

    return responseBody
  } catch (e) {
    if (e instanceof StatusCodeError) {
      console.warn(`API error for ${method} ${path}`, e.responseBody)
    } else {
      if (isInfoRequest) {
        try {
          const data = JSON.parse(localStorage.infoCache)
          if (data.frontendVersion === frontendVersion) {
            console.warn('Failed to fetch info', e)
            return { ...data.responseBody, offline: true }
          } else {
            console.warn('No cached info available')
          }
        } catch (e) {}
      }
      console.warn('Failed to fetch', method, path, e)
      // Nicer error message for the user
      e.message = 'Die Verbindung zum Server war nicht möglich. Bitte Internetverbindung überprüfen und erneut versuchen.'
    }
    throw e
  }
}
