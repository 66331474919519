'use strict'

export const months = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember'
]

export const monthsObject = {}

months.forEach((month, index) => {
  monthsObject[index + 1] = month
})

export const monthsEntries = Object.entries(monthsObject).map(([k, v]) => [Number(k), v])
