import { writable } from 'svelte/store'
import { generateRandomString } from '../lib/utils'

function createViewStack () {
  const { subscribe, update } = writable([])

  const updateViewProps = stack => {
    return stack.map(({ View, props, detached, id }, i, a) => ({
      View,
      props: {
        ...props,
        topView: i === a.length - 1,
        rootView: i === 0,
        viewId: id,
        closeSelf: () => detached ? viewStack.splice(id) : viewStack.closeUntil(id),
        closeHigher: () => detached ? () => {} : viewStack.closeUntil(id, 1),
        openAndReplace: (View, props, newDetached) => {
          if (!detached) viewStack.closeUntil(id, 1)
          viewStack.push(View, props, newDetached)
        }
      },
      detached,
      id
    }))
  }

  const viewStack = {
    subscribe,
    push: (View, props = {}, detached = false) => {
      const id = generateRandomString(6)
      update(stack => updateViewProps([...stack, { View, props, detached, id }]))
      return id
    },
    pop: () => update(stack => {
      if (stack.length <= 1) throw new Error('Cannot pop view')
      return updateViewProps(stack.slice(0, -1))
    }),
    closeUntil: (id, offset = 0) => update(stack => {
      const index = (id === null ? 0 : stack.findIndex(v => v.id === id)) + offset
      if (index - offset < 0) return stack // Nothing to do, the view no longer exists
      if (index < 1) throw new Error('Cannot close views below index 1')
      return updateViewProps(stack.filter(({ detached }, i) => i < index || detached))
    }),
    splice: (id, offset = 0) => update(stack => {
      const index = (id === null ? 0 : stack.findIndex(v => v.id === id)) + offset
      if (index - offset < 0) return stack // Nothing to do, the view no longer exists
      return updateViewProps(stack.filter((_, i) => i !== index))
    })
  }

  return viewStack
}

const viewStack = createViewStack()

export function open (View, props, detached) {
  return viewStack.push(View, props, detached)
}

export default viewStack
