<script>
  export let label = null
  export let icon = null
  export let value = null
  export let error = null
  export let hint = null
  export let col = null
  export let readonly = false
  export let disabled = false

  import { Field, Input } from 'svelma'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  $: internalValue = [...value, '']

  function handleChange (event) {
    for (let i = internalValue.length - 1; i >= 0; i--) {
      if (internalValue[i]) break
      internalValue.pop()
    }
    value = internalValue // This also reassigns internalValue due to reactive statement above
    dispatch(event.type, event.detail ?? event)
  }
</script>

{#key error}
  <Field {label} type={error ? 'is-danger' : ''} message={error || hint} class="{$$props.class ?? ''} {col ? `column is-${col}` : ''}" addons={false}>
    {#each Object.keys(internalValue) as i}
      <Input bind:value={internalValue[i]} {icon} class={error ? 'is-danger' : ''} on:change={handleChange} on:input={handleChange} {...readonly ? { readonly: true } : {}} {...disabled ? { disabled: true } : {}} />
    {/each}
  </Field>
{/key}
