<script>
  export let closeSelf
  export let onSave = () => {}
  export let create = false
  export let id = null
  export let title

  import ResourceForm from '../components/ResourceForm.svelte'
  import { apiCall } from '../lib/api'
  import { applyRequired, trimFields } from '../lib/validation'
  import FormField from '../components/FormField.svelte'
  import FileField from '../components/FileField.svelte'
  import { appInfo } from '../lib/appInfo'

  let record
  let fieldErrors = {}
</script>

<ResourceForm
  {closeSelf} {onSave} {create} {id} {title}
  getTitle={record => record.company ? `${record.company} (#${record.customerNo})` : undefined}
  initRecord={async () => {
    const [highestRec] = await apiCall('GET', '/customers', { order: '-customerNo', select: 'customerNo', limit: 1 })
    const customerNo = (highestRec?.customerNo ?? 0) + 1
    return { customerNo }
  }}
  validateRecord={async record => {
    const fieldErrors = {}

    applyRequired(record, fieldErrors, [
      'customerNo',
      'company',
      'address'
    ])

    trimFields(record, [
      'notes'
    ])

    return { record, fieldErrors }
  }}
  resourcePath="/customers"
  resourceName="Kunde"
  resourceGender="m"
  readonly={!appInfo.user.admin}
  hideId={appInfo.user.isCustomer}
  bind:record
  bind:fieldErrors
>
  <div class="columns">
    <FormField label="Kundennr." type="number" min=1 icon="hashtag" col=4 bind:value={record.customerNo} error={fieldErrors.customerNo} />
    <FormField label="Firma" icon="industry" col=8 inputClass="autofocus" bind:value={record.company} error={fieldErrors.company} />
  </div>

  <div class="columns">
    <FormField label="Anschrift" type="textarea" col=12 bind:value={record.address} error={fieldErrors.address} />
  </div>

  <div class="columns">
    <FormField label="Notizen" type="textarea" col=12 bind:value={record.notes} error={fieldErrors.notes} />
  </div>

  <div class="columns">
    <FileField label="Logo" icon="image" col=12 bind:value={record.logoUrl} error={fieldErrors.logoUrl} />
  </div>
</ResourceForm>
