<script>
  export let closeSelf
  export let onSave = () => {}
  export let create = false
  export let id = null
  export let parentId = null
  export let title

  import ResourceForm from '../components/ResourceForm.svelte'
  import { apiCall } from '../lib/api'
  import { applyRequired, trimFields } from '../lib/validation'
  import FormField from '../components/FormField.svelte'
  import SelectField from '../components/SelectField.svelte'
  import RadioGroupField from '../components/RadioGroupField.svelte'
  import intervals from '../lib/intervals'
  import { monthsEntries, monthsObject } from '../lib/months'
  import { escape } from '../lib/utils'
  import { Toast, Dialog } from 'svelma'
  import { appInfo } from '../lib/appInfo'

  let record
  let fieldErrors = {}
  let customerName = '...'

  let originalInterval
  let originalReferenceMonth
  let originalReferenceYear
  let originalCivilAuditMode
  let originalCivilAuditIntervalYears

  const today = new Date()
</script>

<ResourceForm
  {closeSelf} {onSave} {create} {id} {parentId} {title}
  getTitle={record => record.name}
  parentIdField="customer"
  initRecord={async parentId => ({
    customer: parentId,
    interval: 12,
    referenceMonth: today.getMonth() + 1,
    referenceYear: today.getFullYear(),
    civilAuditMode: 'none',
    civilAuditIntervalYears: 4
  })}
  handleLoad={async (id, record, create, parentId) => {
    try {
      if (record.customer) {
        const customer = await apiCall('GET', `/customers/${record.customer}`, { select: 'company,customerNo' })
        customerName = `${customer.company} (#${customer.customerNo})`
      } else {
        customerName = null
      }
    } catch (e) {
      console.error(`Failed to load name for ${record.customer}`, e)
      customerName = record.customer
    }

    originalInterval = record.interval
    originalReferenceMonth = record.referenceMonth
    originalReferenceYear = record.referenceYear
    originalCivilAuditMode = record.civilAuditMode
    originalCivilAuditIntervalYears = record.civilAuditIntervalYears
  }}
  validateRecord={async record => {
    const fieldErrors = {}

    applyRequired(record, fieldErrors, [
      'name',
      'address',
      'referenceMonth',
      'referenceYear',
      'civilAuditMode'
    ])

    trimFields(record, [
      'contact',
      'phoneNumber',
      'email',
      'notes'
    ])

    if (!record.interval) {
      record.interval = null
    } else {
      if (![1, 3, 6, 12].includes(record.interval)) fieldErrors.interval = 'Ungültiges Intervall'
    }

    if (record.civilAuditMode !== 'none') {
      if (!Number.isInteger(+record.civilAuditIntervalYears)) fieldErrors.civilAuditIntervalYears = 'Nur ganze Jahresschritte möglich'
      if (record.civilAuditIntervalYears < 2) fieldErrors.civilAuditIntervalYears = 'Muss mindestens 2 Jahre betragen'
    }

    if (!!String(record.latitude ?? '') !== !!String(record.longitude ?? '')) {
      const error = 'Sowohl Breitengrad als auch Längengrad müssen gesetzt sein, wenn mindestens einer davon gesetzt ist'
      if (!String(record.latitude ?? '')) fieldErrors.latitude = error
      if (!String(record.longitude ?? '')) fieldErrors.longitude = error
    }

    if (record.latitude < -90 || record.latitude > 90) fieldErrors.latitude = 'Ungültiger Wert (muss zwischen -90 und 90 liegen)'
    if (record.latitude < -180 || record.latitude > 180) fieldErrors.latitude = 'Ungültiger Wert (muss zwischen -180 und 180 liegen)'

    return { record, fieldErrors }
  }}
  handleSave={async record => {
    let doReschedule = create

    if (!create) {
      if (
        originalInterval !== record.interval ||
        +originalReferenceMonth !== +record.referenceMonth ||
        +originalReferenceYear !== +record.referenceYear ||
        originalCivilAuditMode !== record.civilAuditMode ||
        +originalCivilAuditIntervalYears !== +record.civilAuditIntervalYears
      ) {
        if (await Dialog.confirm({
          message: 'Es wurden das Intervall, der Referenztermin und/oder die Ziviltechniker-Einstellungen der Liegenschaft geändert. Sollen jetzt auch alle Termine anhanddessen neu geplant werden? (Achtung, dies kann großflächige Änderungen an den Terminen auslösen!)',
          confirmText: 'Ja',
          cancelText: 'Nein',
          type: 'is-danger'
        })) {
          doReschedule = true
        }
      }
    }

    if (doReschedule) {
      try {
        await apiCall('POST', `/properties/${record.id}/rescheduleAudits`)
        Toast.create({ message: 'Termine wurden neu geplant.', type: 'is-success' })
      } catch (e) {
        console.error(e)
        Dialog.alert({ message: escape(e.serverErrorMessage || `Die Liegenschaft wurde gespeichert, aber beim Planen der Termine ist ein Problem aufgetreten!\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
      }
    }

    return record
  }}
  resourcePath="/properties"
  resourceName="Liegenschaft"
  resourceGender="f"
  readonly={!appInfo.user.admin}
  hideId={appInfo.user.isCustomer}
  bind:record
  bind:fieldErrors
>
  <div class="columns">
    <FormField label="Name" icon="building" col=12 inputClass="autofocus" bind:value={record.name} error={fieldErrors.name} />
  </div>

  <div class="columns">
    <div class="column is-9">
      <FormField label="Anschrift" type="textarea" bind:value={record.address} error={fieldErrors.address} />
    </div>
    <div class="column is-3">
      <FormField label="Koordinaten Breitengrad" icon="globe" type="number" bind:value={record.latitude} error={fieldErrors.latitude} suffix="°" />
      <FormField label="Koordinaten Längengrad" icon="globe" type="number" bind:value={record.longitude} error={fieldErrors.longitude} suffix="°" />
    </div>
  </div>

  <div class="columns">
    <FormField label="Ansprechpartner" icon="user-tie" col=4 bind:value={record.contact} error={fieldErrors.contact} />
    <FormField label="Telefonnummer" icon="phone" col=4 bind:value={record.phoneNumber} error={fieldErrors.phoneNumber} />
    <FormField label="Email" icon="at" col=4 bind:value={record.email} error={fieldErrors.email} />
  </div>

  <div class="columns">
    <FormField label="Kunde" icon="industry" col=3 value={customerName} readonly />
    <SelectField label="Intervall" icon="step-forward" col=3 bind:value={record.interval} error={fieldErrors.interval} options={[...intervals.entries()]} />
    <SelectField label="Monat Referenztermin" icon="calendar-alt" col=3 bind:value={record.referenceMonth} error={fieldErrors.referenceMonth} options={monthsEntries} />
    <FormField label="Jahr Referenztermin" type="number" icon="calendar" col=3 bind:value={record.referenceYear} error={fieldErrors.referenceYear} />
  </div>

  <div class="columns">
    <RadioGroupField label="Ziviltechniker-Prüfungen" options={[['none', 'keine'], ['instead', 'anstatt interner Prüfung'], ['additional', 'zusätzlich zu interner Prüfung']]} col=6 bind:value={record.civilAuditMode} error={fieldErrors.civilAuditMode} />
    {#if (record.civilAuditMode ?? 'none') !== 'none'}
      <FormField label="Ziviltechniker-Prüfungs-Intervall" type="number" min=2 col=6 bind:value={record.civilAuditIntervalYears} error={fieldErrors.civilAuditIntervalYears} prefix="alle" suffix="Jahre" hint="Erste Prüfung im {monthsObject[record.referenceMonth]} {+record.referenceYear + ((record.civilAuditIntervalYears - 1) || 0)} - zum Verschieben bitte Referenztermin ändern" />
    {/if}
  </div>

  <div class="columns">
    <FormField label="Notizen" type="textarea" col=12 bind:value={record.notes} error={fieldErrors.notes} />
  </div>
</ResourceForm>
