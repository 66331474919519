<script>
  export let icon
  export let title
  export let ready
  export let onlyIf
  export let value

  import { Icon } from 'svelma'
</script>

{#if ready || !('ready' in $$props)}
  {#if onlyIf || !('onlyIf' in $$props)}
    {#if value || !('value' in $$props)}
      <span>
        <span {title}><Icon {icon} /></span>
        {#if 'value' in $$props}
          {value}
        {/if}
        <slot />
      </span>
    {/if}
  {/if}
{:else}
  ...
{/if}
