<script>
  export let topView
  export let closeSelf
  export let closeHigher
  export let openAndReplace

  export let id
  export let title
  export let onSave
  export let isolated = false

  import ResourceView from '../components/ResourceView.svelte'
  import { formatCustomDate, dateToYmdString } from '../lib/utils'
  import ViewServiceLogForm from './ViewServiceLogForm.svelte'
  import ViewCustomerDetails from './ViewCustomerDetails.svelte'
  import ViewPropertyDetails from './ViewPropertyDetails.svelte'
  import ViewAuditLogDetails from './ViewAuditLogDetails.svelte'
  import ViewAuditObjectDetails from './ViewAuditObjectDetails.svelte'
  import { tick } from 'svelte'
  import { Icon } from 'svelma'
  import Detail from '../components/Detail.svelte'
  import { open } from '../stores/viewStack'
  import ViewImagePreview from './ViewImagePreview.svelte'
  import { appInfo } from '../lib/appInfo'
  import serviceLogTypes from '../lib/serviceLogTypes'

  let mainRecord
  let selected
  let silentReload
  let actionInProgress

  async function openCustomer (customer) {
    if (!customer) throw new Error('No customer set')
    selected = null
    await tick()
    openAndReplace(ViewCustomerDetails, { id: customer.id, onSave: silentReload, isolated: true })
  }

  async function openProperty (property) {
    if (!property) throw new Error('No property set')
    selected = null
    await tick()
    openAndReplace(ViewPropertyDetails, { id: property.id, onSave: silentReload, isolated: true })
  }

  async function openAuditLog (auditLog) {
    if (!auditLog) throw new Error('No audit log set')
    selected = null
    await tick()
    openAndReplace(ViewAuditLogDetails, { id: auditLog.id, onSave: silentReload, isolated: true })
  }

  async function openAuditObject (auditObject) {
    if (!auditObject) throw new Error('No auditObject object set')
    selected = null
    await tick()
    openAndReplace(ViewAuditObjectDetails, { id: auditObject.id, onSave: silentReload, isolated: true })
  }
</script>

<style lang="scss">
  :global(.panel-block.is-unselectable) {
    &, &:hover {
      background: white;
    }

    cursor: default;
  }

  article {
    white-space: pre-wrap;
  }

  .list-entry hr {
    margin: 0.5rem 0;
  }
</style>

<ResourceView
  {topView} {closeSelf} {closeHigher} {openAndReplace}
  {id} {title}
  icon="comment"
  defaultTitle="Historie-Eintrag"
  {onSave}
  mainPopulate={JSON.stringify([
    ['user', 'fullName'],
    {
      path: 'auditObject',
      select: 'publicId,manufacturer,serialNo',
      populate: [{
        path: 'property',
        select: 'name,customer',
        populate: {
          path: 'customer',
          select: 'company,customerNo'
        }
      }]
    },
    ['auditLog', 'date,audit'] // audit is actually not needed here, but it's used to work around an issue in the way the resource API projects sub-resources (audit is needed for the readProjection of auditLog to allow it through)
  ])}
  getTitle={record => (`${serviceLogTypes[record.type] ?? 'Historie-Eintrag'} vom ${formatCustomDate(dateToYmdString(new Date(record.createdAt)))}`)}
  handleLoad={async (id, mainRecord) => {
    const items = mainRecord ? [mainRecord] : []
    return { items }
  }}
  handleOpenDetails={record => {
    tick().then(() => {
      selected = null
    })
  }}
  resourcePath="/serviceLogs"
  resourceName="Historie-Eintrag"
  ViewMainForm={ViewServiceLogForm}
  allowCreate={false}
  allowEdit={!appInfo.user.isCustomer}
  allowDelete={!appInfo.user.isCustomer}
  canDelete={(mainRecord, items) => appInfo.user.admin || (!appInfo.user.isCustomer && appInfo.user.id === mainRecord?.user.id)}
  readonly={!appInfo.user.admin && (appInfo.user.isCustomer || appInfo.user.id !== mainRecord?.user.id)}
  hideId={appInfo.user.isCustomer}
  rowClass='is-unselectable'
  bind:mainRecord
  bind:silentReload
  bind:selected
  bind:actionInProgress
  let:record
>
  <svelte:fragment slot="header" let:mainRecord>
    <div class="panel-block">
      <Detail title="Benutzer" icon="user" ready={mainRecord}>
        <strong>{mainRecord?.user?.fullName || '(unbekannt)'}</strong>
      </Detail>
    </div>

    <div class="panel-block">
      <Detail title="Zeitpunkt" icon="clock" ready={mainRecord}>
        {mainRecord?.createdAt ? `${formatCustomDate(dateToYmdString(new Date(mainRecord.createdAt)))} um ${new Date(mainRecord.createdAt).toLocaleTimeString()}` : '(unbekannt)'}
      </Detail>
    </div>

    {#if isolated}
      <div class="panel-block">
        <Detail title="Kunde" icon="industry" ready={mainRecord}>
          <a href={undefined} on:click={() => openCustomer(mainRecord.auditObject?.property?.customer)}>{mainRecord?.auditObject?.property?.customer?.company} (#{mainRecord?.auditObject?.property?.customer?.customerNo})</a>
        </Detail>
      </div>

      <div class="panel-block">
        <Detail title="Liegenschaft" icon="building" ready={mainRecord}>
          <a href={undefined} on:click={() => openProperty(mainRecord.auditObject?.property)}>{mainRecord?.auditObject?.property?.name}</a>
        </Detail>
      </div>

      <div class="panel-block">
        <Detail title="Prüfkörper" icon="dice-d6" ready={mainRecord}>
          <a href={undefined} on:click={() => openAuditObject(mainRecord.auditObject)}>
            {mainRecord?.auditObject?.publicId}: {mainRecord?.auditObject?.manufacturer ?? ''} {mainRecord?.auditObject?.serialNo ?? ''} {!mainRecord?.auditObject?.manufacturer && !mainRecord?.auditObject?.serialNo ? 'Unbekannt' : ''}
          </a>
        </Detail>
      </div>
    {/if}

    {#if !appInfo.user.isCustomer && mainRecord?.auditLog}
      <div class="panel-block">
        <Detail title="Prüfprotokoll" icon="clipboard-list" ready={mainRecord}>
          Letzte Prüfung:
          <a href={undefined} on:click={() => openAuditLog(mainRecord.auditLog)}>
            {formatCustomDate(mainRecord?.auditLog?.date)}
          </a>
        </Detail>
      </div>
    {/if}
  </svelte:fragment>

  <span class="list-entry">
    <strong>{serviceLogTypes[record.type] ?? 'Historie-Eintrag'}</strong>:<br />
    <article>{record.text}</article>
    {#if record?.photoUrl}
      <hr />
      <a href={undefined} on:click={() => open(ViewImagePreview, { url: record.photoUrl }, true)} title="Foto-Anhang"><Icon icon="image" /> Foto-Anhang</a>
    {/if}
    {#if record.type === 'repair' && record.fixedIssues?.length}
      <hr />
      Diese Reparatur behebt folgende Mängel:
      <ul>
        {#each record.fixedIssues as { questionDisplayId, questionLabel, description }}
          <li>{questionDisplayId} {questionLabel}: {description}</li>
        {/each}
      </ul>
    {/if}
  </span>
</ResourceView>
