<script>
  export let closeSelf
  export let preparedData

  import Modal from '../components/Modal.svelte'
</script>

<style lang="scss">
  .overview-photo-preview {
    padding: 80px;
    text-align: center;
    font-size: 200%;
    display: block;
  }
</style>

<Modal title="Layout-Vorschau" closeable on:close={closeSelf}>
  {#each preparedData.layoutRows as row}
    <div class="columns is-mobile">
      {#each row.columns as column}
        <div class="column">
          {#each column.groups as group}
            <div class="panel">
              <p class="panel-heading">
                {group.number}: {group.label}
              </p>

              {#if group.isOverviewPhoto}
                <div class="panel-block overview-photo-preview">Foto</div>
              {:else}
                {#each group.questions as question}
                  <div class="panel-block">
                    {question.fullNumber}: {question.label}
                  </div>
                {/each}
              {/if}
            </div>
          {/each}
        </div>
      {/each}
    </div>
  {/each}
</Modal>
