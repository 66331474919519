<script>
  export let closeSelf
  export let onSave = () => {}
  export let create = false
  export let id = null
  export let parentId = null
  export let title

  import ResourceForm from '../components/ResourceForm.svelte'
  import { apiCall } from '../lib/api'
  import { appInfo } from '../lib/appInfo'
  import { applyRequired, cleanErrorObject } from '../lib/validation'
  import FormField from '../components/FormField.svelte'
  import FileField from '../components/FileField.svelte'
  import SelectField from '../components/SelectField.svelte'
  import MultiSelectField from '../components/MultiSelectField.svelte'
  import { dateToYmdString, formatCustomDate } from '../lib/utils'
  import { getAuditLogIssues } from '../lib/auditQuestions'
  import TypePreSelector from '../components/TypePreSelector.svelte'
  import serviceLogTypes from '../lib/serviceLogTypes'

  let record
  let fieldErrors = {}
  let propertyName = '...'
  let customerName = '...'
  let auditObjectName = '...'
  let auditLogName = '...'

  let auditLog = null
  let users = []
  let issues = {}
  let issueSelectOptions = []
  let fixedIssueIds = []

  const newTitle = { comment: 'Neuer Kommentar', internalComment: 'Neuer interner Kommentar', repair: 'Neue Reparatur', serviceRequest: 'Neue Service-Anforderung' }
</script>

<ResourceForm
  {closeSelf} {onSave} {create} {id} {parentId} {title}
  getTitle={record => create ? newTitle[record.type] ?? 'Neuer Historie-Eintrag' : (`${serviceLogTypes[record.type] ?? 'Historie-Eintrag'} vom ${formatCustomDate(dateToYmdString(new Date(record.createdAt)))}`)}
  parentIdField="auditObject"
  initRecord={async parentId => {
    const result = await apiCall('GET', '/auditLogs', {
      order: '-date',
      select: 'id',
      filter: JSON.stringify({ auditObject: parentId }),
      limit: 1
    })

    const record = {
      user: appInfo.user.id,
      auditObject: parentId,
      auditLog: result[0]?.id,
      fixedIssues: []
    }

    return record
  }}
  handleLoad={async (id, record, create, parentId) => {
    try {
      users = await apiCall('GET', '/users', { order: 'fullName', filter: JSON.stringify({ isCustomer: { $ne: true } }), select: 'fullName' })
    } catch (e) {
      console.error('Failed to load user list', e)
      users = []
    }

    try {
      auditLog = record.auditLog ? await apiCall('GET', `/auditLogs/${record.auditLog}`) : null
      auditLogName = record.auditLog ? formatCustomDate(auditLog.date) : null
    } catch (e) {
      console.error('Failed to load audit log', e)
      auditLog = null
      auditLogName = '?'
    }

    if (record.user && !users.find(u => u.id === record.user)) {
      users.push({ id: record.user, fullName: record.user })
    }

    try {
      if (record.auditObject) {
        const auditObject = await apiCall('GET', `/auditObjects/${record.auditObject}`, {
          select: 'publicId,manufacturer,serialNo',
          populate: JSON.stringify([{
            path: 'property',
            select: 'name,customer',
            populate: {
              path: 'customer',
              select: 'company,customerNo'
            }
          }])
        })
        auditObjectName = `${auditObject.publicId}: ${auditObject.manufacturer ?? ''} ${auditObject.serialNo ?? ''} ${!auditObject.manufacturer && !auditObject.serialNo ? 'Unbekannt' : ''}`
        propertyName = auditObject.property?.name
        customerName = auditObject.property?.customer ? `${auditObject.property.customer.company} (#${auditObject.property.customer.customerNo})` : null
      } else {
        auditObjectName = null
        propertyName = null
        customerName = null
      }
    } catch (e) {
      console.error(`Failed to load info for ${record.auditObject}`, e)
      auditObjectName = record.auditObject
      propertyName = '?'
      customerName = '?'
    }

    const possibleIssues = getAuditLogIssues(auditLog)

    for (const issue of record.fixedIssues ?? []) {
      if (!possibleIssues[issue.questionId]) possibleIssues[issue.questionId] = issue
    }

    issues = possibleIssues
    issueSelectOptions = [...Object.values(issues).filter(i => i.state === 'red'), ...Object.values(issues).filter(i => i.state === 'yellow')]
      .map(({ questionId, questionDisplayId, questionLabel, description }) => [questionId, `${questionDisplayId} ${questionLabel}: ${description}`])

    fixedIssueIds = (record.fixedIssues ?? []).map(issue => issue.questionId)
  }}
  validateRecord={async record => {
    const fieldErrors = {}

    applyRequired(record, fieldErrors, [
      'text'
    ])

    record.fixedIssues = fixedIssueIds.map(id => issues[id]).filter(x => x)

    cleanErrorObject(fieldErrors)

    return { record, fieldErrors }
  }}
  resourcePath="/serviceLogs"
  resourceName="Historie-Eintrag"
  resourceGender="m"
  readonly={!appInfo.user.admin && !create && (appInfo.user.isCustomer || appInfo.user.id !== record?.user)}
  hideId={appInfo.user.isCustomer}
  saveEnabled={!!record?.type}
  bind:record
  bind:fieldErrors
>
  <TypePreSelector types={serviceLogTypes} bind:value={record.type}>
    <div class="columns">
      <SelectField label="Typ" icon="comment" col=4 bind:value={record.type} error={fieldErrors.type} options={Object.entries(serviceLogTypes)} />
      <SelectField label="Benutzer" icon="user-check" col=4 bind:value={record.user} error={fieldErrors.user} options={users.map(u => [u.id, u.fullName])} readonly={!appInfo.user.admin} />
      <FormField label="Kunde" icon="industry" col=4 value={customerName} readonly />
    </div>

    <div class="columns">
      <FormField label="Liegenschaft" icon="building" col=4 value={propertyName} readonly />
      <FormField label="Prüfkörper" icon="dice-d6" col=4 value={auditObjectName} readonly />
      <FormField label="Letzte Prüfung" icon="clipboard-check" col=4 value={auditLogName} readonly />
    </div>

    <div class="columns">
      <FormField label="Text" type="textarea" col=12 bind:value={record.text} error={fieldErrors.text} />
    </div>

    <div class="columns">
      <FileField label="Foto-Anhang (optional)" icon="image" col=12 bind:value={record.photoUrl} error={fieldErrors.photoUrl} />
    </div>

    {#if record.type === 'repair'}
      <div class="columns">
        <MultiSelectField label="Behobene Mängel" icon="thumbs-up" col=12 bind:value={fixedIssueIds} error={fieldErrors.fixedIssues} options={issueSelectOptions} selectorTitle="Mängelbehebung hinzufügen" />
      </div>
    {/if}
  </TypePreSelector>
</ResourceForm>
