<script>
  export let topView
  export let closeSelf
  export let closeHigher
  export let openAndReplace

  export let id
  export let title
  export let onSave

  import ResourceView from '../components/ResourceView.svelte'
  import { apiCall } from '../lib/api'
  import ViewCustomerForm from './ViewCustomerForm.svelte'
  import ViewPropertyDetails from './ViewPropertyDetails.svelte'
  import ViewPropertyForm from './ViewPropertyForm.svelte'
  import ViewUserDetails from './ViewUserDetails.svelte'
  import ViewUserForm from './ViewUserForm.svelte'
  import Detail from '../components/Detail.svelte'
  import { Icon, Toast, Dialog } from 'svelma'
  import { escape } from '../lib/utils'
  import { appInfo } from '../lib/appInfo'

  let tab

  let actionInProgress
  let silentReload

  async function terminateAllProperties (mainRecord, items) {
    const toSuspend = items.filter(p => p.interval)
    if (!toSuspend.length) return // Nothing to do

    if (await Dialog.confirm({
      message: `Es ${toSuspend.length === 1 ? 'wird eine Liegenschaft' : `werden ${toSuspend.length} Liegenschaften`} von ${mainRecord.company} gekündigt. Fortfahren?`,
      confirmText: 'Ja',
      cancelText: 'Nein',
      type: 'is-danger'
    })) {
      actionInProgress = true
      try {
        for (const item of toSuspend) {
          await apiCall('PATCH', `/properties/${item.id}`, { interval: null })
        }
        Toast.create({ message: 'Alle Liegenschaften wurden gekündigt.', type: 'is-success' })
        silentReload()

        if (await Dialog.confirm({
          message: 'Kündigungen erfolgreich! Sollen jetzt auch alle zukünftigen Termine der betroffenen Liegenschaft(en) abgebrochen bzw. gelöscht werden?',
          confirmText: 'Ja',
          cancelText: 'Nein',
          type: 'is-danger'
        })) {
          try {
            for (const item of toSuspend) {
              await apiCall('POST', `/properties/${item.id}/rescheduleAudits`)
            }
            Toast.create({ message: 'Termine wurden neu geplant.', type: 'is-success' })
            silentReload()
          } catch (e) {
            console.error(e)
            Dialog.alert({ message: escape(e.serverErrorMessage || `Beim Neu-Planen der Termine ist ein Problem aufgetreten!\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
          }
        }
      } catch (e) {
        console.error(e)
        Dialog.alert({ message: escape(e.serverErrorMessage || `Die Änderungen konnten nicht gespeichert werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
        silentReload()
      } finally {
        actionInProgress = false
      }
    }
  }
</script>

<ResourceView
  {topView} {closeSelf} {closeHigher} {openAndReplace}
  {id} {title}
  icon="industry"
  defaultTitle="Kundendetails"
  {onSave}
  bind:actionInProgress
  bind:silentReload
  getTitle={record => `${record.company} (#${record.customerNo})`}
  getChildTitle={record => (record.fullName && record.username) ? `${record.fullName} (${record.username})` : record.name} {...{/* In case it's a new property and not a new user, record.name will be undefined too */}}
  childIcon={record => record.username ? 'user' : 'building'}
  handleLoad={async (id, mainRecord) => {
    let items = await apiCall('GET', '/properties', { order: 'name', select: 'name,address,interval', filter: JSON.stringify({ customer: id }) })
    if (!appInfo.user.isCustomer) {
      items = items.concat(await apiCall('GET', '/users', { order: 'fullName', select: 'username,fullName,suspended,limitProperties', filter: JSON.stringify({ customer: id }) }))
    }
    return { items }
  }}
  getItems={(items, tab) => (tab === 'properties' || !appInfo.user.admin) ? items.filter(item => !item.username) : items.filter(item => item.username)}
  resourcePath="/customers"
  resourceName="Kunde"
  childResourceName={(tab === 'properties' || !appInfo.user.admin) ? 'Liegenschaft' : 'Benutzer'}
  childResourceGender={(tab === 'properties' || !appInfo.user.admin) ? 'f' : 'm'}
  ViewMainForm={ViewCustomerForm}
  ViewChildDetails={(tab === 'properties' || !appInfo.user.admin) ? ViewPropertyDetails : ViewUserDetails}
  childDetailsProps={{ openedFrom: 'customer' }}
  ViewChildForm={(tab === 'properties' || !appInfo.user.admin) ? ViewPropertyForm : ViewUserForm}
  tabs={!appInfo.user.admin ? undefined : { properties: 'Liegenschaften', users: 'Benutzer' }}
  bind:tab
  search={(searchQuery, searchRegex, record) => record.name?.match(searchRegex) || record.address?.match(searchRegex) || record.username?.match(searchRegex) || record.fullName?.match(searchRegex)}
  allowEdit={!appInfo.user.isCustomer}
  allowDelete={!appInfo.user.isCustomer}
  canDelete={(mainRecord, items) => !items.length}
  afterDelete={async () => {
    try {
      const users = await apiCall('GET', '/users', { select: 'id', filter: JSON.stringify({ customer: id }) })
      for (const user of users) {
        await apiCall('DELETE', `/users/${user.id}`)
      }
      if (users.length) Toast.create({ message: `${users.length} Kundenbenutzer wurde(n) gelöscht.`, type: 'is-success' })
    } catch (e) {
      console.error(e)
      Toast.create({ message: escape(`Beim Löschen der Kundenbenutzer ist ein Fehler aufgetreten! Der Kunde selbst wurde jedoch gelöscht.\n\nTechnische Informationen: ${e.serverErrorMessage || e}`), type: 'is-danger' })
    }
  }}
  readonly={!appInfo.user.admin}
  hideId={appInfo.user.isCustomer}
  let:record
>
  <svelte:fragment slot="header" let:mainRecord>
    <div class="panel-block">
      <Detail title="Anschrift" icon="envelope" ready={mainRecord?.address}>
        {mainRecord?.address?.split('\n').join('; ')}
      </Detail>
    </div>

    {#if mainRecord?.notes && !appInfo.user.isCustomer}
      <div class="panel-block">
        <Detail title="Notizen" icon="sticky-note">
          {mainRecord.notes.split('\n').join('; ')}
        </Detail>
      </div>
    {/if}
  </svelte:fragment>

  <svelte:fragment slot="actions" let:mainRecord let:items>
    {#if appInfo.user.admin}
      {#if items?.some(p => p.interval)}
        <a href={undefined} class="dropdown-item has-text-danger" on:click={() => terminateAllProperties(mainRecord, items)}><Icon icon="times-circle" /> Alle Liegenschaften kündigen</a>
      {/if}
    {:else}
      <em class="dropdown-item">Keine Aktionen verfügbar</em>
    {/if}
  </svelte:fragment>

  <span class="list-entry" class:is-disabled={record.username ? record.suspended : !record.interval}>
    {#if record.username}
      {record.fullName} ({record.username})
      {#if !record.limitProperties}
        <span title="Zugriff auf alle Liegenschaften"><Icon icon="crown" /></span>
      {/if}
    {:else}
      {record.name}<br />
      <small>{record.address.split('\n').join('; ')}</small>
    {/if}
  </span>
</ResourceView>
