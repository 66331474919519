'use strict'

export const OVERVIEW_PHOTO_GROUP = '\u0001'

export function getAllGroups (record) {
  if (!record?.layoutRows) return []
  return record.layoutRows.map(r => r.columns.map(c => c.groups)).flat(2)
}

export function getActualLabel (label) {
  if (!label) return '(unbenannt)'
  if (label === OVERVIEW_PHOTO_GROUP) return 'Überblick-Foto'
  return label
}

/*
  Takes the DB format in and returns a format more suitable for rendering
  Output:
  {
    layoutRows: [..., {
      columns: [..., {
        groups: [..., {
          number: 3,
          label: 'Group label ready to render',
          isOverviewPhoto: false,
          questions: [..., {
            id: 'abcdef',
            label: 'Question label ready to render',
            description,
            yellowPresets,
            redPresets,
            photoOptional,
            number: 5,
            fullNumber: '3.5',
            group: <reference back to group>
          }, ...]
        }, ...]
      }, ...]
    }, ...],
    groups: [<all groups>],
    questions: [<all questions>]
  }
*/
export function getPreparedAuditQuestions (auditQuestions) {
  const prepared = { layoutRows: [], groups: [], questions: [] }
  for (const row of auditQuestions?.layoutRows ?? []) {
    const preparedRow = { columns: [] }

    for (const column of row.columns ?? []) {
      const preparedColumn = { groups: [] }

      for (const group of column.groups ?? []) {
        const preparedGroup = {
          number: prepared.groups.length + 1,
          label: getActualLabel(group.label),
          isOverviewPhoto: group.label === OVERVIEW_PHOTO_GROUP,
          questions: []
        }

        for (const questionId of group.questions ?? []) {
          const question = auditQuestions?.questions[questionId]
          const preparedQuestion = {
            id: questionId,
            group: preparedGroup,
            ...question,
            label: getActualLabel(question?.label),
            number: preparedGroup.questions.length + 1,
            fullNumber: `${preparedGroup.number}.${preparedGroup.questions.length + 1}`
          }

          prepared.questions.push(preparedQuestion)
          preparedGroup.questions.push(preparedQuestion)
        }

        prepared.groups.push(preparedGroup)
        preparedColumn.groups.push(preparedGroup)
      }

      preparedRow.columns.push(preparedColumn)
    }

    prepared.layoutRows.push(preparedRow)
  }

  return prepared
}

export function getAuditLogIssues (auditLog) {
  const issues = {}
  if (auditLog?.auditQuestions) {
    const { questions } = getPreparedAuditQuestions(auditLog.auditQuestions)
    for (const question of questions) {
      if (auditLog.results?.[question.id]?.state === 'red' || auditLog.results?.[question.id]?.state === 'yellow') {
        issues[question.id] = {
          questionId: question.id,
          questionDisplayId: question.fullNumber,
          questionLabel: question.label,
          description: auditLog.results[question.id].description,
          state: auditLog.results[question.id].state
        }
      }
    }
  }
  return issues
}
