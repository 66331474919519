'use strict'

// This is replaced with the app config via @rollup/plugin-replace
const env = __app_env__ // eslint-disable-line camelcase, no-undef

export const apiUrl = env.apiUrl
export const appUrl = env.appUrl

if (!apiUrl) {
  throw new Error('No API URL defined')
}

if (!appUrl) {
  throw new Error('No app URL defined')
}

export const frontendVersion = env.frontendVersion
