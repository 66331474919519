<script>
  import { Toast, Button, Notification } from 'svelma'
  import MiddleBox from '../components/MiddleBox.svelte'
  import { apiCall } from '../lib/api'
  import { onMount } from 'svelte'
  import { escape } from '../lib/utils'
  import FormField from '../components/FormField.svelte'
  import { appInfo } from '../lib/appInfo'

  let username = ''
  let password = ''

  let loading = false
  let invalid = false

  let formElement

  onMount(() => {
    if (formElement) formElement.querySelector('input').focus() // Because autofocus doesn't properly work on <Input>
  })

  async function login () {
    loading = true
    invalid = false
    try {
      const { userId } = await apiCall('POST', '/login', { username, password })
      console.log('Login erfolgreich', userId)
      location.reload()
    } catch (e) {
      Toast.create({ message: escape(e.serverErrorMessage) || 'Login fehlgeschlagen, bitte erneut versuchen!', type: 'is-danger' })
      if (e.code === 'invalid_credentials') invalid = true
      if (e.code === 'already_authenticated') location.reload()
      loading = false
    }
  }
</script>

<div class="container">
  <MiddleBox>
    <h1 class="title is-2">Rettenbacher ERP Login</h1>
    {#if appInfo.offline}
      <Notification type="is-danger" icon={true} showClose={false}>
        Zur Anmeldung ist eine Verbindung zum Server erforderlich. Bitte Internetverbindung überprüfen und erneut versuchen.
        <div class="buttons is-right">
          <Button type="is-danger" inverted outlined on:click={() => location.reload()}>Neu laden</Button>
        </div>
      </Notification>
    {:else}
      <form on:submit|preventDefault={login} bind:this={formElement}>
        <fieldset disabled={loading}>
          <FormField label="Benutzername" autocomplete="username" bind:value={username} icon="user" error={invalid ? 'Benutzername oder Passwort falsch!' : undefined} />
          <FormField label="Passwort" autocomplete="current-password" bind:value={password} type="password" icon="star-of-life" error={invalid ? 'Benutzername oder Passwort falsch!' : undefined} />

          <div class="buttons is-right">
            <Button type="is-primary" nativeType="submit" {loading}>Anmelden</Button>
          </div>
        </fieldset>
      </form>
    {/if}
  </MiddleBox>
</div>
