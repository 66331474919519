<script>
  export let label = null
  export let icon = null
  export let value = null
  export let error = null
  export let hint = null
  export let col = null
  export let options = []
  export let readonly = false
  export let disabled = false
  export let inputClass = null
  export let allowInvalid = false

  import { Field, Select } from 'svelma'

  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  $: selected = String(options.findIndex(([k]) => k === value))
  $: if (!allowInvalid && selected === '-1' && options.length) {
    updateValue({ type: 'input', detail: { preventingInvalid: true, selected: '0' } })
  }

  function updateValue (event) {
    // Binding "selected" causes reverting to the old value in certain conditions (such as on Chrome 92 on Android)
    // because some things were marked dirty which shouldn't be and the reactive assignment above was executed before
    // this function, so instead we set it here on change in a controlled manner!
    selected = event.detail?.selected ?? event.target?.value ?? event.detail
    value = options[selected]?.[0]

    // Gotcha: This is usually an INPUT event and not change! It seems that's how <Select> does it in Svelma...
    dispatch(event.type, event.detail ?? event)
  }
</script>

{#key error}
  <Field {label} type={error ? 'is-danger' : ''} message={error || hint} class="{$$props.class ?? ''} {col ? `column is-${col}` : ''}">
    <!-- It would appear that Svelma's <Select> uses input and not change as event... -->
    <Select expanded {selected} {icon} iconPack="fas" class="{error ? 'is-danger' : ''} {inputClass ?? ''}" on:change={updateValue} on:input={updateValue} {...disabled || readonly ? { disabled: true } : {}} on:focus>
      {#each Object.entries(options) as [index, [key, text]]}
        <option value={index} data-key={key}>{text}</option>
      {/each}
    </Select>
  </Field>
{/key}
