'use strict'

import { apiCall } from './api'
import { Toast, Dialog } from 'svelma'

export async function checkAutoPublish (auditId) {
  if (auditId?.id) auditId = auditId.id // In case an audit object is passed
  if (!auditId) return
  const { status } = await apiCall('GET', `/audits/${auditId}`, { select: 'status' })
  if (status !== 'completed') return
  const result = await apiCall('GET', '/auditLogs', { select: 'id', limit: 1, filter: JSON.stringify({ audit: auditId, verifiedBy: null }) })
  if (result.length) return

  // Seems it's completed and there are no non-verified audit logs for it... suggestion setting to published
  if (await Dialog.confirm({
    message: 'Der Prüftermin ist abgeschlossen und alle Prüfprotokolle sind bereits kontrolliert worden. Prüfungsergebnisse jetzt veröffentlichen (Prüftermin freigeben)?',
    confirmText: 'Ja',
    cancelText: 'Nein',
    type: 'is-info',
    icon: 'check-double'
  })) {
    try {
      await apiCall('PATCH', `/audits/${auditId}`, { status: 'published' })
      Toast.create({ message: 'Prüftermin freigegeben.', type: 'is-success' })
    } catch (e) {
      console.error(e)
      Dialog.alert({ message: escape(e.serverErrorMessage || `Die Änderungen konnten nicht gespeichert werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
    }
  }
}
