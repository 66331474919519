<script>
  export let title = null
  export let icon = null
  export let closeable = false
  export let reloadable = false
  export let width = null

  export let tabs = null
  export let tab = tabs ? Object.keys(tabs)[0] : undefined

  import { onMount, createEventDispatcher } from 'svelte'
  import { Icon } from 'svelma'
  import { fly } from 'svelte/transition'

  let element

  const dispatch = createEventDispatcher()

  onMount(() => {
    const viewStackElement = document.getElementById('view-stack')
    if (viewStackElement) viewStackElement.scrollLeft = viewStackElement.scrollWidth - viewStackElement.clientWidth // Scroll to the very end always
  })

  function close () {
    dispatch('close')
  }

  function reload () {
    dispatch('reload')
  }
</script>

<style lang="scss">
  .panel {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    position: relative;
    background: white;
    width: 400px;
    max-width: 90vw;
    overflow-y: auto; // In case the non-scrollable area becomes too large as well

    z-index: 10; // Because things like input field icons have index 4

    :global(.panel-block:last-child) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      border-bottom: 1px solid $border;
    }
  }

  @media (max-width: 480px) {
    .panel {
      width: 100% !important;
      max-width: none;
    }
  }

  main {
    overflow: auto;
    flex: 1;
    border-bottom: 1px solid $border;
    min-height: 150px;

    & > :global(.notification) {
      margin: 0.75em;
    }
  }

  .panel-heading {
    border-radius: 0;

    :global(.icon) {
      margin-right: 0.5em;
    }
  }

  .delete.reload {
    font-size: 55%;
    color: white;

    &:before, &:after {
      content: none;
    }

    span {
      display: block;
    }
  }

  .title-icons button {
    margin-left: 10px;
  }

  .level-left {
    flex: 1;
    word-break: break-word;
  }
</style>

<nav class="panel pane" in:fly|local={{ duration: 250, x: -25 }} bind:this={element} style={width ? `width: ${typeof width === 'number' ? width + 'px' : width};` : ''}>
  {#if title || closeable || reloadable}
    <div class="panel-heading">
      <div class="level is-mobile">
        <div class="level-left">
          {#if icon}
            <Icon {icon} />
          {/if}
          {title ?? ''}
        </div>
        <div class="level-right title-icons">
          {#if reloadable}<button class="delete is-medium reload" aria-label="reload" on:click={reload} title="Neu laden"><span class="fa fa-sync"></span></button>{/if}
          {#if closeable}<button class="delete is-medium" aria-label="close" on:click={close} title="Schließen"></button>{/if}
        </div>
      </div>
    </div>
  {/if}

  <slot name="header" {tab} />

  {#if tabs}
    <p class="panel-tabs">
      {#each Object.entries(tabs) as [id, label] (id)}
        <a class:is-active={tab === id} href={undefined} on:click={() => (tab = id)}>{label}</a>
      {/each}
    </p>
  {/if}

  <slot name="subHeader" {tab} />

  <main>
    <slot {tab} />
  </main>

  <slot name="footer" {tab} />
</nav>
