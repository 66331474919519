<script>
  export let open = false
  export let forceClose = false

  $: if (forceClose && open) open = false

  let element

  function toggle () {
    open = forceClose ? false : !open
  }

  function bodyClick (event) {
    if (event.target.closest('.dropdown') !== element) open = false
  }

  function ddClick (event) {
    // Caller can use stopPropagation to prevent this
    if (event.target.closest('.dropdown-item')) open = false
  }
</script>

<style lang="scss">
  .dropdown, .dropdown-trigger {
    width: 100%;
  }
</style>

<svelte:body on:click={bodyClick} />

<div class="dropdown {$$props.class}" bind:this={element} class:is-active={open}>
  <slot name="customTrigger" {open} {toggle} />

  {#if $$slots.trigger}
    <div class="dropdown-trigger" on:click={toggle}>
      <slot name="trigger" {open} {toggle} />
    </div>
  {/if}

  <div class="dropdown-menu" role="menu">
    <div class="dropdown-content" on:click={ddClick}>
      <slot {open} {toggle} />
    </div>
  </div>
</div>
