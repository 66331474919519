'use strict'

/* eslint-env browser */

import { apiCall } from './api'
import { appInfo } from './appInfo'
import { getAuditLogIssues } from './auditQuestions'
import ViewSelectDialog from '../views/ViewSelectDialog.svelte'
import { Toast, Dialog } from 'svelma'
import { open } from '../stores/viewStack'
import { escape } from './utils'

// This function must be called only with audit logs of the same audit!
export async function batchServiceRequest (items, setActionInProgress = () => {}, onSave = () => {}) {
  const issues = []
  setActionInProgress(true)
  try {
    const relevantItems = items.filter(s => s.overallState === 'red' || s.overallState === 'yellow')

    if (!relevantItems.length) throw new Error('Nothing to do')

    // Specifying audit is required for the query to be permitted as customer
    const { audit } = await apiCall('GET', `/auditLogs/${relevantItems[0].id}`, { select: 'audit' })
    // Refetching because the data passed to the function will likely not have all fields selected
    const auditLogs = await apiCall('GET', '/auditLogs', {
      filter: JSON.stringify({
        audit,
        _id: { $in: relevantItems.map(item => item.id) }
      })
    })
    auditLogs.sort((a, b) => a.auditObjectData?.idNumber - b.auditObjectData?.idNumber)

    for (const auditLog of auditLogs) {
      for (const issue of Object.values(getAuditLogIssues(auditLog))) {
        issues.push({
          auditLog,
          issue,
          label: `${auditLog.auditObjectData?.publicId} / ${issue.questionDisplayId} ${issue.questionLabel}: ${issue.description}`,
          index: issues.length
        })
      }
    }
  } finally {
    setActionInProgress(false)
  }

  open(ViewSelectDialog, {
    title: 'Mängel für Reparaturauftrag wählen',
    options: [...issues.filter(i => i.issue.state === 'red'), ...issues.filter(i => i.issue.state === 'yellow')]
      .map(({ issue: { state }, label, index }) => [index, `<span class="fas fa-circle ${state === 'red' ? 'has-text-danger' : 'has-text-warning'}"></span> ${escape(label)}`]),
    htmlOptions: true,
    multiple: true,
    requiredCheckboxLabel: appInfo.user.isCustomer ? 'Ich möchte hiermit rechtsverbindlich die Reparatur der ausgewählten Mängel in Auftrag geben und bin mir bewusst, dass dafür Kosten anfallen können.' : null,
    onSave: async indices => {
      setActionInProgress(true)

      try {
        const serviceLogsToBeCreated = {}
        for (const index of indices) {
          const { auditLog, issue } = issues[index]
          if (!serviceLogsToBeCreated[auditLog.auditObject]) {
            serviceLogsToBeCreated[auditLog.auditObject] = {
              auditObject: auditLog.auditObject,
              auditLog: auditLog.id,
              fixedIssues: []
            }
          }
          serviceLogsToBeCreated[auditLog.auditObject].fixedIssues.push(issue)
        }

        if (Object.keys(serviceLogsToBeCreated).length === 0) {
          Dialog.alert({ message: 'Keine Mängel ausgewählt.', type: 'is-warning', icon: 'exclamation-circle' })
          return
        }

        if (!await Dialog.confirm({
          message: Object.keys(serviceLogsToBeCreated).length > 1 ? `Hiermit werden ${Object.keys(serviceLogsToBeCreated).length} Service-Anforderungen erzeugt. Fortfahren?` : 'Hiermit wird eine Service-Anforderung erzeugt. Fortfahren?',
          confirmText: 'Ja',
          cancelText: 'Nein',
          type: 'is-info',
          icon: 'list-ol'
        })) return

        await apiCall('POST', '/serviceLogs/batchServiceRequest', { user: appInfo.user.id, serviceLogs: Object.values(serviceLogsToBeCreated) })
        Toast.create({ message: 'Service-Anforderungen wurden abgeschickt.', type: 'is-success' })
        onSave()
      } catch (e) {
        console.error(e)
        Dialog.alert({ message: escape(e.serverErrorMessage || `Die Änderungen konnten nicht gespeichert werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
      } finally {
        setActionInProgress(false)
      }
    }
  }, true)
}

