<script>
  export let closeSelf
  export let title = 'QR-/Barcode scannen'
  export let onScan = () => {}
  export let beepOnScan = true

  import Modal from '../components/Modal.svelte'
  import Loader from '../components/Loader.svelte'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import { Button, Icon } from 'svelma'
  import { onMount, onDestroy } from 'svelte'
  import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode'

  let scanner
  let done = false
  let loadingPromise
  const previewId = `scannerId${Math.floor(Math.random() * 1e9)}`

  let videoTrack
  let hasFlashlight = false
  let flashOn = false

  const audioContext = window.AudioContext ? new AudioContext() : undefined

  function beep (freq = 520, duration = 200, vol = 100) {
    if (!audioContext) return
    try {
      const oscillator = audioContext.createOscillator()
      const gain = audioContext.createGain()
      oscillator.connect(gain)
      oscillator.frequency.value = freq
      oscillator.type = 'square'
      gain.connect(audioContext.destination)
      gain.gain.value = vol * 0.01
      oscillator.start(audioContext.currentTime)
      oscillator.stop(audioContext.currentTime + duration * 0.001)
    } catch (e) {} // If browser doesn't support it, it doesn't matter
  }

  onMount(async () => {
    scanner = new Html5Qrcode(previewId, {
      formatsToSupport: [
        Html5QrcodeSupportedFormats.QR_CODE,
        Html5QrcodeSupportedFormats.UPC_A
      ]
    })
    loadingPromise = scanner.start({
      facingMode: 'environment'
    }, {
      fps: 10,
      qrbox: 240,
      experimentalFeatures: {
        useBarCodeDetectorIfSupported: true
      }
    }, (decodedText, decodedResult) => {
      if (done) return // We want only one code per scan
      console.log('Barcode scan result:', decodedText, decodedResult)
      const [, barcodeNumber] = decodedText.match(/^(?:rett:)?([0-9]{12})$/) ?? []
      if (barcodeNumber) {
        done = true
        if (beepOnScan) beep()
        console.log('Returning barcode number:', barcodeNumber)
        onScan(barcodeNumber)
        closeSelf()
      }
    }, error => {
      if (!String(error).includes('NotFoundException')) console.error('Error while scanning:', error)
    })

    try {
      await loadingPromise
      videoTrack = scanner.videoElement.srcObject.getVideoTracks()[0]

      if (!window.ImageCapture) {
        console.log('No ImageCapture API available')
      } else {
        try {
          const imageCapture = new ImageCapture(videoTrack)
          const capabilities = await imageCapture.getPhotoCapabilities()
          hasFlashlight = !!capabilities.fillLightMode?.includes('flash')
        } catch (e) {
          console.warn('Failed to get flash capabilities:', e)
        }
      }
    } catch (e) {
      console.error('Error while initializing scanner:', e)
    }
  })

  onDestroy(async () => {
    if (!scanner) return
    try {
      await scanner.stop()
    } catch (e) {
      console.log('Error stopping barcode scanner', e)
    }
  })

  async function toggleFlashlight () {
    if (!videoTrack || !hasFlashlight) return
    flashOn = !flashOn
    await videoTrack.applyConstraints({
      advanced: [{ torch: flashOn }]
    })
  }
</script>

<style lang="scss">
  section {
    width: 260px;
    min-height: 200px;
    margin: auto;
    overflow: hidden;
  }

  @media (min-width: 400px) {
    section {
      width: 320px;
    }
  }

  div {
    position: relative;
  }
</style>

<Modal {title} closeable on:close={closeSelf}>
  <div>
    <section id={previewId}></section>

    {#if loadingPromise}
      {#await loadingPromise}
        <Loader cover />
      {:then}
        <!-- All fine here -->
      {:catch error}
        <ErrorDisplay {error} />
      {/await}
    {/if}
  </div>

  <svelte:fragment slot="footer">
    <div class="left-buttons">
      {#if hasFlashlight}
        <span title="LED-Leuchte"><Button class="no-min-width {flashOn ? 'has-text-warning' : ''}" type="is-default" on:click={toggleFlashlight}><Icon icon="lightbulb" /></Button></span>
      {/if}
    </div>

    <Button on:click={closeSelf}>Abbrechen</Button>
  </svelte:fragment>
</Modal>
