<script>
  export let topView
  export let closeSelf
  export let closeHigher
  export let openAndReplace

  import ResourceView from '../components/ResourceView.svelte'
  import ViewUserDetails from './ViewUserDetails.svelte'
  import ViewUserForm from './ViewUserForm.svelte'
  import { Icon } from 'svelma'
  import { appInfo } from '../lib/appInfo'

  let tab
</script>

<ResourceView
  {topView} {closeSelf} {closeHigher} {openAndReplace}
  title="Benutzerliste"
  getChildTitle={record => (record.fullName && record.username) ? `${record.fullName} (${record.username})` : undefined}
  order="fullName"
  select="username,fullName,admin,suspended,isCustomer,limitProperties"
  getItems={(items, tab) => items.filter(item => !!item.isCustomer === (tab === 'customers'))}
  childIcon="user"
  childResourcePath="/users"
  childResourceName={tab === 'employees' ? 'Mitarbeiter' : 'Kunde'}
  childResourceGender="m"
  ViewChildDetails={ViewUserDetails}
  childDetailsProps={{ openedFrom: 'userList' }}
  ViewChildForm={ViewUserForm}
  tabs={{ employees: 'Mitarbeiter', customers: 'Kunden' }}
  bind:tab
  search={(searchQuery, searchRegex, record) => record.username?.match(searchRegex) || record.fullName?.match(searchRegex)}
  let:record
  allowCreate={tab === 'employees'}
  readonly={!appInfo.user.admin}
  hideId={appInfo.user.isCustomer}
>
  <span class="list-entry" class:is-disabled={record.suspended}>
    {record.fullName} ({record.username})
    {#if record.admin}
      <span title="Administrator"><Icon icon="star" /></span>
    {/if}
    {#if record.isCustomer && !record.limitProperties}
      <span title="Zugriff auf alle Liegenschaften"><Icon icon="crown" /></span>
    {/if}
  </span>
</ResourceView>
