<script>
  export let closeSelf
  export let title = 'Auswahl'
  export let options = []
  export let asyncOptions = Promise.resolve(options)
  export let htmlOptions = false
  export let multiple = false
  export let requiredCheckboxLabel = null
  export let onSave

  let requiredCheckboxAccepted = !requiredCheckboxLabel

  import { Button, Icon, Field } from 'svelma'
  import Modal from '../components/Modal.svelte'

  asyncOptions.then(result => { if (options !== result) options = result })

  export let selected

  $: if (multiple && !Array.isArray(selected)) selected = []

  $: saveDisabled = multiple ? (!selected.length || selected.some(s => !options.some(([o]) => o === s))) : (selected === undefined || !options.some(([o]) => o === selected))

  function save () {
    onSave(selected)
    closeSelf()
  }

  function select (key) {
    if (multiple) {
      if (selected.includes(key)) {
        selected = selected.filter(item => item !== key)
      } else {
        selected = selected.concat([key])
      }
    } else {
      selected = key
    }
  }
</script>

<style lang="scss">
  :global(.modal.view-select-dialog.with-checkbox .modal-card-foot) {
    flex-wrap: wrap;

    :global(.field.required-checkbox) {
      flex-basis: 100%;
      margin-bottom: 1rem;
    }
  }

  .menu-list input[type=checkbox] {
    cursor: pointer;
  }
</style>

<Modal {title} closeable on:close={closeSelf} class="view-select-dialog {requiredCheckboxLabel ? 'with-checkbox' : ''}">
  <aside class="menu">
    <ul class="menu-list">
      {#each options as [key, label]}
        <li>
          <a href={undefined} class:is-active={multiple ? selected.includes(key) : (key === selected)} on:click={() => select(key)}>
            {#if multiple}
              <input type="checkbox" checked={multiple ? selected.includes(key) : (key === selected)} on:change|preventDefault={() => {}} />&nbsp;
            {/if}
            {#if htmlOptions}
              {@html label}
            {:else}
              {label}
            {/if}
          </a>
        </li>
      {/each}
    </ul>
  </aside>

  <svelte:fragment slot="footer">
    {#if requiredCheckboxLabel}
      <Field class="required-checkbox">
        <label class="checkbox">
          <input type="checkbox" bind:checked={requiredCheckboxAccepted}>
          {requiredCheckboxLabel}
        </label>
      </Field>
    {/if}

    {#if multiple}
      <div class="left-buttons">
        <span title="Alle auswählen"><Button class="no-min-width" type="is-default" disabled={!options.some(o => !selected.includes(o[0]))} on:click={() => (selected = options.map(o => o[0]))}><Icon icon="check-double" /></Button></span>
        <span title="Keine auswählen"><Button class="no-min-width" type="is-default" disabled={!selected.length} on:click={() => (selected = [])}><Icon icon="times" /></Button></span>
      </div>
    {/if}

    <Button type="is-primary" on:click={save} disabled={saveDisabled || !requiredCheckboxAccepted}>OK</Button>
    <Button on:click={closeSelf}>Abbrechen</Button>
  </svelte:fragment>
</Modal>
