<script>
  export let label = null
  export let icon = null
  export let value = [] // Format: [id]
  export let error = null
  export let hint = null
  export let col = null
  export let options = [] // Async source is currently not supported here
  export let readonly = false
  export let disabled = false
  export let inputClass = null
  export let selectorTitle = 'Element hinzufügen'

  import { Field, Icon } from 'svelma'
  import ViewSelectDialog from '../views/ViewSelectDialog.svelte'
  import { open } from '../stores/viewStack'

  import { createEventDispatcher } from 'svelte'

  let inputElement

  const dispatch = createEventDispatcher()

  function onDelete (id) {
    if (disabled || inputElement.closest('fieldset')?.disabled) return
    value = value.filter(v => v !== id)
    dispatch('change', { value })
  }

  function onAdd () {
    if (disabled || inputElement.closest('fieldset')?.disabled) return

    open(ViewSelectDialog, {
      title: selectorTitle,
      options: options.filter(([id]) => !value.includes(id)),
      multiple: true,
      onSave: ids => {
        value = [...value, ...ids]
        dispatch('change', { value })
      }
    }, true)
  }
</script>

<style lang="scss">
  .input {
    height: auto !important;
    min-height: 2.5em;
    padding-top: calc(0.4em - 1px);
    padding-bottom: calc(0.4em - 1px);

    .control {
      margin-bottom: .1em !important;
      margin-top: .1em !important;
    }

    .is-delete.is-danger:hover {
      background-color: #f03a5f;
      cursor: pointer;
    }
  }

  :global(fieldset[disabled]) .input {
    .is-delete.is-danger, .add-tag {
      background-color: #e5e5e5 !important;
      cursor: not-allowed;
    }
  }
</style>

{#key error}
  <Field {label} type={error ? 'is-danger' : ''} message={error || hint} class="{$$props.class ?? ''} {col ? `column is-${col}` : ''}">
    <div class="control" class:has-icons-left={!!icon}>
      <div bind:this={inputElement} class="field input is-grouped is-grouped-multiline {error ? 'is-danger' : ''} {inputClass ?? ''}" {...disabled ? { disabled: true } : {}} tabindex="0">
        {#each value as id}
          <div class="control">
            <div class="tags has-addons">
              <span class="tag">{options.find(o => o[0] === id)?.[1] ?? id}</span>
              {#if !readonly && !disabled}
                <span class="tag is-delete is-small is-danger" on:click={() => onDelete(id)}></span>
              {/if}
            </div>
          </div>
        {/each}

        {#if !disabled && !readonly && options.some(([id]) => !value.includes(id))}
          <div class="control">
            <div class="tags has-addons">
              <span class="tag is-success button add-tag" on:click={onAdd}>
                <i class="fas fa-plus"></i>&nbsp;Hinzufügen
              </span>
            </div>
          </div>
        {/if}
      </div>
      {#if icon}
        <Icon {icon} isLeft />
      {/if}
    </div>
  </Field>
{/key}
