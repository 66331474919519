<script>
  export let topView
  export let closeSelf
  export let closeHigher
  export let openAndReplace

  export let id
  export let title
  export let onSave
  export let isolated = false

  import ResourceView from '../components/ResourceView.svelte'
  import { escape, formatCustomDate, monthToYmdString, numPad } from '../lib/utils'
  import ViewPropertyForm from './ViewPropertyForm.svelte'
  import ViewAuditDetails from './ViewAuditDetails.svelte'
  import ViewAuditForm from './ViewAuditForm.svelte'
  import ViewAuditObjectDetails from './ViewAuditObjectDetails.svelte'
  import ViewAuditObjectForm from './ViewAuditObjectForm.svelte'
  import ViewCustomerDetails from './ViewCustomerDetails.svelte'
  import { apiCall } from '../lib/api'
  import intervals from '../lib/intervals'
  import { Notification, Icon, Toast, Dialog, Button } from 'svelma'
  import { tick } from 'svelte'
  import Detail from '../components/Detail.svelte'
  import { apiUrl } from '../lib/env'
  import { appInfo } from '../lib/appInfo'
  import { batchServiceRequest } from '../lib/batchServiceRequest'

  let tab

  let selected
  let silentReload
  let actionInProgress
  let batchServiceRequestInProgress

  let lastAudit
  let lastAuditLogs = []
  let lastAuditLogsByAuditObject = {}

  async function openCustomer (customer) {
    if (!customer) throw new Error('No customer set')
    selected = null
    await tick()
    openAndReplace(ViewCustomerDetails, { id: customer.id, onSave: silentReload, isolated: true })
  }

  async function generateReport (auditId) {
    window.open(`${apiUrl}/audits/${auditId}/report`)
  }

  async function terminate (mainRecord) {
    if (await Dialog.confirm({
      message: `Liegenschaft ${mainRecord.name} wirklich kündigen?`,
      confirmText: 'Ja',
      cancelText: 'Nein',
      type: 'is-warning'
    })) {
      actionInProgress = true
      try {
        await apiCall('PATCH', `/properties/${id}`, { interval: null })
        Toast.create({ message: 'Liegenschaft wurde gekündigt.', type: 'is-success' })
        silentReload({ id })

        if (await Dialog.confirm({
          message: `Kündigung erfolgreich! Sollen jetzt auch alle zukünftigen Termine von ${mainRecord.name} abgebrochen bzw. gelöscht werden?`,
          confirmText: 'Ja',
          cancelText: 'Nein',
          type: 'is-danger'
        })) {
          try {
            await apiCall('POST', `/properties/${id}/rescheduleAudits`)
            Toast.create({ message: 'Termine wurden neu geplant.', type: 'is-success' })
            silentReload({ id })
          } catch (e) {
            console.error(e)
            Dialog.alert({ message: escape(e.serverErrorMessage || `Beim Neu-Planen der Termine ist ein Problem aufgetreten!\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
          }
        }
      } catch (e) {
        console.error(e)
        Dialog.alert({ message: escape(e.serverErrorMessage || `Die Änderungen konnten nicht gespeichert werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
      } finally {
        actionInProgress = false
      }
    }
  }

  async function rescheduleAudits (mainRecord) {
    if (await Dialog.confirm({
      message: `Hiermit werden alle geplanten, noch nicht begonnenen Termine von ${mainRecord.name} abgebrochen oder gelöscht (je nachdem, ob bereits eigene Daten darin gespeichert wurden oder nicht) und danach neue Termine bis zum Ende des nächsten Jahres geplant. Bestehende Termine im selben Monat werden wiederverwendet. Dies kann großflächige Änderungen an den Terminen bewirken. Sicher fortfahren?`,
      confirmText: 'Ja',
      cancelText: 'Nein',
      type: 'is-danger'
    })) {
      actionInProgress = true
      try {
        await apiCall('POST', `/properties/${id}/rescheduleAudits`)
        Toast.create({ message: 'Termine wurden neu geplant.', type: 'is-success' })
        silentReload({ id })
      } catch (e) {
        console.error(e)
        Dialog.alert({ message: escape(e.serverErrorMessage || `Beim Neu-Planen der Termine ist ein Problem aufgetreten!\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
      } finally {
        actionInProgress = false
      }
    }
  }

  async function generateAuditObjectList (mainRecord) {
    window.open(`${apiUrl}/properties/${mainRecord.id}/auditObjectList`)
  }

  async function generateAuditObjectPhotoGrid (mainRecord) {
    window.open(`${apiUrl}/properties/${mainRecord.id}/auditObjectPhotoGrid`)
  }

  function getAuditObjectStatusForDisplay (record) {
    if (!record.publicId || record.decommissioned || lastAudit?.status !== 'published') {
      return undefined
    } else {
      return lastAuditLogsByAuditObject?.[record.id]?.overallState
    }
  }

  async function generateIssueList (lastAudit) {
    window.open(`${apiUrl}/audits/${lastAudit.id}/issueList`)
  }
</script>

<style lang="scss">
  .fix-notification-icon :global(.icon) {
    transform: translateY(0.75rem);
  }
</style>

<ResourceView
  {topView} {closeSelf} {closeHigher} {openAndReplace}
  {id} {title}
  icon="building"
  defaultTitle="Liegenschaft-Details"
  {onSave}
  bind:actionInProgress
  mainPopulate={JSON.stringify([['customer', 'company customerNo']])}
  getTitle={record => record.name}
  getChildTitle={record => record.publicId ? `${record.publicId}: ${record.manufacturer ?? ''} ${record.serialNo ?? ''} ${!record.manufacturer && !record.serialNo ? 'Unbekannt' : ''}` : 'Prüfprotokoll vom ' + (formatCustomDate(record.startDate) + (record.endDate && record.endDate !== record.startDate ? ` - ${formatCustomDate(record.endDate)}` : '') + (!record.startDate?.startsWith(`${numPad(record.year, 4)}-${numPad(record.month, 2)}`) ? ` (${numPad(record.month, 2)}.${numPad(record.year, 4)})` : ''))}
  childIcon={record => record.publicId ? 'dice-d6' : 'calendar-check'}
  handleLoad={async (id, mainRecord) => {
    let items = await apiCall('GET', '/auditObjects', { order: 'idNumber', select: 'idNumber,publicId,manufacturer,serialNo,barcode,decommissioned', filter: JSON.stringify({ property: id }) })
    if (!appInfo.user.isCustomer) {
      items = items.concat(await apiCall('GET', '/audits', { order: '-startDate', select: 'type,startDate,endDate,month,year,status,overallState', filter: JSON.stringify({ property: id }) }))
    } else {
      const result = await apiCall('GET', '/audits', { order: '-startDate', select: 'type,startDate,endDate,month,year,status,overallState', filter: JSON.stringify({ property: id, status: { $in: ['inProgress', 'completed', 'published'] }, type: mainRecord.civilAuditMode === 'additional' ? 'regular' : { $in: ['regular', 'civil'] } }), limit: 1 })
      lastAudit = result[0] // eslint-disable-line no-unused-vars

      lastAuditLogs = []
      lastAuditLogsByAuditObject = {}
      if (lastAudit) {
        lastAuditLogs = await apiCall('GET', '/auditLogs', { select: 'id,auditObject,overallState', filter: JSON.stringify({ audit: lastAudit.id }) }) // eslint-disable-line no-unused-vars
        lastAuditLogsByAuditObject = Object.fromEntries(lastAuditLogs.map(l => [l.auditObject, l])) // eslint-disable-line no-unused-vars
      }
    }
    return { items }
  }}
  getItems={(items, tab) => (tab === 'auditObjects' || appInfo.user.isCustomer) ? items.filter(item => item.publicId) : items.filter(item => !item.publicId)}
  resourcePath="/properties"
  resourceName="Liegenschaft"
  childResourceName={(tab === 'auditObjects' || appInfo.user.isCustomer) ? 'Prüfkörper' : 'Prüftermin'}
  childResourceGender="m"
  ViewMainForm={ViewPropertyForm}
  ViewChildDetails={(tab === 'auditObjects' || appInfo.user.isCustomer) ? ViewAuditObjectDetails : ViewAuditDetails}
  ViewChildForm={(tab === 'auditObjects' || appInfo.user.isCustomer) ? ViewAuditObjectForm : ViewAuditForm}
  tabs={appInfo.user.isCustomer ? undefined : { auditObjects: 'Prüfkörper', audits: 'Prüftermine' }}
  bind:tab
  search={(searchQuery, searchRegex, record) => [record.manufacturer ?? '', record.serialNo ?? ''].join(' ').match(searchRegex) || record.publicId?.replace(/-.*$/, '') === searchQuery.toUpperCase() || record.publicId === searchQuery.toUpperCase() || record.barcode === searchQuery || (record.startDate && formatCustomDate(record.startDate).match(searchRegex)) || (record.endDate && formatCustomDate(record.endDate).match(searchRegex))}
  allowEdit={!appInfo.user.isCustomer}
  allowDelete={!appInfo.user.isCustomer}
  canDelete={(mainRecord, items) => !items.length}
  afterDelete={async () => {
    try {
      const users = await apiCall('GET', '/users', { select: 'id,properties,limitProperties,fullName,username', filter: JSON.stringify({ properties: id }) })
      let deleted = 0
      for (const user of users) {
        if (user.limitProperties && user.properties.length === 1) {
          if (await Dialog.confirm({
            message: `Der Benutzer ${user.fullName} (${user.username}) hat nach Löschen dieser Liegenschaft Zugriff auf keine anderen Liegenschaften mehr. Soll der Benutzer auch gelöscht werden?`,
            confirmText: 'Ja',
            cancelText: 'Nein',
            type: 'is-info'
          })) {
            await apiCall('DELETE', `/users/${user.id}`)
            deleted++
          }
        } else {
          await apiCall('PATCH', `/users/${user.id}`, { properties: user.properties.filter(property => property !== id) })
        }
      }
      if (deleted) Toast.create({ message: `${deleted} Kundenbenutzer wurde(n) gelöscht.`, type: 'is-success' })
    } catch (e) {
      console.error(e)
      Toast.create({ message: escape(`Beim Löschen/Anpassen der Kundenbenutzer ist ein Fehler aufgetreten! Die Liegenschaft selbst wurde jedoch gelöscht.\n\nTechnische Informationen: ${e.serverErrorMessage || e}`), type: 'is-danger' })
    }
  }}
  readonly={!appInfo.user.admin}
  hideId={appInfo.user.isCustomer}
  let:record
  bind:silentReload
  bind:selected
>
  <svelte:fragment slot="header" let:mainRecord>
    {#if isolated && !appInfo.user.isCustomer}
      <div class="panel-block">
        <Detail title="Kunde" icon="industry" ready={mainRecord}>
          <a href={undefined} on:click={() => openCustomer(mainRecord.customer)}>{mainRecord?.customer?.company} (#{mainRecord?.customer?.customerNo})</a>
        </Detail>
      </div>
    {/if}

    <div class="panel-block">
      <Detail title="Anschrift" icon="envelope" ready={mainRecord?.address}>
        {mainRecord?.address?.split('\n').join('; ')}

        {#if mainRecord?.latitude != null && mainRecord?.longitude != null}
          <a href="https://maps.google.com/maps?q={mainRecord.latitude}%2C{mainRecord.longitude}&z=17" target="_blank" title="GPS-Koordinaten"><Icon icon="map-marked" /></a>
        {/if}
      </Detail>
    </div>

    <div class="panel-block">
      {#if mainRecord}
        <span>
          <Detail title="Ansprechpartner" icon="user-tie" value={mainRecord.contact} />
          <Detail title="Telefonnummer" icon="phone" value={mainRecord.phoneNumber} />
          <Detail title="Email" icon="at" value={mainRecord.email} />
        </span>
      {:else}
        ...
      {/if}
    </div>

    <div class="panel-block">
      {#if mainRecord}
        <span>
          <Detail title="Intervall" icon="step-forward" ready={mainRecord}>
            <span class:has-text-danger={!mainRecord?.interval}>{intervals.get(mainRecord?.interval ?? null)}</span>
          </Detail>
          {#if !appInfo.user.isCustomer}
            <Detail title="Referenztermin" icon="calendar-alt" onlyIf={mainRecord.referenceMonth && mainRecord.referenceYear}>
              {formatCustomDate(monthToYmdString(mainRecord.referenceMonth, mainRecord.referenceYear))}
            </Detail>
            <Detail title="Ziviltechniker-Prüfung" icon="user-ninja" onlyIf={mainRecord.civilAuditMode !== 'none'}>
              alle {mainRecord.civilAuditIntervalYears} J.
              {#if mainRecord.civilAuditMode === 'additional'}
                (+)
              {/if}
            </Detail>
          {/if}
        </span>
      {/if}
    </div>

    {#if mainRecord?.notes && !appInfo.user.isCustomer}
      <div class="panel-block">
        <Detail title="Notizen" icon="sticky-note">
          {mainRecord.notes.split('\n').join('; ')}
        </Detail>
      </div>
    {/if}

    {#if appInfo.user.isCustomer}
      <div class="panel-block">
        {#if mainRecord}
          <Detail title="Letzte Prüfung" icon="calendar-check">
            {#if lastAudit}
              {formatCustomDate(lastAudit.startDate)}
              {#if lastAudit.endDate && lastAudit.endDate !== lastAudit.startDate}
                {' - '}
                {formatCustomDate(lastAudit.endDate)}
              {/if}
              {#if lastAudit.status === 'inProgress'}
                (<strong class="has-text-warning">in Arbeit</strong>)
              {:else if lastAudit.status === 'completed'}
                (<strong class="has-text-success">wird fertiggestellt</strong>)
              {:else if lastAudit.status === 'published'}
                (<strong class="has-text-info">Ergebnis liegt vor</strong>)
              {/if}
            {:else}
              <span class="has-text-grey-light">Noch keine Prüfungen durchgeführt</span>
            {/if}
          </Detail>
        {:else}
          ...
        {/if}
      </div>

      {#if lastAudit?.type === 'civil'}
        <div class="panel-block fix-notification-icon">
          <Notification type="is-info" icon={true} showClose={false}>
            Prüfung durchgeführt von Ziviltechniker, nur Einzelprotokolle verfügbar
          </Notification>
        </div>
      {/if}

      {#if mainRecord && lastAudit?.status === 'published' && lastAudit?.type !== 'civil'}
        <div class="panel-block">
          <div class="container">
            <div class="columns is-mobile">
              <div class="column is-12">
                <Button iconLeft="file-invoice" type="is-link" outlined class="is-fullwidth" on:click={() => generateReport(lastAudit.id)}>Protokollmappe öffnen</Button>
              </div>
            </div>
          </div>
        </div>

        {#if lastAuditLogs.some(log => log.overallState === 'red' || log.overallState === 'yellow')}
          <div class="panel-block">
            <div class="container">
              <div class="columns is-mobile">
                <div class="column is-12">
                  <Button iconLeft="wrench" type="is-warning" outlined class="is-fullwidth" loading={batchServiceRequestInProgress} on:click={() => batchServiceRequest(lastAuditLogs, v => (batchServiceRequestInProgress = v), () => silentReload({ id }))}>Massen-Reparaturauftrag</Button>
                </div>
              </div>
            </div>
          </div>

          <div class="panel-block">
            <div class="container">
              <div class="columns is-mobile">
                <div class="column is-12">
                  <Button iconLeft="print" outlined class="is-fullwidth" on:click={() => generateIssueList(lastAudit)}>Mängelliste drucken</Button>
                </div>
              </div>
            </div>
          </div>
        {/if}
      {/if}
    {/if}
  </svelte:fragment>

  <svelte:fragment slot="actions" let:mainRecord>
    {#if appInfo.user.admin}
      <a href={undefined} class="dropdown-item" on:click={() => rescheduleAudits(mainRecord)}><Icon icon="sync" /> Termine neu planen</a>
      <hr class="dropdown-divider" />
    {/if}

    {#if !appInfo.user.isCustomer}
      <a href={undefined} class="dropdown-item" on:click={() => generateAuditObjectList(mainRecord)}><Icon icon="list-alt" /> Prüfkörperliste drucken</a>
      <a href={undefined} class="dropdown-item" on:click={() => generateAuditObjectPhotoGrid(mainRecord)}><Icon icon="images" /> Foto-Übersicht drucken</a>
    {:else}
      <em class="dropdown-item">Keine Aktionen verfügbar</em>
    {/if}

    {#if mainRecord?.interval && appInfo.user.admin}
      <a href={undefined} class="dropdown-item has-text-danger" on:click={() => terminate(mainRecord)}><Icon icon="times-circle" /> Kündigen</a>
    {/if}
  </svelte:fragment>

  <span class="list-entry" class:is-disabled={record.decommissioned || record.status === 'cancelled'} class:has-text-danger={getAuditObjectStatusForDisplay(record) === 'red'} class:has-text-warning={getAuditObjectStatusForDisplay(record) === 'yellow'} class:has-text-success={getAuditObjectStatusForDisplay(record) === 'green'}>
    {#if record.publicId}
      {record.publicId}: {record.manufacturer ?? ''} {record.serialNo ?? ''} {!record.manufacturer && !record.serialNo ? 'Unbekannt' : ''}
    {:else}
      {formatCustomDate(record.startDate)}
      {#if record.endDate && record.endDate !== record.startDate}
        {' - '}
        {formatCustomDate(record.endDate)}
      {/if}
      {#if record.type === 'civil'}
        (Ziviltechniker)
      {/if}
      {#if !appInfo.user.isCustomer}
        {#if !record.startDate?.startsWith(`${numPad(record.year, 4)}-${numPad(record.month, 2)}`)}
          ({numPad(record.month, 2)}.{numPad(record.year, 4)})
        {/if}
        {#if record.status === 'inProgress'}
          <span title="In Arbeit" class="has-text-warning"><Icon icon="hourglass-half" /></span>
        {/if}
        {#if record.status === 'completed'}
          <span title="Abgeschlossen" class="has-text-success"><Icon icon="check" /></span>
        {/if}
        {#if record.status === 'published'}
          <span title="Freigegeben" class="has-text-info"><Icon icon="check-double" /></span>
        {/if}
      {/if}
    {/if}
  </span>
</ResourceView>
