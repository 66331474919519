'use strict'

import { apiCall } from './api'

export const appInfo = window.appInfo = {}

export async function initAppInfo () {
  for (const k in appInfo) delete appInfo[k]
  Object.assign(appInfo, await apiCall('GET', '/info'), { initialized: true })
}
