<script>
  export let closeSelf
  export let onSave = () => {}
  export let create = false
  export let id = null
  export let parentId = null
  export let parentIdField
  export let ready = false
  export let saveEnabled = true
  export let readonly = false
  export let loading = false
  export let hideId = id === '~'
  export let record
  export let fieldErrors = {}
  export let formElement
  export let title
  export let getTitle
  export let select // undefined
  export let handleLoad = async () => {}
  export let handleSave = async () => {}
  export let initRecord = async parentId => parentIdField ? ({ [parentIdField]: parentId }) : ({})
  export let validateRecord = async record => ({ record, fieldErrors: {} })
  export let resourcePath
  export let resourceName = 'Datensatz'
  export let resourceGender = 'm'
  export let saveLabel = 'Speichern'

  export let newWord = ({ m: 'Neuer', f: 'Neue', n: 'Neues' }[resourceGender])
  $: if (record && getTitle) title = getTitle(record)

  export let defaultTitle = create ? `${newWord} ${resourceName}` : `${resourceName} bearbeiten` // Requires resourceName and newWord

  import Modal from '../components/Modal.svelte'
  import { Button, Toast, Dialog } from 'svelma'
  import Loader from '../components/Loader.svelte'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import { apiCall } from '../lib/api'
  import { tick } from 'svelte'
  import { scrollToInvalidField, escape } from '../lib/utils'

  async function load () {
    if (create) {
      record = await initRecord(parentId)
    }

    if (!create && resourcePath && id) {
      record = await apiCall('GET', id === '~' ? resourcePath : `${resourcePath}/${id}`, { select })
    }

    const result = await handleLoad(id, record, create, parentId)
    if (result !== undefined) record = result

    if (create) setTimeout(() => formElement?.querySelector('.autofocus')?.focus(), 0)
    ready = true
  }

  let cancel = false
  export let validate = async function validate () {
    ;({ record, fieldErrors, cancel = false } = await validateRecord(record))

    if (cancel) return false

    if (Object.keys(fieldErrors).length) {
      console.warn('Validation errors', fieldErrors)
      Toast.create({ message: 'Die Angaben sind ungültig oder unvollständig!', type: 'is-danger' })
      await tick()
      scrollToInvalidField(formElement)
      return false
    }

    return true
  }

  async function save () {
    loading = true
    fieldErrors = {}
    try {
      if (await validate()) {
        if (resourcePath) {
          if (create) {
            const newRecord = await apiCall('POST', resourcePath, record)
            id = newRecord.id
            record.id = id
            create = false
          } else if (id) {
            await apiCall('PATCH', id === '~' ? resourcePath : `${resourcePath}/${id}`, record)
          }
        }

        const result = await handleSave(record)
        if (result !== undefined) record = result

        Toast.create({ message: `${resourceName} wurde ${create ? 'angelegt' : 'gespeichert'}!`, type: 'is-success' })
        onSave(record)
        closeSelf()
      }
    } catch (e) {
      fieldErrors = e.responseBody?.fieldErrors ?? {}
      console.error(e)
      Dialog.alert({ message: escape(e.serverErrorMessage || `Die Änderungen konnten nicht gespeichert werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
    } finally {
      loading = false
    }
  }
</script>

<style lang="scss">
  .id-display {
    font-size: 75%;
    flex: 1;
    text-align: left;
    padding-right: 8px;
    word-break: break-word;
  }
</style>

<Modal title={title || defaultTitle} closeable large on:close={closeSelf}>
  {#await load()}
    <Loader />
  {:then}
    <div class="container">
      <form on:submit|preventDefault={() => save()} bind:this={formElement}>
        <fieldset disabled={loading || readonly}>
          <slot {record} {fieldErrors} {loading} {readonly} />
        </fieldset>
        <!-- This exists just to make Enter work for submitting form -->
        {#if !readonly}
          <button class="is-hidden"></button>
        {/if}
      </form>
    </div>
  {:catch error}
    <ErrorDisplay {error} />
  {/await}

  <svelte:fragment slot="footer">
    {#if id && !hideId}
      <span class="id-display">
        Interne ID: {id}
      </span>
    {/if}

    <slot name="buttons" {ready} {record} {fieldErrors} {loading} />

    {#if !readonly}
      <Button type="is-primary" on:click={save} disabled={!ready || !saveEnabled} {loading}>{saveLabel}</Button>
    {/if}
    <Button on:click={closeSelf}>{readonly ? 'Schließen' : 'Abbrechen'}</Button>
  </svelte:fragment>
</Modal>
