<script>
  export let cover = false
</script>

<style lang="scss">
  .loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    pointer-events: none;
    width: 100%;
    height: 115px;
    overflow: hidden;

    .loader {
      height: 80px;
      width: 80px;
    }

    &.cover {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
    }
  }
</style>

<div class="loader-wrapper {cover ? 'cover' : ''}">
  <div class="loader is-loading"></div>
</div>
