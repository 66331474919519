'use strict'

export function escape (s, nl2br = false) {
  if (!s) return s
  const i = document.createElement('i')
  i.innerText = s
  return nl2br ? i.innerHTML.replace(/\n/g, '<br>') : i.innerHTML
}

export function removeUndefined (object) { // Note that this does mutate the object
  for (const [k, v] of Object.entries(object)) {
    if (v === undefined) delete object[k]
  }
  return object
}

const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
export function generateRandomString (length) {
  let s = ''
  for (let i = 0; i < length; i++) {
    s += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length))
  }
  return s
}

export function ellipsis (s, maxLen) {
  if (!s || s.length <= maxLen) return s ?? ''
  return s.substr(0, maxLen) + '…'
}

export function numPad (n, digits) {
  if (typeof n !== 'number') return ''.padStart(digits, '-')
  return String(n).padStart(digits, '0')
}

export function ymdStringToDate (ymd) {
  const [y, m, d] = (ymd ?? '').split('-')
  const date = new Date(Number(y), Number(m) - 1, Number(d))
  if (isNaN(date)) return null
  return date
}

export function ymdStringToUtcDate (ymd) {
  const localDate = ymdStringToDate(ymd)
  if (!localDate) return null
  return Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate())
}

export function dateToYmdString (date) {
  if (!date || isNaN(date)) return null
  return `${numPad(date.getFullYear(), 4)}-${numPad(date.getMonth() + 1, 2)}-${numPad(date.getDate(), 2)}`
}

export function monthToYmdString (month, year) {
  return `${numPad(Number(year), 4)}-${numPad(Number(month), 2)}-00`
}

export function offsetYmdString (ymd, days) {
  const nativeDate = ymdStringToDate(ymd)
  if (!nativeDate) return null
  nativeDate.setDate(nativeDate.getDate() + days)
  return dateToYmdString(nativeDate)
}

export const WEEKDAYS = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']

export function formatCustomDate (ymd) {
  if (ymd?.endsWith('-00')) {
    const [y, m] = ymd.split('-')
    return `Monat ${numPad(Number(m), 2)}.${numPad(Number(y), 4)}`
  }
  const date = ymdStringToDate(ymd)
  if (!date) return '--, --.--.----'
  return `${WEEKDAYS[date.getDay()]}, ${numPad(date.getDate(), 2)}.${numPad(date.getMonth() + 1, 2)}.${numPad(date.getFullYear(), 4)}`
}

export function round2 (n) {
  return Math.round(n * 100) / 100
}

export function round1 (n) {
  return Math.round(n * 10) / 10
}

export function comma (n, allowString = false) {
  if (typeof n === 'string' && !allowString) return n
  return (n || 0).toString().replace('.', ',')
}

export function round2Comma (n) {
  if (typeof n === 'string') return n
  return comma(round2(n))
}

export function round1Comma (n) {
  if (typeof n === 'string') return n
  return comma(round1(n))
}

export function round2CommaFixed (n) {
  if (typeof n === 'string') return n
  return comma(round2(n).toFixed(2), true)
}

export function round1CommaFixed (n) {
  if (typeof n === 'string') return n
  return comma(round1(n).toFixed(1), true)
}

export function scrollToInvalidField (formElement) {
  if (!formElement) return
  const invalidField = formElement.querySelector('.field.is-danger, tr.has-background-danger-light, .item.has-background-danger-light, .notification.is-danger')
  if (invalidField) {
    invalidField.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
    setTimeout(() => invalidField.querySelector('input, textarea, select')?.focus(), 25)
  }
}

export function getCsv (rows) {
  return rows.map(row => row.map(r => `"${String(r ?? '').replaceAll('"', '""')}"`).join(';')).join('\r\n')
}

export async function download (data, mimeType, filename) {
  const wait = () => new Promise(resolve => setTimeout(resolve, 0))
  const blob = new window.Blob([data], { type: 'text/csv;charset=utf-8' })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.download = filename
  document.body.appendChild(link)
  await wait()
  link.click()
  await wait()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

export async function downloadCsv (rows, filename) {
  await download('\ufeff' + getCsv(rows), 'text/csv;charset=utf-8', filename)
}
