<script>
  import { onMount, onDestroy, tick } from 'svelte'
  import { appInfo } from '../lib/appInfo'
  import { Icon, Field, Input, Button } from 'svelma'
  import { apiCall } from '../lib/api'
  import viewStack, { open } from '../stores/viewStack'
  import ViewSearch from '../views/ViewSearch.svelte'
  import ViewBarcodeScanner from '../views/ViewBarcodeScanner.svelte'

  let navbarMenu
  let hamburgerIsActive = false
  let searchQuery = ''

  onMount(() => {
    document.documentElement.classList.add('has-navbar-fixed-top')
    navbarMenu.addEventListener('click', e => {
      if (e.target.closest('form')) return

      hamburgerIsActive = false

      // Make sure the dropdowns won't just stay open after clicking an element on them
      // https://github.com/jgthms/bulma/issues/2514
      const dropdown = e.target.closest('.navbar-dropdown')
      if (dropdown) {
        const dropdownOuter = e.target.closest('.has-dropdown')
        dropdownOuter.classList.remove('is-hoverable')
        dropdown.style.display = 'none'
        setTimeout(() => {
          dropdownOuter.blur()
          dropdownOuter.classList.add('is-hoverable')
          dropdown.style.display = ''
        }, 150)
      }
    })
  })
  onDestroy(() => {
    document.documentElement.classList.remove('has-navbar-fixed-top')
  })

  function toggleMenu () {
    hamburgerIsActive = !hamburgerIsActive
  }

  async function logout () {
    await apiCall('POST', '/logout')
    location.reload()
  }

  async function search () {
    if (searchQuery?.trim()) {
      viewStack.closeUntil(null, 1)
      hamburgerIsActive = false
      await tick()
      viewStack.push(ViewSearch, { searchQuery })
    }
  }

  async function scanForBarcode () {
    open(ViewBarcodeScanner, {
      onScan: code => {
        searchQuery = code
        search()
      }
    })
  }
</script>

<style lang="scss">
  .brand-title {
    font-size: 1.5rem;
  }

  @media (min-width: 481px) {
    .close-all {
      display: none;
    }
  }

  .navbar-start, form, form :global(.field), form :global(.control:first-child) {
    flex: 1;
  }
</style>

<nav class="navbar is-fixed-top has-shadow" role="navigation" aria-label="main navigation">
  <div class="container">
    <div class="navbar-brand">
      <div class="navbar-item brand-title">Rettenbacher ERP</div>

      <a role="button" class="navbar-burger burger" class:is-active={hamburgerIsActive} aria-label="menu" aria-expanded="false" data-target="navbarMenu" href={undefined} on:click={toggleMenu}>
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>

    <div bind:this={navbarMenu} id="navbarMenu" class="navbar-menu" class:is-active={hamburgerIsActive}>
      <div class="navbar-start">
        <a href={undefined} class="navbar-item close-all" on:click={() => viewStack.closeUntil(null, 1)}>Hauptmenü</a>

        <form class="navbar-item" on:submit|preventDefault={search}>
          <Field>
            <Input icon="search" type="search" bind:value={searchQuery} />
            <div class="control"><Button nativeType="button" on:click={scanForBarcode} title="QR-/Barcode scannen"><Icon icon="qrcode" /></Button></div>
            <div class="control"><Button nativeType="submit" disabled={!searchQuery?.trim()}>Suchen</Button></div>
          </Field>
        </form>
      </div>

      <div class="navbar-end">
        {#if appInfo.user}
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link" href={undefined}>
              <Icon icon="user" />&nbsp;{appInfo.user.fullName}
            </a>

            <div class="navbar-dropdown">
              <a href={undefined} class="navbar-item" on:click={logout}>Abmelden</a>
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</nav>
