<script>
  export let closeSelf
  export let onSave = () => {}
  export let create = false
  export let id = null
  export let parentId = null
  export let title

  import ResourceForm from '../components/ResourceForm.svelte'
  import { apiCall } from '../lib/api'
  import { applyRequired } from '../lib/validation'
  import FormField from '../components/FormField.svelte'
  import { appInfo } from '../lib/appInfo'
  import SwitchField from '../components/SwitchField.svelte'
  import RadioGroupField from '../components/RadioGroupField.svelte'
  import MultiSelectField from '../components/MultiSelectField.svelte'
  import { Dialog } from 'svelma'

  let record
  let fieldErrors = {}

  let customerName = '...'
  let propertyList = []
</script>

<ResourceForm
  {closeSelf} {onSave} {create} {id} {parentId} {title}
  getTitle={record => (record.fullName && record.username) ? `${record.fullName} (${record.username})` : undefined}
  parentIdField="customer"
  initRecord={async parentId => ({
    isCustomer: !!parentId,
    customer: parentId,
    limitProperties: false,
    properties: []
  })}
  handleLoad={async (id, record, create, parentId) => {
    try {
      if (record.customer) {
        const customer = await apiCall('GET', `/customers/${record.customer}`, { select: 'company,customerNo' })
        customerName = `${customer.company} (#${customer.customerNo})`
      } else {
        customerName = null
      }
    } catch (e) {
      console.error(`Failed to load name for ${record.customer}`, e)
      customerName = record.customer
    }

    if (record.isCustomer) {
      record.limitProperties = !!record.limitProperties
      if (!record.properties) record.properties = []

      try {
        if (record.customer) {
          const properties = await apiCall('GET', '/properties', { order: 'name', select: 'name', filter: JSON.stringify({ customer: record.customer }) })
          propertyList = properties.map(p => [p.id, p.name])
        } else {
          propertyList = []
        }
      } catch (e) {
        console.error(`Failed to load property list for ${record.customer}`, e)
        propertyList = (record.properties ?? []).map(id => [id, id])
      }
    }
  }}
  validateRecord={async record => {
    const fieldErrors = {}

    applyRequired(record, fieldErrors, [
      'username',
      'fullName'
    ])

    if (create && !record.password) fieldErrors.password = 'Passwort muss für neuen Benutzer angegeben werden'

    let cancel
    if (!Object.keys(fieldErrors).length && record.isCustomer && record.limitProperties && !record.properties.length) {
      cancel = !await Dialog.confirm({
        message: 'Es wurde eingeschränkter Liegenschaftenzugriff gesetzt, aber keine Liegenschaften gewählt - der Benutzer hat somit momentan auf gar keine Ressourcen Zugriff! Trotzdem speichern?',
        icon: 'question-circle',
        confirmText: 'Ja',
        cancelText: 'Nein',
        type: 'is-warning'
      })
    }

    return { record, fieldErrors, cancel }
  }}
  resourcePath="/users"
  resourceName="Benutzer"
  resourceGender="m"
  readonly={!appInfo.user.admin}
  hideId={appInfo.user.isCustomer}
  bind:record
  bind:fieldErrors
>
  <div class="columns">
    <FormField label="Benutzername" icon="user" col=6 inputClass="autofocus" bind:value={record.username} error={fieldErrors.username} />
    <FormField label="Neues Passwort" autocomplete="new-password" type="password" icon="star-of-life" passwordReveal col=6 bind:value={record.password} error={fieldErrors.password} />
  </div>

  <div class="columns">
    <FormField label="Voller Name" type="text" icon="id-badge" col=6 bind:value={record.fullName} error={fieldErrors.fullName} />
    {#if record.isCustomer}
      <FormField label="Kunde" icon="industry" col=4 value={customerName} readonly />
    {:else}
      <SwitchField label="Administator?" col=4 bind:value={record.admin} error={fieldErrors.admin} disabled={id === appInfo.user.id} />
    {/if}
    <SwitchField label="Zugang gesperrt?" col=2 bind:value={record.suspended} error={fieldErrors.suspended} disabled={id === appInfo.user.id} />
  </div>

  {#if record.isCustomer}
    <div class="columns">
      <RadioGroupField label="Zugriff auf Liegenschaften" options={[[false, 'Alle Liegenschaften des Kunden'], [true, 'Nur bestimmte Liegenschaften']]} col=6 bind:value={record.limitProperties} error={fieldErrors.limitProperties} />
      {#if record.limitProperties}
        <MultiSelectField label="Gewählte Liegenschaften" icon="building" col=6 bind:value={record.properties} error={fieldErrors.properties} options={propertyList} selectorTitle="Liegenschaftsberechtigung hinzufügen" />
      {/if}
    </div>
  {/if}
</ResourceForm>
