<script>
  export let url
  export let closeSelf

  import Modal from '../components/Modal.svelte'
</script>

<style lang="scss">
  figure {
    margin: auto;

    width: 800px;
    max-width: calc(90vw - 50px);
  }

  img {
    object-fit: contain;
  }
</style>

<Modal title="Bild-Ansicht" closeable on:close={closeSelf}>
  <figure class="image is-4by3">
    <a href={url} target="_blank">
      <img src={url} alt="Bild" />
    </a>
  </figure>
</Modal>
