<script>
  export let closeSelf
  export let onSave = () => {}
  export let create = false
  export let id = null
  export let parentId = null
  export let auditId = null
  export let title

  import ResourceForm from '../components/ResourceForm.svelte'
  import { apiCall } from '../lib/api'
  import { appInfo } from '../lib/appInfo'
  import { applyRequired, cleanErrorObject, trimFields } from '../lib/validation'
  import { Button, Field, Icon, Collapse, Toast, Dialog } from 'svelma'
  import FormField from '../components/FormField.svelte'
  import FileField from '../components/FileField.svelte'
  import SwitchField from '../components/SwitchField.svelte'
  import SelectField from '../components/SelectField.svelte'
  import RadioGroupField from '../components/RadioGroupField.svelte'
  import CustomDateField from '../components/CustomDateField.svelte'
  import DurationField from '../components/DurationField.svelte'
  import Dropdown from '../components/Dropdown.svelte'
  import { dateToYmdString, escape, formatCustomDate, numPad } from '../lib/utils'
  import { getActualLabel, getAllGroups } from '../lib/auditQuestions'
  import { open } from '../stores/viewStack'
  import ViewAuditObjectForm from './ViewAuditObjectForm.svelte'
  import { checkAutoPublish } from '../lib/autoPublish'

  let record
  let fieldErrors = {}
  let propertyName = '...'
  let customerName = '...'
  let auditObjectName = '...'
  let auditName = '...'

  $: if (record && record.type !== 'civil') {
    record.overallState = ['red', 'yellow', 'green'].find(color => Object.values(record.results).some(r => r.state === color)) ?? 'grey'
  }

  let currentAuditQuestions = {}

  let questionList = []
  $: if (record && record.type !== 'civil') {
    questionList = record?.auditQuestions ? getAllGroups(record.auditQuestions).map((g, i) => g.questions.map((q, j) => [q, `${Number(i) + 1}.${Number(j) + 1}`])).flat() : []
    for (const [q] of questionList) {
      if (!record.results[q]) record.results[q] = {}
    }
  }

  let updateAuditObjectDataInProgress = false
  let auditQuestionsOpen = !!create
  let updateAuditQuestionsInProgress = false
  let copyAuditObjectDataOnSave = false

  let users = []

  async function updateAuditQuestions () {
    updateAuditQuestionsInProgress = true
    try {
      currentAuditQuestions = await apiCall('GET', `/settings/auditQuestions/${record.auditObjectData.type}`)
      record.auditQuestions = JSON.parse(JSON.stringify(currentAuditQuestions))

      for (const q of Object.values(record.auditQuestions.questions)) {
        delete q.description
        delete q.yellowPresets
        delete q.redPresets
      }

      Toast.create({ message: 'Prüfkriterien wurden aktualisiert.', type: 'is-info' })
    } catch (e) {
      console.error(e)
      Dialog.alert({ message: escape(e.serverErrorMessage || `Beim Laden der aktuellen Kriterien ist ein Problem aufgetreten!\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
    } finally {
      updateAuditQuestionsInProgress = false
    }
  }

  async function updateAuditObjectData () {
    updateAuditObjectDataInProgress = true
    try {
      if (!record.auditObject) throw new Error('No audit object set')
      record.auditObjectData = await apiCall('GET', `/auditObjects/${record.auditObject}`, { select: '-property' })

      Toast.create({ message: 'Prüfkörper-Daten wurden aktualisiert.', type: 'is-info' })
    } catch (e) {
      console.error(e)
      Dialog.alert({ message: escape(e.serverErrorMessage || `Beim Laden der aktuellen Prüfkörper-Daten ist ein Problem aufgetreten!\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
    } finally {
      updateAuditObjectDataInProgress = false
    }
  }

  function editAuditObjectData () {
    open(ViewAuditObjectForm, {
      embeddedRecord: record.auditObjectData,
      onSave: embeddedRecord => {
        record.auditObjectData = embeddedRecord
      }
    }, true)
  }
</script>

<style lang="scss">
  :global(.state-picker label.radio) {
    padding: 0 3px;
    border-radius: 5px;

    &:nth-child(1) {
      background: $success;
      color: white;
    }

    &:nth-child(2) {
      background: $warning;
    }

    &:nth-child(3) {
      background: $danger;
      color: white;
    }

    &:nth-child(4) {
      background: $grey;
      color: white;
    }
  }

  :global(.state-picker label.radio.has-cursor-not-allowed) {
    margin-left: 0;
    margin-right: -0.415em;

    :global(input) {
      display: none;
    }

    :global(span) {
      display: none;
    }

    :global(input:checked + span) {
      display: inherit;
    }
  }
</style>

<ResourceForm
  {closeSelf} {onSave} {create} {id} {parentId} {title}
  getTitle={record => (record?.auditObjectData && record?.date) ? `${record?.type === 'civil' ? 'Ziviltechniker-' : ''}Prüfprotokoll ${record?.auditObjectData?.publicId} vom ${formatCustomDate(record?.date)}` : undefined}
  parentIdField="auditObject"
  initRecord={async parentId => {
    const { type } = await apiCall('GET', `/audits/${auditId}`, { select: 'type' })

    const record = {
      type,
      user: type !== 'civil' ? appInfo.user.id : null,
      auditObject: parentId,
      audit: auditId,
      date: dateToYmdString(new Date()),
      auditObjectData: await apiCall('GET', `/auditObjects/${parentId}`),
      results: {},
      badge: false,
      logged: false
    }

    if (type !== 'civil') {
      record.auditQuestions = await apiCall('GET', `/settings/auditQuestions/${record.auditObjectData.type}`)

      for (const q of Object.values(record.auditQuestions.questions)) {
        delete q.description
        delete q.yellowPresets
        delete q.redPresets
      }
    }

    return record
  }}
  handleLoad={async (id, record, create, parentId) => {
    try {
      users = await apiCall('GET', '/users', { order: 'fullName', filter: JSON.stringify({ isCustomer: { $ne: true } }), select: 'fullName' })
    } catch (e) {
      console.error('Failed to load user list', e)
      users = []
    }

    currentAuditQuestions = await apiCall('GET', `/settings/auditQuestions/${record.auditObjectData.type}`)

    if (record.user && !users.find(u => u.id === record.user)) {
      users.push({ id: record.user, fullName: record.user })
    }

    if (record.verifiedBy && !users.find(u => u.id === record.verifiedBy)) {
      users.push({ id: record.verifiedBy, fullName: record.verifiedBy })
    }

    try {
      if (record.audit) {
        const audit = await apiCall('GET', `/audits/${record.audit}`, {
          select: 'startDate,endDate,month,year,property',
          populate: JSON.stringify([{
            path: 'property',
            select: 'name,customer',
            populate: {
              path: 'customer',
              select: 'company,customerNo'
            }
          }])
        })
        auditName = (formatCustomDate(audit.startDate) + (audit.endDate && audit.endDate !== audit.startDate ? ` - ${formatCustomDate(audit.endDate)}` : '') + (!audit.startDate?.startsWith(`${numPad(audit.year, 4)}-${numPad(audit.month, 2)}`) ? ` (${numPad(audit.month, 2)}.${numPad(audit.year, 4)})` : ''))
        propertyName = audit.property?.name
        customerName = audit.property?.customer ? `${audit.property.customer.company} (#${audit.property.customer.customerNo})` : null
      } else {
        auditName = null
        propertyName = null
        customerName = null
      }
    } catch (e) {
      console.error(`Failed to load info for ${record.audit}`, e)
      auditName = record.audit
      propertyName = '?'
      customerName = '?'
    }

    try {
      if (record.auditObject) {
        const auditObject = await apiCall('GET', `/auditObjects/${record.auditObject}`, {
          select: 'publicId,manufacturer,serialNo'
        })
        auditObjectName = `${auditObject.publicId}: ${auditObject.manufacturer ?? ''} ${auditObject.serialNo ?? ''} ${!auditObject?.manufacturer && !auditObject?.serialNo ? 'Unbekannt' : ''}`
      } else {
        auditObjectName = null
      }
    } catch (e) {
      console.error(`Failed to load name for ${record.auditObject}`, e)
      auditObjectName = record.auditObject
    }
  }}
  handleSave={async record => {
    if (copyAuditObjectDataOnSave) {
      try {
        if (!record.auditObject) throw new Error('No audit object set')
        delete record.auditObjectData.property
        await apiCall('PATCH', `/auditObjects/${record.auditObject}`, record.auditObjectData)

        Toast.create({ message: 'Prüfkörper wurde aktualisiert.', type: 'is-success' })
      } catch (e) {
        Dialog.alert({ message: escape(e.serverErrorMessage || `Das Prüfprotokoll wurde gespeichert aber beim Aktualisieren des zugehörigen Prüfkörpers ist ein Fehler aufgetreten!\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
        console.error(e)
      }
    }

    await checkAutoPublish(record.audit)
  }}
  validateRecord={async record => {
    const fieldErrors = { results: {} }

    applyRequired(record, fieldErrors, [
      'date',
      'badge',
      'logged',
      'overallState',
      ...record.type === 'civil' ? ['externalPdfUrl'] : ['overviewPhotoUrl', 'auditQuestions']
    ])

    trimFields(record, [
      'generalNotes'
    ])

    if (record.type !== 'civil') {
      for (const [questionId, question] of Object.entries(record.results)) {
        fieldErrors.results[questionId] = {}
        applyRequired(question, fieldErrors.results[questionId], [
          'state',
          ...(question.state === 'red' && !(record.auditQuestions.questions[questionId]?.photoOptional ?? currentAuditQuestions.questions[questionId]?.photoOptional)) ? ['photoUrl'] : [],
          ...question.state === 'red' || question.state === 'yellow' ? ['description'] : []
        ])
      }
    }

    cleanErrorObject(fieldErrors)

    if (fieldErrors.results) auditQuestionsOpen = true

    return { record, fieldErrors }
  }}
  resourcePath="/auditLogs"
  resourceName="Prüfprotokoll"
  resourceGender="n"
  readonly={!appInfo.user.admin}
  hideId={appInfo.user.isCustomer}
  bind:record
  bind:fieldErrors
>
  <div class="columns">
    <CustomDateField label="Datum" icon="calendar-day" col=3 bind:value={record.date} error={fieldErrors.date} />
    <FormField label="Kunde" icon="industry" col=3 value={customerName} readonly />
    <FormField label="Liegenschaft" icon="building" col=3 value={propertyName} readonly />
    <FormField label="Prüfkörper" icon="dice-d6" col=3 value={auditObjectName} readonly />
  </div>

  <div class="columns">
    <FormField label="Prüftermin" icon="calendar-check" col=3 value={auditName} readonly />
    {#if record.type === 'civil'}
      <FormField label="Mitarbeiter" icon="user-ninja" col=3 value="(Ziviltechniker)" readonly />
    {:else}
      <SelectField label="Mitarbeiter" icon="user" col=3 bind:value={record.user} error={fieldErrors.user} options={[[null, '(nicht zugewiesen)'], ...users.map(u => [u.id, u.fullName])]} />
    {/if}
    <SelectField label="Kontrolle" icon="user-check" col=3 bind:value={record.verifiedBy} error={fieldErrors.verifiedBy} options={[[null, '(nicht kontrolliert)'], ...users.map(u => [u.id, u.fullName])]} />
    <Field label="Prüfkörper-Daten{copyAuditObjectDataOnSave ? ' (WIRD GESPEICHERT)' : ''}" class="column is-3" forceClose={updateAuditObjectDataInProgress}>
      <Dropdown class="is-right">
        <Button slot="trigger" iconRight="angle-down" type="is-default" outlined class="is-fullwidth" loading={updateAuditObjectDataInProgress}>Aktionen</Button>

        <a href={undefined} class="dropdown-item" on:click={editAuditObjectData}><Icon icon="pen" /> Lokale Daten ändern</a>
        <hr class="dropdown-divider" />
        <a href={undefined} class="dropdown-item" on:click={updateAuditObjectData}><Icon icon="sync" /> Aktuelle Daten aus Prüfkörper laden</a>
        <a href={undefined} class="dropdown-item" class:is-active={copyAuditObjectDataOnSave} on:click={() => (copyAuditObjectDataOnSave = !copyAuditObjectDataOnSave)}><Icon icon="save" /> Daten aus Prüfprotokoll in Prüfkörper übernehmen (beim Speichern)</a>
      </Dropdown>
    </Field>
  </div>

  <div class="columns">
    <FormField label="Allgemeine Hinweise" type="textarea" col=12 bind:value={record.generalNotes} error={fieldErrors.generalNotes} />
  </div>

  {#if record.type === 'regular'}
    <div class="columns">
      <FileField label="Überblick-Foto" icon="image" col=12 bind:value={record.overviewPhotoUrl} error={fieldErrors.overviewPhotoUrl} />
    </div>

    <div class="columns">
      <SwitchField label="Prüfplakette erteilt?" col=3 bind:value={record.badge} error={fieldErrors.badge} />
      <SwitchField label="Prüfbucheintrag erfolgt?" col=3 bind:value={record.logged} error={fieldErrors.logged} />
      <RadioGroupField label="Gesamtergebnis" options={[['green', 'Grün'], ['yellow', 'Gelb'], ['red', 'Rot'], ['grey', 'Grau']]} col=3 bind:value={record.overallState} error={fieldErrors.overallState} class="state-picker" readonly />
      <DurationField label="Arbeitsdauer" icon="stopwatch" col=3 bind:value={record.auditTimeInSeconds} error={fieldErrors.auditTimeInSeconds} />
    </div>

    <div class="has-background-grey-lighter p-2 mb-3">
      <Collapse animation={() => /* No animation */ {}} bind:open={auditQuestionsOpen}>
        <div class="columns" slot="trigger">
          <div class="column is-12">
            <a href={undefined} class="level is-mobile">
              <div class="level-left large-icon"><strong>Prüfkriterien</strong></div>
              <div class="level-right"><Icon icon={auditQuestionsOpen ? 'chevron-up' : 'chevron-down'} /></div>
            </a>
          </div>
        </div>

        {#each questionList as [questionId, fullNumber] (questionId)}
          <div class="columns">
            <RadioGroupField label="{fullNumber}: {getActualLabel(record.auditQuestions.questions[questionId]?.label)}" options={[['green', 'Grün'], ['yellow', 'Gelb'], ['red', 'Rot'], ['grey', 'Grau']]} col=4 bind:value={record.results[questionId].state} error={fieldErrors.results?.[questionId]?.state} class="state-picker" />
            {#if record.results[questionId].state === 'yellow' || record.results[questionId].state === 'red'}
              <FormField label="Mangelbeschreibung" col=4 bind:value={record.results[questionId].description} error={fieldErrors.results?.[questionId]?.description} presets={currentAuditQuestions.questions[questionId]?.[`${record.results[questionId].state}Presets`] ?? []} />
            {/if}
            {#if record.results[questionId].state === 'red' || record.results[questionId].state === 'yellow'}
              <FileField label="Fotodokumentation {((record.auditQuestions.questions[questionId]?.photoOptional ?? currentAuditQuestions.questions[questionId]?.photoOptional) || record.results[questionId].state === 'yellow') ? '(optional)' : ''}" icon="image" col=4 bind:value={record.results[questionId].photoUrl} error={fieldErrors.results?.[questionId]?.photoUrl} />
            {/if}
          </div>
        {/each}

        <div class="columns">
          <div class="column is-6"></div>
          <div class="column is-6">
            <Button class="is-fullwidth is-wrappable" iconLeft="sync" loading={updateAuditQuestionsInProgress} on:click={updateAuditQuestions}>Aktuelle Kriterien aus Einstellungen laden</Button>
          </div>
        </div>
      </Collapse>
    </div>
  {:else if record.type === 'civil'}
    <div class="columns">
      <RadioGroupField label="Gesamtergebnis" options={[['green', 'Grün'], ['yellow', 'Gelb'], ['red', 'Rot'], ['grey', 'Grau']]} col=6 bind:value={record.overallState} error={fieldErrors.overallState} class="state-picker" />
      <FileField label="Protokolldokument (PDF)" icon="file-pdf" col=6 bind:value={record.externalPdfUrl} error={fieldErrors.externalPdfUrl} linkPreview={true} accept=".pdf" />
    </div>
  {/if}

  <div class="columns">
    <FormField label="Interne Notizen zu diesem Protokoll" type="textarea" col=12 bind:value={record.notes} error={fieldErrors.notes} />
  </div>
</ResourceForm>
