<script>
  export let label = null
  export let icon = null
  export let value = null
  export let error = null
  export let hint = null
  export let col = null
  export let readonly = false
  export let disabled = false
  export let omitContainer = false

  import { Field, Input } from 'svelma'
  import { createEventDispatcher } from 'svelte'
  import TimeFormat from 'hh-mm-ss'

  const dispatch = createEventDispatcher()

  let internalValue
  $: {
    if (value !== parse(internalValue)) {
      const newValue = convert(value)
      internalValue = newValue
    }
  }

  function parse (str) {
    let seconds = null
    try {
      if (str.match(/^\d:/)) str = '0' + str
      seconds = TimeFormat.toS(str)
      if (isNaN(seconds)) {
        seconds = null
      }
    } catch (e) {}
    return seconds
  }

  function convert (seconds) {
    return value != null ? TimeFormat.fromS(seconds) : ''
  }

  function handleChange (event) {
    // Not sure why but we sometimes see event.target and sometimes event.detail.target
    internalValue = ((event.target || event.detail.target).value)

    const newValue = parse(internalValue) ?? null
    if (value !== newValue) value = newValue

    if (event.type === 'change') internalValue = convert(value)

    dispatch(event.type, event.detail ?? event)
  }
</script>

{#if omitContainer}
  <!-- Not using bind:value here because this caused resetting the cursor inside the numbers! -->
  <Input type="text" value={internalValue ?? ''} {icon} placeholder="(hh:)mm:ss" class={error ? 'is-danger' : ''} on:change={handleChange} on:input={handleChange} {...readonly ? { readonly: true } : {}} {...disabled ? { disabled: true } : {}} />
{:else}
  {#key error}
    <Field {label} type={error ? 'is-danger' : ''} message={error || hint} class="{$$props.class ?? ''} {col ? `column is-${col}` : ''}">
      <!-- Not using bind:value here because this caused resetting the cursor inside the numbers! -->
      <Input type="text" value={internalValue ?? ''} {icon} placeholder="(hh:)mm:ss" class={error ? 'is-danger' : ''} on:change={handleChange} on:input={handleChange} {...readonly ? { readonly: true } : {}} {...disabled ? { disabled: true } : {}} />
    </Field>
  {/key}
{/if}
