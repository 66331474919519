<script>
  import { Notification } from 'svelma'
  import viewStack from './stores/viewStack'
  import ViewMain from './views/ViewMain.svelte'
  import Loader from './components/Loader.svelte'
  import ErrorDisplay from './components/ErrorDisplay.svelte'
  import { escape } from './lib/utils'
  import { initAppInfo, appInfo } from './lib/appInfo'
  import { frontendVersion } from './lib/env'
  import FatalError from './FatalError.svelte'
  import Nav from './components/Nav.svelte'
  import ViewLogin from './views/ViewLogin.svelte'
  import { Toast } from 'svelma'

  window.onerror = message => {
    if (message === 'ResizeObserver loop limit exceeded' || message === 'ResizeObserver loop completed with undelivered notifications.') return // Not sure where this error comes from
    Notification.create({ message: `<strong>Ein Fehler ist aufgetreten!</strong><br><span>${escape(message)}</span>`, type: 'is-danger', icon: true, duration: 15000 })
  }

  window.onunhandledrejection = ({ reason }) => {
    let fatal = false
    try {
      // Dirty way to detect that we crashed inside a template and therefore the whole app is now dead
      if (reason instanceof Error && reason.stack?.split('\n').find(r => r.trim().match(/^at (?:Object(?:\.\$\$self\.\$\$)?\.(update|create|destroy)|create_slot)/))) fatal = true
    } catch (e) {
      console.error(e)
      fatal = true
    }

    if (fatal && !window.ignoreFatalErrors) {
      if (window.fatalErrorView) { // Protect from fault loop
        alert(`EIN SCHWERER FEHLER IST AUFGETRETEN!\n\nDie Applikation muss neu geladen werden, ungespeicherte Änderungen (falls vorhanden) gehen dabei leider verloren.\n\nTechnische Information: ${reason}`)
        location.reload()
      } else {
        document.body.innerHTML = ''
        window.fatalErrorView = new FatalError({
          target: document.body,
          props: { error: reason instanceof Error ? reason : new Error(reason), frontendVersion, serverVersion: appInfo.serverVersion }
        })
      }
    } else {
      Notification.create({ message: `<strong>Ein Fehler ist aufgetreten!</strong><br><span>${escape(reason)}</span>`, type: 'is-danger', icon: true, duration: 15000 })
    }
  }

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      navigator.serviceWorker.register('/sw.js').then(function (registration) {
        // Registration was successful
        console.log('ServiceWorker registration successful with scope: ', registration.scope)
      }, function (err) {
        // registration failed :(
        console.log('ServiceWorker registration failed: ', err)
      })
    })
  }

  document.body.classList.add('app-ready')

  viewStack.push(ViewMain)

  async function load () {
    await initAppInfo()
  }

  let loadingPromise = load()

  // Support back button to close the topmost view (especially nice on mobile)
  // This works by normally always having two states in history, so we can react on the navigation from the second to the first state
  history.pushState({}, document.title)
  function onPopState () {
    if ($viewStack.length === 1) {
      // Nothing to close, actually go back this time
      history.back()
      // But in case this already was the end, we push another state (won't work
      // anyway if real navigation already happened)
      history.pushState({}, document.title)
    } else {
      // Close topmost view and rearm the "two states" situation
      // But, if there is a Svelma dialog open, don't do anything (it's not on the
      // view stack and pulling out the rug under its feet would cause a mess)
      if (document.querySelector('.modal.dialog.is-active:not(.custom)')) {
        Toast.create({ message: 'Bitte zuerst Dialogfenster schließen!', type: 'is-warning' })
      } else {
        viewStack.pop()
      }
      history.pushState({}, document.title)
    }
  }
</script>

<style lang="scss">
  main { // Dummy CSS to fix a weird bug that would remove the "section" part of the following rule...
    color: inherit;
  }

  section {
    width: 100%;
    height: 100%;
    overflow: hidden; // a) scrolling should be handled by the content, b) we don't want margin collapse here
  }

  main {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    position: relative;
    scroll-behavior: smooth;

    &::after {
      content: '';
      min-width: 24px;
      border-top: 1px solid transparent; // Otherwise it won't show up at all

      @media (max-width: 480px) {
        content: none;
        display: none;
      }
    }
  }

  .view {
    height: 100%;
  }

  @media (max-width: 480px) {
    main {
      padding-right: 0;
    }

    .view {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      pointer-events: none;

      &:last-child {
        pointer-events: all;
      }
    }
  }
</style>

<svelte:window on:popstate={onPopState} />

<section>
  {#await loadingPromise}
    <Loader cover />
  {:then}
    {#if appInfo.user}
      <Nav />

      <main id="view-stack">
        {#each $viewStack as { View, props, id } (id)}
          <div class="view">
            <svelte:component this={View} {...props} />
          </div>
        {:else}
          Kein Inhalt geladen.
        {/each}
      </main>
    {:else}
      <ViewLogin />
    {/if}
  {:catch error}
    <div class="container error-container">
      <ErrorDisplay title="Server-Verbindung fehlgeschlagen!" {error} />
      <small>F {frontendVersion} &bull; <a href={undefined} on:click={() => { loadingPromise = load() }}>Neu versuchen</a></small>
    </div>
  {/await}
</section>
