<script>
  export let topView
  export let openAndReplace

  import { apiCall, StatusCodeError } from '../lib/api'
  import { appInfo } from '../lib/appInfo'
  import { escape } from '../lib/utils'
  import Loader from '../components/Loader.svelte'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import Pane from '../components/Pane.svelte'
  import PaneRow from '../components/PaneRow.svelte'
  import ViewCustomerList from './ViewCustomerList.svelte'
  import ViewAuditSchedule from './ViewAuditSchedule.svelte'
  import PaneList from '../components/PaneList.svelte'
  import ViewUserList from './ViewUserList.svelte'
  import ViewSettings from './ViewSettings.svelte'
  import ViewTagPrint from './ViewTagPrint.svelte'
  import ViewCustomerDetails from './ViewCustomerDetails.svelte'
  import ViewBarcodeScanner from './ViewBarcodeScanner.svelte'
  import { Dialog, Button, Notification } from 'svelma'
  import ViewAuditObjectDetails from './ViewAuditObjectDetails.svelte'

  setInterval(() => {
    if (topView) checkIfInfoChanged()
  }, 60000)

  async function checkIfInfoChanged () {
    try {
      const newInfo = await apiCall('GET', '/info')
      if (JSON.stringify({ ...appInfo, offline: undefined }) !== JSON.stringify({ ...newInfo, initialized: true, offline: undefined })) {
        console.log('Info changed! Reloading...')
        location.reload()
      } else if (appInfo.offline !== newInfo.offline) {
        // This is the one thing that may be updated here
        appInfo.offline = newInfo.offline
      }
    } catch (e) {
      console.error(e)
    }
  }

  let customer

  async function load () {
    await checkIfInfoChanged()

    if (appInfo.user.isCustomer) {
      if (appInfo.offline) {
        customer = null
      } else {
        customer = await apiCall('GET', `/customers/${appInfo.user.customer}`, { select: 'logoUrl' })
      }
    }
  }

  let selected
  let loading = false

  let networkError = false
  let searchId = null

  function cancelScanSearch () {
    searchId = null
    networkError = null
    loading = null
  }

  async function onScan (code) {
    const thisSearchId = searchId = Math.floor(Math.random() * 1e9)
    loading = true

    try {
      let res
      do {
        try {
          res = await apiCall('GET', '/auditObjects', {
            filter: JSON.stringify({
              barcode: code
            })
          })

          networkError = false
        } catch (e) {
          if (searchId !== thisSearchId) return // Cancelled

          networkError = !(e instanceof StatusCodeError)

          if (networkError) {
            console.warn('Barcode search request failed', e)
            await new Promise(resolve => setTimeout(resolve, 5000))
          } else {
            throw e
          }
        }
      } while (networkError && searchId === thisSearchId) // eslint-disable-line no-unmodified-loop-condition

      if (searchId !== thisSearchId) return // Cancelled

      if (!res.length) {
        Dialog.alert({ message: `Zu dem Barcode "${escape(code)}" wurde kein Prüfkörper gefunden!`, type: 'is-warning', icon: 'exclamation-circle' })
      } else {
        openAndReplace(ViewAuditObjectDetails, { id: res[0].id, isolated: true })
      }
    } catch (e) {
      console.error(e)
      Dialog.alert({ message: escape(e.serverErrorMessage || `Der Barcode konnte nicht abgefragt werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
    } finally {
      loading = false
    }
  }
</script>

<style lang="scss">
  .big-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    :global(button:not(.is-outlined)) {
      font-size: 200%;
      width: 80%;
      margin: 30px;
      height: auto;
      white-space: normal;
    }

    :global(.notification) {
      margin: 30px;
      width: 80%;
    }
  }

  .customer-logo {
    margin: 15px;
    max-width: 75%;
    max-height: 20%;
    object-fit: contain;
  }
</style>

{#if appInfo.user.isCustomer}
  <Pane width={(!topView || selected) ? 300 : '100vw'}>
    {#await load()}
      <Loader cover />
    {:then}
      {#if !topView || selected}
        <PaneList bind:selected let:select {topView}>
          <PaneRow {selected} {select} id="properties" icon="building" on:select={() => openAndReplace(ViewCustomerDetails, { id: appInfo.user.customer })}>Liegen&shy;schaften und Prüf&shy;ergebnisse</PaneRow>
          <PaneRow {selected} {select} id="scanBarcode" icon="qrcode" on:select={() => openAndReplace(ViewBarcodeScanner, { onScan })}>QR-/Barcode scannen</PaneRow>
        </PaneList>
      {:else if loading}
        <Loader cover />

        {#if networkError}
          <Notification type="is-warning" icon="wifi" showClose={false}>
            Die Suche konnte aktuell nicht durchgeführt werden, weil die Verbindung zum Server nicht möglich war. Die Suche wird automatisch wiederholt.<br><br>Bitte <strong>stellen Sie die Internetverbindung wieder her</strong>, um die Ergebnisse abrufen zu können.
            <div class="buttons is-right">
              <Button type="is-warning" inverted outlined on:click={() => cancelScanSearch()}>Abbrechen</Button>
            </div>
          </Notification>
        {/if}
      {:else}
        <div class="big-buttons">
          {#if appInfo.offline}
            <Notification type="is-warning" showClose={false}>
              Keine Verbindung! Nur Offline-Barcode-Erfassung möglich.
              <div class="buttons is-right">
                <Button type="is-warning" inverted outlined on:click={() => location.reload()}>Neu laden</Button>
              </div>
            </Notification>
          {/if}
          {#if customer?.logoUrl}
            <img src={customer.logoUrl} class="customer-logo" alt="Logo" />
          {/if}
          {#if !appInfo.offline}
            <Button iconLeft="building" type="is-success" on:click={() => (selected = 'properties')}>Liegenschaften und Prüfergebnisse</Button>
          {/if}
          <Button iconLeft="qrcode" type="is-info" on:click={() => (selected = 'scanBarcode')}>QR-/Barcode scannen</Button>
        </div>
      {/if}
    {:catch error}
      <ErrorDisplay {error} />
    {/await}
  </Pane>
{:else}
  <Pane width={200}>
    {#await load()}
      <Loader cover />
    {:then}
      <PaneList bind:selected let:select {topView}>
        <PaneRow {selected} {select} id="database" icon="book" on:select={() => openAndReplace(ViewCustomerList)}>Datenbank</PaneRow>
        <PaneRow {selected} {select} id="maintenancePlan" icon="calendar-alt" on:select={() => openAndReplace(ViewAuditSchedule)}>Wartungsplan</PaneRow>
        <PaneRow {selected} {select} id="labels" icon="tags" on:select={() => openAndReplace(ViewTagPrint)}>Etiketten</PaneRow>
        {#if appInfo.user.admin}
          <PaneRow {selected} {select} id="users" icon="users" on:select={() => openAndReplace(ViewUserList)}>Benutzer</PaneRow>
          <PaneRow {selected} {select} id="settings" icon="wrench" on:select={() => openAndReplace(ViewSettings)}>Einstellungen</PaneRow>
        {/if}
      </PaneList>
    {:catch error}
      <ErrorDisplay {error} />
    {/await}
  </Pane>
{/if}
