<script>
  export let topView
  export let closeSelf
  export let closeHigher
  export let openAndReplace

  export let id
  export let title
  export let onSave
  export let isolated = false
  export let openedFrom = null

  import ResourceView from '../components/ResourceView.svelte'
  import { escape, formatCustomDate, numPad } from '../lib/utils'
  import ViewAuditLogForm from './ViewAuditLogForm.svelte'
  import ViewCustomerDetails from './ViewCustomerDetails.svelte'
  import ViewPropertyDetails from './ViewPropertyDetails.svelte'
  import ViewAuditDetails from './ViewAuditDetails.svelte'
  import ViewAuditObjectDetails from './ViewAuditObjectDetails.svelte'
  import ViewServiceRequestForm from './ViewServiceRequestForm.svelte'
  import { tick } from 'svelte'
  import Detail from '../components/Detail.svelte'
  import { getActualLabel, getAllGroups } from '../lib/auditQuestions'
  import { Icon, Dialog, Toast, Button } from 'svelma'
  import { open } from '../stores/viewStack'
  import ViewImagePreview from './ViewImagePreview.svelte'
  import { appInfo } from '../lib/appInfo'
  import { apiCall } from '../lib/api'
  import { apiUrl } from '../lib/env'
  import { checkAutoPublish } from '../lib/autoPublish'
  import TimeFormat from 'hh-mm-ss'

  let selected
  let silentReload
  let actionInProgress

  async function openCustomer (customer) {
    if (!customer) throw new Error('No customer set')
    selected = null
    await tick()
    openAndReplace(ViewCustomerDetails, { id: customer.id, onSave: silentReload, isolated: true })
  }

  async function openProperty (property) {
    if (!property) throw new Error('No property set')
    selected = null
    await tick()
    openAndReplace(ViewPropertyDetails, { id: property.id, onSave: silentReload, isolated: true })
  }

  async function openAudit (audit) {
    if (!audit) throw new Error('No audit set')
    selected = null
    await tick()
    openAndReplace(ViewAuditDetails, { id: audit.id, onSave: silentReload, isolated: true })
  }

  async function openAuditObject (auditObject) {
    if (!auditObject) throw new Error('No auditObject object set')
    selected = null
    await tick()
    openAndReplace(ViewAuditObjectDetails, { id: auditObject.id, onSave: silentReload, isolated: true })
  }

  async function generateReport (mainRecord) {
    window.open(`${apiUrl}/auditLogs/${id}/report`)
  }

  async function markVerified (mainRecord) {
    actionInProgress = true
    try {
      await apiCall('PATCH', `/auditLogs/${id}`, { verifiedBy: appInfo.user.id })
      Toast.create({ message: 'Prüfprotokoll wurde als kontrolliert markiert.', type: 'is-success' })

      await checkAutoPublish(mainRecord.audit?.id)

      silentReload({ id })
    } catch (e) {
      console.error(e)
      Dialog.alert({ message: escape(e.serverErrorMessage || `Die Änderungen konnten nicht gespeichert werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
    } finally {
      actionInProgress = false
    }
  }
</script>

<style lang="scss">
  :global(.panel-block.is-unselectable) {
    &, &:hover {
      background: white;
    }

    cursor: default;
  }
</style>

<ResourceView
  {topView} {closeSelf} {closeHigher} {openAndReplace}
  {id} {title}
  icon="clipboard-list"
  defaultTitle="Prüfprotokoll-Details"
  {onSave}
  mainPopulate={JSON.stringify([
    ['user', 'fullName'],
    ['verifiedBy', 'fullName'],
    ['auditObject', 'publicId,manufacturer,serialNo'],
    {
      path: 'audit',
      populate: [{
        path: 'property',
        select: 'name,customer',
        populate: {
          path: 'customer',
          select: 'company,customerNo'
        }
      }]
    }
  ])}
  getTitle={record => `${record.auditObjectData?.publicId || 'Unbekannter Prüfkörper'} am ${formatCustomDate(record.date)}` + (record.type === 'civil' ? ' (Ziviltechniker)' : '')}
  handleLoad={async (id, mainRecord) => {
    const questionList = mainRecord?.auditQuestions ? getAllGroups(mainRecord.auditQuestions).map((g, i) => g.questions.map((q, j) => [q, `${Number(i) + 1}.${Number(j) + 1}`])).flat() : []
    const items = []
    for (const [questionId, fullNumber] of questionList) {
      if (mainRecord.results?.[questionId]) items.push({ id: questionId, label: `${fullNumber}: ${getActualLabel(mainRecord.auditQuestions.questions[questionId]?.label)}`, result: mainRecord.results?.[questionId] })
    }
    return { items }
  }}
  handleOpenDetails={record => {
    tick().then(() => {
      selected = null
    })
  }}
  resourcePath="/auditLogs"
  resourceName="Prüfprotokoll"
  ViewMainForm={ViewAuditLogForm}
  search={(searchQuery, searchRegex, record) =>
    record.label.match(searchRegex) ||
    record.result.description?.match(searchRegex) ||
    (record.result.state === 'red' && searchQuery.toLowerCase() === 'rot') ||
    (record.result.state === 'yellow' && searchQuery.toLowerCase() === 'gelb') ||
    (record.result.state === 'green' && searchQuery.toLowerCase() === 'grün') ||
    (record.result.state === 'grey' && searchQuery.toLowerCase() === 'grau')
  }
  allowCreate={false}
  allowEdit={!appInfo.user.isCustomer}
  allowDelete={!appInfo.user.isCustomer}
  readonly={!appInfo.user.admin}
  hideId={appInfo.user.isCustomer}
  rowClass='is-unselectable'
  let:record
  bind:silentReload
  bind:selected
  bind:actionInProgress
>
  <svelte:fragment slot="header" let:mainRecord>
    {#if isolated}
      <div class="panel-block">
        <Detail title="Kunde" icon="industry" ready={mainRecord}>
          <a href={undefined} on:click={() => openCustomer(mainRecord.audit?.property?.customer)}>{mainRecord?.audit?.property?.customer?.company} (#{mainRecord?.audit?.property?.customer?.customerNo})</a>
        </Detail>
      </div>

      <div class="panel-block">
        <Detail title="Liegenschaft" icon="building" ready={mainRecord}>
          <a href={undefined} on:click={() => openProperty(mainRecord.audit?.property)}>{mainRecord?.audit?.property?.name}</a>
        </Detail>
      </div>
    {/if}

    {#if isolated || openedFrom !== 'audit'}
      <div class="panel-block">
        <Detail title="Prüftermin" icon="calendar-check" ready={mainRecord}>
          <a href={undefined} on:click={() => openAudit(mainRecord.audit)}>
            {formatCustomDate(mainRecord?.audit?.startDate)}
            {#if mainRecord?.audit?.endDate && mainRecord?.audit?.endDate !== mainRecord?.audit?.startDate}
              {' - '}
              {formatCustomDate(mainRecord?.audit?.endDate)}
            {/if}
            {#if mainRecord && !mainRecord.audit.startDate?.startsWith(`${numPad(mainRecord.audit.year, 4)}-${numPad(mainRecord.audit.month, 2)}`)}
              ({numPad(mainRecord.audit.month, 2)}.{numPad(mainRecord.audit.year, 4)})
            {/if}
          </a>
        </Detail>
      </div>
    {/if}

    {#if isolated || openedFrom !== 'auditObject'}
      <div class="panel-block">
        <Detail title="Prüfkörper" icon="dice-d6" ready={mainRecord}>
          <a href={undefined} on:click={() => openAuditObject(mainRecord.auditObject)}>
            {mainRecord?.auditObject?.publicId}: {mainRecord?.auditObject?.manufacturer ?? ''} {mainRecord?.auditObject?.serialNo ?? ''} {!mainRecord?.auditObject?.manufacturer && !mainRecord?.auditObject?.serialNo ? 'Unbekannt' : ''}
          </a>
        </Detail>
      </div>
    {/if}

    <div class="panel-block">
      <Detail title="Datum" icon="calendar-day" ready={mainRecord}>
        {formatCustomDate(mainRecord?.date)}
      </Detail>

      {#if !appInfo.user.isCustomer}
        <Detail title="Arbeitsdauer" icon="stopwatch" onlyIf={mainRecord?.type !== 'civil'}>
          {#if mainRecord}
            {TimeFormat.fromS(mainRecord.auditTimeInSeconds ?? 0)}
          {:else}
            ...
          {/if}
        </Detail>
      {/if}
    </div>

    <div class="panel-block">
      <Detail title="Gesamtergebnis" icon="traffic-light" ready={mainRecord}>
        {#if mainRecord?.overallState === 'green'}
          <span class="has-text-success">Grün</span>
        {:else if mainRecord?.overallState === 'yellow'}
          <span class="has-text-warning">Gelb</span>
        {:else if mainRecord?.overallState === 'red'}
          <span class="has-text-danger">Rot</span>
        {:else}
          <span>k.A.</span>
        {/if}

        {#if mainRecord?.badge}
          <span title="Prüfplakette erteilt"><Icon icon="certificate" /></span>
        {/if}

        {#if mainRecord?.logged}
          <span title="Prüfbucheintrag erfolgt"><Icon icon="book" /></span>
        {/if}

        {#if mainRecord?.overviewPhotoUrl}
          <a href={undefined} on:click={() => open(ViewImagePreview, { url: mainRecord.overviewPhotoUrl }, true)} title="Überblick-Foto"><Icon icon="image" /></a>
        {/if}
      </Detail>
    </div>

    <div class="panel-block">
      <Detail title="Mitarbeiter" icon="user" ready={mainRecord} onlyIf={mainRecord?.type !== 'civil'}>
        {#if mainRecord?.user}
          {mainRecord.user.fullName}
        {:else}
          (nicht zugewiesen)
        {/if}
      </Detail>

      <Detail title="Mitarbeiter" icon="user-ninja" ready={mainRecord} onlyIf={mainRecord?.type === 'civil'}>
        (Ziviltechniker)
      </Detail>
    </div>

    <div class="panel-block">
      <Detail title="Kontrolle" icon="user-check" ready={mainRecord}>
        {mainRecord.verifiedBy?.fullName || '(nicht kontrolliert)'}
      </Detail>
    </div>

    {#if mainRecord?.notes && !appInfo.user.isCustomer}
      <div class="panel-block">
        <Detail title="Notizen" icon="sticky-note">
          {mainRecord.notes.split('\n').join('; ')}
        </Detail>
      </div>
    {/if}

    {#if !appInfo.user.isCustomer}
      <div class="panel-block">
        <em>Für weitere Details bitte "{appInfo.user.admin ? 'Ändern' : 'Ansehen'}" klicken</em>
      </div>
    {/if}

    {#if appInfo.user.isCustomer && mainRecord && mainRecord?.type !== 'civil'}
      <div class="panel-block">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column is-6">
              <Button iconLeft="file-invoice" type="is-link" outlined class="is-fullwidth" on:click={() => generateReport(mainRecord)}>Protokollmappe</Button>
            </div>
            <div class="column is-6">
              <Button iconLeft="envelope-open-text" type="is-warning" outlined class="is-fullwidth" on:click={() => openAndReplace(ViewServiceRequestForm, { auditObject: mainRecord.auditObject })}>Service-Anforderung</Button>
            </div>
          </div>
        </div>
      </div>
    {/if}
  </svelte:fragment>

  <svelte:fragment slot="actions" let:mainRecord>
    {#if mainRecord}
      <a href={undefined} class="dropdown-item has-text-info" on:click={() => generateReport(mainRecord)} class:is-disabled={mainRecord.type === 'civil' && !mainRecord.externalPdfUrl}><Icon icon="file-invoice" /> Protokolldokument</a>
      {#if !mainRecord.verifiedBy && !appInfo.user.isCustomer}
        <hr class="dropdown-divider" />
        <a href={undefined} class="dropdown-item has-text-success" on:click={() => markVerified(mainRecord)}><Icon icon="check" /> Als kontrolliert markieren</a>
      {/if}
    {/if}
  </svelte:fragment>

  <span class="list-entry" class:has-background-danger-light={record.result.state === 'red'} class:has-background-warning-light={record.result.state === 'yellow'} class:has-background-success-light={record.result.state === 'green'} class:has-background-grey-light={record.result.state === 'grey'}>
    {record.label}
    {#if record.result.state === 'yellow' || record.result.state === 'red'}
      <br />
      <small>
        {record.result.description}
        {#if record.result.photoUrl}
          <a href={undefined} on:click={() => open(ViewImagePreview, { url: record.result.photoUrl }, true)} title="Fotodokumentation"><Icon icon="image" /></a>
        {/if}
      </small>
    {/if}
  </span>
</ResourceView>
