<script>
  export let topView
  export let closeSelf
  export let closeHigher
  export let openAndReplace

  export let id
  export let title
  export let onSave
  export let isolated = false

  import ResourceView from '../components/ResourceView.svelte'
  import { escape, ellipsis, formatCustomDate, dateToYmdString, monthToYmdString, numPad, ymdStringToDate } from '../lib/utils'
  import ViewAuditObjectForm from './ViewAuditObjectForm.svelte'
  import ViewAuditLogDetails from './ViewAuditLogDetails.svelte'
  import ViewAuditLogForm from './ViewAuditLogForm.svelte'
  import ViewServiceLogDetails from './ViewServiceLogDetails.svelte'
  import ViewServiceLogForm from './ViewServiceLogForm.svelte'
  import ViewCustomerDetails from './ViewCustomerDetails.svelte'
  import ViewPropertyDetails from './ViewPropertyDetails.svelte'
  import { tick } from 'svelte'
  import Detail from '../components/Detail.svelte'
  import { Icon, Toast, Dialog, Button } from 'svelma'
  import { apiCall } from '../lib/api'
  import { apiUrl } from '../lib/env'
  import { appInfo } from '../lib/appInfo'
  import { open } from '../stores/viewStack'
  import ViewSelectDialog from './ViewSelectDialog.svelte'
  import ViewServiceRequestForm from './ViewServiceRequestForm.svelte'
  import serviceLogTypes from '../lib/serviceLogTypes'

  let tab

  let selected
  let silentReload
  let actionInProgress

  let lastAuditLog
  let lastAdditionalCivilAuditLog

  async function openCustomer (customer) {
    if (!customer) throw new Error('No customer set')
    selected = null
    await tick()
    openAndReplace(ViewCustomerDetails, { id: customer.id, onSave: silentReload, isolated: true })
  }

  async function openProperty (property) {
    if (!property) throw new Error('No property set')
    selected = null
    await tick()
    openAndReplace(ViewPropertyDetails, { id: property.id, onSave: silentReload, isolated: true })
  }

  async function decommission (mainRecord) {
    actionInProgress = true
    try {
      await apiCall('PATCH', `/auditObjects/${id}`, { decommissioned: true })
      Toast.create({ message: 'Prüfkörper wurde stillgelegt.', type: 'is-success' })
      silentReload({ id })
    } catch (e) {
      console.error(e)
      Dialog.alert({ message: escape(e.serverErrorMessage || `Die Änderungen konnten nicht gespeichert werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
    } finally {
      actionInProgress = false
    }
  }

  async function bringBack (mainRecord) {
    actionInProgress = true
    try {
      await apiCall('PATCH', `/auditObjects/${id}`, { decommissioned: false })
      Toast.create({ message: 'Prüfkörper wurde zurückgeholt.', type: 'is-success' })
      silentReload({ id })
    } catch (e) {
      console.error(e)
      Dialog.alert({ message: escape(e.serverErrorMessage || `Die Änderungen konnten nicht gespeichert werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
    } finally {
      actionInProgress = false
    }
  }

  async function generateReport (auditLogId) {
    window.open(`${apiUrl}/auditLogs/${auditLogId}/report`)
  }
</script>

<ResourceView
  {topView} {closeSelf} {closeHigher} {openAndReplace}
  {id} {title}
  icon="dice-d6"
  defaultTitle="Prüfkörper-Details"
  {onSave}
  bind:actionInProgress
  mainPopulate={JSON.stringify([{ path: 'property', select: 'name,customer,civilAuditMode', populate: { path: 'customer', select: 'company,customerNo' } }])}
  getTitle={record => record.publicId ? `${record.publicId}: ${record.manufacturer ?? ''} ${record.serialNo ?? ''} ${!record.manufacturer && !record.serialNo ? 'Unbekannt' : ''}` : undefined}
  getChildTitle={record => record.overallState ? `${record.auditObjectData?.publicId || 'Unbekannter Prüfkörper'} am ${formatCustomDate(record.date)}` : (`${serviceLogTypes[record.type] ?? 'Historie-Eintrag'} vom ${formatCustomDate(dateToYmdString(new Date(record.createdAt)))}`)}
  childIcon={record => record.overallState ? 'clipboard-list' : 'comment'}
  handleLoad={async (id, mainRecord) => {
    let items = []
    if (!appInfo.user.isCustomer) {
      items = items.concat(await apiCall('GET', '/auditLogs', { order: '-date', select: 'type,date,auditObjectData.publicId,overallState', filter: JSON.stringify({ auditObject: id }) }))
    }
    items = items.concat(await apiCall('GET', '/serviceLogs', { order: '-createdAt', select: 'createdAt,user,type,text', filter: JSON.stringify({ auditObject: id }), populate: JSON.stringify([['user', 'fullName']]) }))

    if (appInfo.user.isCustomer) {
      const result = await apiCall('GET', '/auditLogs', {
        order: '-date',
        select: 'type,date,overallState,audit',
        filter: JSON.stringify({ auditObject: id, type: mainRecord.property?.civilAuditMode === 'additional' ? 'regular' : { $in: ['regular', 'civil'] } }),
        populate: JSON.stringify([{ path: 'audit', select: 'status' }]),
        limit: 1
      })
      lastAuditLog = result[0] // eslint-disable-line no-unused-vars

      if (mainRecord.property?.civilAuditMode === 'additional') {
        const result = await apiCall('GET', '/auditLogs', { order: '-date', select: 'id', filter: JSON.stringify({ auditObject: id, type: 'civil', date: { $gte: lastAuditLog?.date ? dateToYmdString(new Date(ymdStringToDate(lastAuditLog.date).valueOf() - 366 * 86400000)) : '0000-00-00' } }), limit: 1 })
        lastAdditionalCivilAuditLog = result[0] // eslint-disable-line no-unused-vars
      }
    }

    return { items }
  }}
  getItems={(items, tab) => (!appInfo.user.isCustomer && tab === 'auditLogs') ? items.filter(item => item.overallState) : items.filter(item => !item.overallState)}
  resourcePath="/auditObjects"
  resourceName="Prüfkörper"
  childResourcePath={appInfo.user.isCustomer ? undefined : '/auditLogs'}
  childResourceName={(!appInfo.user.isCustomer && tab === 'auditLogs') ? 'Prüfprotokoll' : 'Historie-Eintrag'}
  childResourceGender={(!appInfo.user.isCustomer && tab === 'auditLogs') ? 'n' : 'm'}
  ViewMainForm={ViewAuditObjectForm}
  ViewChildDetails={(!appInfo.user.isCustomer && tab === 'auditLogs') ? ViewAuditLogDetails : ViewServiceLogDetails}
  childDetailsProps={{ openedFrom: 'auditObject' }}
  ViewChildForm={(!appInfo.user.isCustomer && tab === 'auditLogs') ? ViewAuditLogForm : ViewServiceLogForm}
  tabs={appInfo.user.isCustomer ? undefined : { auditLogs: 'Prüfprotokolle', serviceLogs: 'Servicehistorie' }}
  bind:tab
  search={appInfo.user.isCustomer ? undefined : (searchQuery, searchRegex, record) => (record.date && formatCustomDate(record.date).match(searchRegex)) || record.text?.match(searchRegex)}
  allowEdit={!appInfo.user.isCustomer}
  allowDelete={!appInfo.user.isCustomer}
  canDelete={(mainRecord, items) => !items.length}
  readonly={!appInfo.user.admin}
  allowCreateDespiteReadonly={tab === 'serviceLogs' && !appInfo.user.isCustomer}
  hideId={appInfo.user.isCustomer}
  handleCreateNewChildResource={async (id, mainRecord, afterCreate, ViewChildForm) => {
    if (!appInfo.user.isCustomer && tab === 'auditLogs') {
      const audits = await apiCall('GET', '/audits', { filter: JSON.stringify({ property: mainRecord.property?.id, status: 'inProgress' }), select: 'type,startDate,endDate,month,year,user', populate: [['user', 'fullName']] })
      if (!audits.length) {
        Dialog.alert({ message: 'Es ist momentan kein Prüftermin im Status "In Arbeit" für diese Liegenschaft verfügbar!', type: 'is-warning', icon: 'exclamation-circle' })
        return
      }

      const filteredAudits = []
      for (const audit of audits) {
        const logs = await apiCall('GET', '/auditLogs', { filter: JSON.stringify({ audit: audit.id, property: mainRecord.property?.id }), select: 'id' })
        if (!logs.length) filteredAudits.push(audit)
      }

      if (!filteredAudits.length) {
        Dialog.alert({ message: 'Es ist momentan kein Prüftermin im Status "In Arbeit" für diese Liegenschaft verfügbar, der nicht schon ein Prüfprotokoll für diesen Prüfkörper hat!', type: 'is-warning', icon: 'exclamation-circle' })
        return
      }

      if (filteredAudits.length === 1) {
        return open(ViewChildForm, { parentId: id, auditId: filteredAudits[0].id, onSave: afterCreate, create: true }, true)
      }

      open(ViewSelectDialog, {
        title: 'Prüftermin wählen',
        options: filteredAudits.map(audit => [audit.id, `${audit.type === 'civil' ? 'Ziviltechniker-' : ''}Prüftermin vom ${formatCustomDate(audit.startDate || monthToYmdString(audit.month, audit.year))}${audit.endDate && audit.endDate !== audit.startDate ? ` - ${formatCustomDate(audit.endDate)}` : ''}${audit.user ? ` (${audit.user?.fullName})` : ''}${(!audit.startDate?.startsWith(`${numPad(audit.year, 4)}-${numPad(audit.month, 2)}`) ? ` (${numPad(audit.month, 2)}.${numPad(audit.year, 4)})` : '')}`]),
        onSave: async auditId => {
          await tick()
          open(ViewChildForm, { parentId: id, auditId, onSave: afterCreate, create: true }, true)
        }
      }, true)
    } else {
      open(ViewChildForm, { parentId: id, onSave: afterCreate, create: true }, true)
    }
  }}

  let:record
  bind:silentReload
  bind:selected
>
  <svelte:fragment slot="header" let:mainRecord>
    {#if isolated}
      {#if !appInfo.user.isCustomer}
        <div class="panel-block">
          <Detail title="Kunde" icon="industry" ready={mainRecord}>
            <a href={undefined} on:click={() => openCustomer(mainRecord.property?.customer)}>{mainRecord?.property?.customer?.company} (#{mainRecord?.property?.customer?.customerNo})</a>
          </Detail>
        </div>
      {/if}

      <div class="panel-block">
        <Detail title="Liegenschaft" icon="building" ready={mainRecord}>
          <a href={undefined} on:click={() => openProperty(mainRecord.property)}>{mainRecord?.property?.name}</a>
        </Detail>
      </div>
    {/if}

    <div class="panel-block">
      <Detail title="Barcode-Nummer" icon="tag" ready={mainRecord}>
        {mainRecord?.barcode || '(nicht gesetzt)'}
        {#if mainRecord?.decommissioned}
          <span class="has-text-danger">(stillgelegt)</span>
        {/if}
      </Detail>
    </div>

    <div class="panel-block">
      {#if mainRecord}
        <span>
          <Detail title="Baujahr" icon="calendar" value={mainRecord.year} />
          {#if mainRecord.type === 'door'}
            <Detail title="Türblattausführung" icon="th-large" value={mainRecord.doorVariation} />
            <Detail title="Türtyp" icon="sliders-h" value={mainRecord.doorType} />
          {:else if mainRecord.type === 'gate'}
            <Detail title="Torblattausführung" icon="th-large" value={mainRecord.gateVariation} />
            <Detail title="Tortyp" icon="sliders-h" value={mainRecord.gateType} />
          {:else if mainRecord.type === 'fireDoor' || mainRecord.type === 'fireGate'}
            <Detail title="Klassifikation" icon="certificate" value={mainRecord.fireClass} />
          {:else if mainRecord.type === 'boomBarrier'}
            <Detail title="Baumlänge" icon="ruler" onlyIf={mainRecord.barLength}>
              {mainRecord.barLength} mm
            </Detail>
          {/if}
        </span>
      {:else}
        ...
      {/if}
    </div>

    {#if mainRecord?.notes && !appInfo.user.isCustomer}
      <div class="panel-block">
        <Detail title="Notizen" icon="sticky-note">
          {mainRecord.notes.split('\n').join('; ')}
        </Detail>
      </div>
    {/if}

    {#if !appInfo.user.isCustomer}
      <div class="panel-block">
        <em>Für weitere Details bitte "{appInfo.user.admin ? 'Ändern' : 'Ansehen'}" klicken</em>
      </div>
    {/if}

    {#if appInfo.user.isCustomer}
      <div class="panel-block">
        {#if mainRecord}
          <Detail title="Letzte Prüfung" icon="calendar-check">
            {#if lastAuditLog}
              {formatCustomDate(lastAuditLog.date)}
              {#if lastAuditLog.audit?.status === 'inProgress'}
                (<strong class="has-text-warning">in Arbeit</strong>)
              {:else if lastAuditLog.audit?.status === 'completed'}
                (<strong class="has-text-success">wird fertiggestellt</strong>)
              {:else if lastAuditLog.audit?.status === 'published'}
                (<strong class="has-text-info">Ergebnis liegt vor</strong>)
              {/if}
            {:else}
              <span class="has-text-grey-light">Noch keine Prüfungen durchgeführt</span>
            {/if}
          </Detail>
        {:else}
          ...
        {/if}
      </div>

      {#if mainRecord && lastAuditLog?.audit?.status === 'published'}
        <div class="panel-block">
          <Detail title="Gesamtergebnis" icon="traffic-light">
            {#if lastAuditLog?.overallState === 'green'}
              <strong class="has-text-success">Keine offensichtlichen Mängel</strong>
            {:else if lastAuditLog?.overallState === 'yellow'}
              <strong class="has-text-warning">Hinweise sollten abgeklärt werden</strong>
            {:else if lastAuditLog?.overallState === 'red'}
              <strong class="has-text-danger">Offensichtliche Mängel festgestellt</strong>
            {:else}
              <span>k.A.</span>
            {/if}
          </Detail>
        </div>

        {#if lastAuditLog?.type === 'civil'}
          <div class="panel-block">
            <Detail title="Info" icon="info-circle">
              Prüfung durchgeführt von Ziviltechniker
            </Detail>
          </div>
        {/if}

        <div class="panel-block">
          <div class="container">
            <div class="columns is-mobile">
              <div class="column is-12">
                <Button iconLeft="file-invoice" type="is-link" outlined class="is-fullwidth" on:click={() => generateReport(lastAuditLog.id)}>Einzelprotokoll öffnen</Button>
              </div>
            </div>
          </div>
        </div>
      {/if}

      {#if mainRecord && lastAdditionalCivilAuditLog}
        <div class="panel-block">
          <div class="container">
            <div class="columns is-mobile">
              <div class="column is-12">
                <Button iconLeft="file-medical" type="is-info" outlined class="is-fullwidth" on:click={() => generateReport(lastAdditionalCivilAuditLog.id)}>Zusätzliches Ziviltechniker-Protokoll öffnen</Button>
              </div>
            </div>
          </div>
        </div>
      {/if}
    {/if}

    {#if appInfo.user.isCustomer && mainRecord}
      <div class="panel-block">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column is-12">
              <Button iconLeft="envelope-open-text" type="is-warning" outlined class="is-fullwidth" on:click={() => openAndReplace(ViewServiceRequestForm, { auditObject: mainRecord, onSave: silentReload })}>Service-Anforderung</Button>
            </div>
          </div>
        </div>
      </div>
    {/if}
  </svelte:fragment>

  <svelte:fragment slot="actions" let:mainRecord>
    {#if mainRecord}
      {#if appInfo.user.admin}
        {#if mainRecord.decommissioned}
          <a href={undefined} class="dropdown-item" on:click={() => bringBack(mainRecord)}><Icon icon="undo" /> Zurückholen</a>
        {:else}
          <a href={undefined} class="dropdown-item" on:click={() => decommission(mainRecord)}><Icon icon="archive" /> Stilllegen</a>
        {/if}
      {:else}
        <em class="dropdown-item">Keine Aktionen verfügbar</em>
      {/if}
    {/if}
  </svelte:fragment>

  {#if record.overallState}
    <span class="list-entry" class:has-text-danger={record.overallState === 'red'} class:has-text-warning={record.overallState === 'yellow'} class:has-text-success={record.overallState === 'green'}>
      {formatCustomDate(record.date)}
      {#if record.type === 'civil'}
        (Ziviltechniker)
      {/if}
    </span>
  {:else}
    <span class="list-entry">
      {serviceLogTypes[record.type] ?? 'Historie-Eintrag'} vom {formatCustomDate(dateToYmdString(new Date(record.createdAt)))}<br />
      <small>{record.user?.fullName ?? '(unbekannt)'}: {ellipsis(record.text, 25)}</small>
    </span>
  {/if}
</ResourceView>
