'use strict'

export function cleanErrorObject (obj) {
  for (const k in obj) {
    if (typeof obj[k] === 'object' && obj[k]) {
      cleanErrorObject(obj[k])
      if (!Object.keys(obj[k]).length) delete obj[k]
    } else if (typeof obj[k] !== 'string' || !k) {
      delete obj[k]
    }
  }
}

export function trimFields (obj, fieldsToTrim) {
  for (const field of fieldsToTrim) {
    if (typeof obj[field] === 'string') obj[field] = obj[field].trim() || null
  }
}

export function applyRequired (obj, fieldErrors, requiredFields) {
  trimFields(obj, requiredFields)
  for (const field of requiredFields) {
    if ((obj[field] ?? '') === '') fieldErrors[field] = 'Angabe erforderlich'
  }
}
