<script>
  export let error = new Error()
  export let title = 'Ein Fehler ist aufgetreten!'

  import { Notification } from 'svelma'

  console.error(error)
</script>

<Notification type="is-danger" icon={true} showClose={false}>
  <strong>{title}</strong><br>
  <span>{error.message ?? (typeof error === 'string' ? error : JSON.stringify(error))}</span>
</Notification>
