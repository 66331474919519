<script>
  export let label = null
  export let icon = null
  export let value = null
  export let error = null
  export let hint = null
  export let col = null
  export let disabled = false
  export let accept = '.png,.jpg,.jpeg,.gif'
  export let linkPreview = false
  export let preview = true

  import { Field, Input, Button, Icon, Notification, Toast } from 'svelma'
  import { createEventDispatcher } from 'svelte'
  import { apiCall } from '../lib/api'
  import { escape } from '../lib/utils'
  import { open } from '../stores/viewStack'
  import ViewImagePreview from '../views/ViewImagePreview.svelte'

  const dispatch = createEventDispatcher()

  $: if (value === undefined) value = null
  $: filename = value ? decodeURIComponent(value.includes('__') ? value.replace(/^(.*?)__/, '') : value.replace(/^.*\//, '')) : ''

  function reset () {
    value = null
    dispatch('change', { value })
  }

  function showPreview () {
    if (linkPreview) {
      window.open(value)
    } else {
      open(ViewImagePreview, { url: value }, true)
    }
  }


  let fileElement
  export let loading = false

  function upload () {
    fileElement.value = ''
    fileElement.click()
  }

  async function handleUpload () {
    if (!fileElement.files.length) return

    loading = true
    try {
      const { url } = await apiCall('POST', '/upload', fileElement.files[0])
      console.log('File uploaded', url)
      Toast.create({ message: 'Datei wurde hochgeladen', type: 'is-success' })
      value = url
      dispatch('change', { value })
    } catch (e) {
      console.error(e)
      Notification.create({ message: `<strong>Upload fehlgeschlagen!</strong><br><span>${escape(e.serverErrorMessage || String(e))}</span>`, type: 'is-danger', icon: true, duration: 15000 })
    } finally {
      loading = false
    }
  }
</script>

{#key error}
  <Field {label} type={error ? 'is-danger' : ''} message={error || hint} class="{$$props.class ?? ''} {col ? `column is-${col}` : ''}">
    <div class="field-body">
      <div class="field has-addons">
        <Input bind:value={filename} {icon} class={error ? 'is-danger' : ''} expanded readonly {...disabled ? { disabled: true } : {}} title={value ?? ''} on:click={upload} />
        <input type="file" style="display: none;" bind:this={fileElement} on:change={handleUpload} {accept} />
        <div class="control"><Button on:click={reset} type="is-danger" disabled={!value}><Icon icon="times" /></Button></div>
        {#if preview}<div class="control"><Button on:click={showPreview} iconLeft="search-plus" disabled={!value}><span class="is-hidden-mobile">Zeigen</span></Button></div>{/if}
        <div class="control"><Button on:click={upload} iconLeft="upload" type="is-primary" {loading}><span class="is-hidden-mobile">Hochladen</span></Button></div>
      </div>
    </div>
  </Field>
{/key}
