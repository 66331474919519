<script>
  export let closeSelf
  export let onSave = () => {}

  import ResourceForm from '../components/ResourceForm.svelte'
  import FormField from '../components/FormField.svelte'
  import RadioGroupField from '../components/RadioGroupField.svelte'
  import { cleanErrorObject } from '../lib/validation'

  let record
  let fieldErrors = {}
</script>

<ResourceForm
  {closeSelf} {onSave}
  id='~'
  title="Einstellungen für Etikettendruck"
  select="barcodePrint"
  validateRecord={async record => {
    const fieldErrors = {
      barcodePrint: {
        pageMargins: {},
        perPage: {},
        minPadding: {}
      }
    }

    for (const k of ['left', 'top', 'right', 'bottom']) {
      record.barcodePrint.pageMargins[k] = record.barcodePrint.pageMargins[k] || 0
      if (record.barcodePrint.pageMargins[k] < 0) fieldErrors.barcodePrint.pageMargins[k] = 'Der Wert kann nicht negativ sein'
    }

    for (const k of ['horizontally', 'vertically']) {
      record.barcodePrint.perPage[k] = Math.round(record.barcodePrint.perPage[k]) || 0
      if (record.barcodePrint.perPage[k] <= 0) fieldErrors.barcodePrint.perPage[k] = 'Der Wert muss größer als null sein'
    }

    for (const k of ['horizontally', 'vertically']) {
      record.barcodePrint.minPadding[k] = record.barcodePrint.minPadding[k] || 0
      if (record.barcodePrint.minPadding[k] < 0) fieldErrors.barcodePrint.minPadding[k] = 'Der Wert kann nicht negativ sein'
    }

    cleanErrorObject(fieldErrors)

    return { record, fieldErrors }
  }}
  resourcePath="/settings"
  resourceName="Einstellungen"
  bind:record
  bind:fieldErrors
>
  <div class="columns">
    <FormField label="Seitenrand links" type="number" min=0 suffix="mm" icon="long-arrow-alt-left" col=3 bind:value={record.barcodePrint.pageMargins.left} error={fieldErrors.barcodePrint?.pageMargins?.left} />
    <FormField label="Seitenrand oben" type="number" min=0 suffix="mm" icon="long-arrow-alt-up" col=3 bind:value={record.barcodePrint.pageMargins.top} error={fieldErrors.barcodePrint?.pageMargins?.top} />
    <FormField label="Seitenrand rechts" type="number" min=0 suffix="mm" icon="long-arrow-alt-right" col=3 bind:value={record.barcodePrint.pageMargins.right} error={fieldErrors.barcodePrint?.pageMargins?.right} />
    <FormField label="Seitenrand unten" type="number" min=0 suffix="mm" icon="long-arrow-alt-down" col=3 bind:value={record.barcodePrint.pageMargins.bottom} error={fieldErrors.barcodePrint?.pageMargins?.bottom} />
  </div>

  <div class="columns">
    <FormField label="Anzahl Spalten" type="number" min=1 icon="grip-lines-vertical" col=3 bind:value={record.barcodePrint.perPage.horizontally} error={fieldErrors.barcodePrint?.perPage?.horizontally} />
    <FormField label="Anzahl Zeilen" type="number" min=1 icon="grip-lines" col=3 bind:value={record.barcodePrint.perPage.vertically} error={fieldErrors.barcodePrint?.perPage?.vertically} />
    <FormField label="Mindestabstand waagrecht" type="number" min=0 suffix="mm" icon="arrows-alt-h" col=3 bind:value={record.barcodePrint.minPadding.horizontally} error={fieldErrors.barcodePrint?.minPadding?.horizontally} />
    <FormField label="Mindestabstand senkrecht" type="number" min=0 suffix="mm" icon="arrows-alt-v" col=3 bind:value={record.barcodePrint.minPadding.vertically} error={fieldErrors.barcodePrint?.minPadding?.vertically} />
  </div>

  <div class="columns">
    <RadioGroupField label="Code-Typ" options={[['qr', 'QR-Code'], ['upc', 'Barcode (UPC-A)']]} col=12 bind:value={record.barcodePrint.codeType} error={fieldErrors.barcodePrint?.codeType} />
  </div>
</ResourceForm>
