<script>
  export let label = null
  export let value = null
  export let error = null
  export let hint = null
  export let col = null
  export let options = []
  export let readonly = false
  export let disabled = false

  import { Field } from 'svelma'
  import { createEventDispatcher } from 'svelte'
  import { generateRandomString } from '../lib/utils'

  const dispatch = createEventDispatcher()

  function handleRadioButtonClick (event, newValue) {
    value = newValue
    dispatch('change', event.detail)
  }

  const name = generateRandomString()
</script>

<style lang="scss">
  .radio {
    line-height: 2rem;

    > * {
      vertical-align: middle;
    }
  }

  .control {
    line-height: 2em;
    padding: 0.3333em 0;
  }
</style>

{#key error}
  <Field {label} type={error ? 'is-danger' : ''} message={error || hint} class="{$$props.class ?? ''} {col ? `column is-${col}` : ''}">
    <div class="control {error ? 'is-danger has-text-danger' : ''}">
      {#each options as [optValue, optLabel] (optValue)}
        <label class="radio" class:has-cursor-not-allowed={disabled || readonly}>
          <input type="radio" {name} on:click={event => handleRadioButtonClick(event, optValue)} checked={value === optValue} disabled={disabled || readonly}>
          <span>{optLabel}</span>
        </label>
      {/each}
    </div>
  </Field>
{/key}
