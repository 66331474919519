<script>
  export let types = {}
  export let value = null
  export let label = 'Typ'

  import { Field, Button } from 'svelma'
</script>

<style lang="scss">
  .type-select {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
</style>

{#if !value}
  <div class="type-select is-hidden-mobile">
    <Field {label}>
      <div class="field-body">
        <div class="field has-addons is-justify-content-center">
          {#each Object.entries(types) as [key, label] (key)}
            <div class="control"><Button on:click={() => (value = key)}>{label}</Button></div>
          {/each}
        </div>
      </div>
    </Field>
  </div>
  <div class="type-select is-hidden-tablet">
    <Field label="Typ" addons={false}>
      {#each Object.entries(types) as [key, label] (key)}
        <Button class="is-fullwidth" on:click={() => (value = key)}>{label}</Button>
      {/each}
    </Field>
  </div>
{:else}
  <slot type={value} />
{/if}
