<script>
  export let topView
  export let closeSelf
  export let closeHigher
  export let openAndReplace

  export let id
  export let title
  export let onSave
  export let isolated = false

  import ResourceView from '../components/ResourceView.svelte'
  import auditStatuses from '../lib/auditStatuses'
  import { escape, formatCustomDate, numPad, ellipsis } from '../lib/utils'
  import ViewAuditForm from './ViewAuditForm.svelte'
  import ViewAuditLogDetails from './ViewAuditLogDetails.svelte'
  import ViewAuditLogForm from './ViewAuditLogForm.svelte'
  import ViewCustomerDetails from './ViewCustomerDetails.svelte'
  import ViewPropertyDetails from './ViewPropertyDetails.svelte'
  import { tick } from 'svelte'
  import Detail from '../components/Detail.svelte'
  import { apiCall } from '../lib/api'
  import { Icon, Toast, Dialog, Button } from 'svelma'
  import { appInfo } from '../lib/appInfo'
  import { open } from '../stores/viewStack'
  import ViewSelectDialog from './ViewSelectDialog.svelte'
  import { apiUrl, appUrl } from '../lib/env'
  import { checkAutoPublish } from '../lib/autoPublish'
  import { PREFIXES } from '../lib/publicIdPrefixes'
  import TimeFormat from 'hh-mm-ss'
  import { batchServiceRequest } from '../lib/batchServiceRequest'

  let selected
  let silentReload
  let actionInProgress

  function getTypeAggregation (records) {
    return Object.fromEntries(Object.keys(PREFIXES).map(type => [type, records.filter(r => r.auditObjectData?.type === type).length]))
  }

  async function openCustomer (customer) {
    if (!customer) throw new Error('No customer set')
    selected = null
    await tick()
    openAndReplace(ViewCustomerDetails, { id: customer.id, onSave: silentReload, isolated: true })
  }

  async function openProperty (property) {
    if (!property) throw new Error('No property set')
    selected = null
    await tick()
    openAndReplace(ViewPropertyDetails, { id: property.id, onSave: silentReload, isolated: true })
  }

  async function setStatus (mainRecord, status) {
    actionInProgress = true
    try {
      await apiCall('PATCH', `/audits/${id}`, { status })
      Toast.create({ message: 'Status wurde aktualisiert.', type: 'is-success' })
      silentReload({ id })
    } catch (e) {
      console.error(e)
      Dialog.alert({ message: escape(e.serverErrorMessage || `Die Änderungen konnten nicht gespeichert werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
    } finally {
      actionInProgress = false
    }
  }

  async function markBilled (mainRecord) {
    actionInProgress = true
    try {
      await apiCall('PATCH', `/audits/${id}`, { billed: true })
      Toast.create({ message: 'Abrechnungsstatus wurde aktualisiert.', type: 'is-success' })
      silentReload({ id })
    } catch (e) {
      console.error(e)
      Dialog.alert({ message: escape(e.serverErrorMessage || `Die Änderungen konnten nicht gespeichert werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
    } finally {
      actionInProgress = false
    }
  }

  async function assign (mainRecord) {
    let users
    actionInProgress = true
    try {
      users = await apiCall('GET', '/users', { order: 'fullName', filter: JSON.stringify({ isCustomer: { $ne: true } }), select: 'fullName' })
    } finally {
      actionInProgress = false
    }

    open(ViewSelectDialog, {
      title: 'Mitarbeiter wählen',
      options: [[null, '(nicht zugewiesen)']].concat(users.map(user => [user.id, user.fullName])),
      selected: mainRecord.user?.id ?? null,
      onSave: async userId => {
        actionInProgress = true
        try {
          await apiCall('PATCH', `/audits/${id}`, { user: userId })
          Toast.create({ message: 'Zuweisung wurde aktualisiert.', type: 'is-success' })
          silentReload({ id })
        } catch (e) {
          console.error(e)
          Dialog.alert({ message: escape(e.serverErrorMessage || `Die Änderungen konnten nicht gespeichert werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
        } finally {
          actionInProgress = false
        }
      }
    }, true)
  }

  async function generateReport (mainRecord) {
    window.open(`${apiUrl}/audits/${id}/report`)
  }

  async function generateAuditObjectList (mainRecord) {
    window.open(`${apiUrl}/properties/${mainRecord.property?.id ?? mainRecord.property}/auditObjectList`)
  }

  async function generateAuditObjectPhotoGrid (mainRecord) {
    window.open(`${apiUrl}/properties/${mainRecord.property?.id ?? mainRecord.property}/auditObjectPhotoGrid`)
  }

  async function generateIssueList (mainRecord) {
    window.open(`${apiUrl}/audits/${mainRecord.id}/issueList`)
  }

  async function openInAppView (mainRecord) {
    window.open(`${appUrl}/audit/${mainRecord.id}`)
  }

  async function markAllVerified (mainRecord, items) {
    const toUpdate = items.filter(l => !l.verifiedBy)
    if (!toUpdate.length) return // Nothing to do

    if (await Dialog.confirm({
      message: `Es ${toUpdate.length === 1 ? 'wird ein Prüfprotokoll' : `werden ${toUpdate.length} Prüfprotokolle`} als kontrolliert durch ${appInfo.user.fullName} markiert. Fortfahren?`,
      confirmText: 'Ja',
      cancelText: 'Nein',
      type: 'is-success'
    })) {
      actionInProgress = true
      try {
        for (const item of toUpdate) {
          await apiCall('PATCH', `/auditLogs/${item.id}`, { verifiedBy: appInfo.user.id })
        }
        Toast.create({ message: 'Alle Prüfprotkolle wurden als kontrolliert markiert.', type: 'is-success' })

        await checkAutoPublish(mainRecord.id)

        silentReload()
      } catch (e) {
        console.error(e)
        Dialog.alert({ message: escape(e.serverErrorMessage || `Die Änderungen konnten nicht gespeichert werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
        silentReload()
      } finally {
        actionInProgress = false
      }
    }
  }
</script>

<ResourceView
  {topView} {closeSelf} {closeHigher} {openAndReplace}
  {id} {title}
  icon="calendar-check"
  defaultTitle="Prüftermin-Details"
  {onSave}
  bind:actionInProgress
  mainPopulate={JSON.stringify([['user', 'fullName'], { path: 'property', select: 'name,customer', populate: { path: 'customer', select: 'company,customerNo' } }])}
  getTitle={record => (formatCustomDate(record.startDate) + (record.endDate && record.endDate !== record.startDate ? ` - ${formatCustomDate(record.endDate)}` : '') + (!record.startDate?.startsWith(`${numPad(record.year, 4)}-${numPad(record.month, 2)}`) ? ` (${numPad(record.month, 2)}.${numPad(record.year, 4)})` : '')) + (record.type === 'civil' ? ' (Ziviltechniker)' : '')}
  getChildTitle={record => `${record.auditObjectData?.publicId || 'Unbekannter Prüfkörper'} am ${formatCustomDate(record.date)}`}
  order="auditObjectData.idNumber"
  select="type,overallState,verifiedBy,auditObjectData.idNumber,auditObjectData.publicId,auditObjectData.manufacturer,auditObjectData.serialNo,auditObjectData.barcode,auditObjectData.type,auditTimeInSeconds"
  filter={JSON.stringify({ audit: id })}
  childIcon="clipboard-list"
  resourcePath="/audits"
  resourceName="Prüftermin"
  childResourcePath="/auditLogs"
  childResourceName="Prüfprotokoll"
  childResourceGender="n"
  ViewMainForm={ViewAuditForm}
  ViewChildDetails={ViewAuditLogDetails}
  childDetailsProps={{ openedFrom: 'audit' }}
  ViewChildForm={ViewAuditLogForm}
  search={(searchQuery, searchRegex, record) => [record.auditObjectData?.manufacturer ?? '', record.auditObjectData?.serialNo ?? ''].join(' ').match(searchRegex) || record.auditObjectData?.publicId?.replace(/-.*$/, '') === searchQuery.toUpperCase() || record.auditObjectData?.publicId === searchQuery.toUpperCase() || record.auditObjectData?.barcode === searchQuery}
  allowEdit={!appInfo.user.isCustomer}
  allowDelete={!appInfo.user.isCustomer}
  allowCreate={false}
  canDelete={(mainRecord, items) => !items.length}
  readonly={!appInfo.user.admin}
  hideId={appInfo.user.isCustomer}
  let:record
  bind:silentReload
  bind:selected
>
  <svelte:fragment slot="header" let:mainRecord let:items>
    {#if isolated}
      {#if !appInfo.user.isCustomer}
        <div class="panel-block">
          <Detail title="Kunde" icon="industry" ready={mainRecord}>
            <a href={undefined} on:click={() => openCustomer(mainRecord.property?.customer)}>{mainRecord?.property?.customer?.company} (#{mainRecord?.property?.customer?.customerNo})</a>
          </Detail>
        </div>
      {/if}

      <div class="panel-block">
        <Detail title="Liegenschaft" icon="building" ready={mainRecord}>
          <a href={undefined} on:click={() => openProperty(mainRecord.property)}>{mainRecord?.property?.name}</a>
        </Detail>
      </div>
    {/if}

    {#if !appInfo.user.isCustomer}
      <div class="panel-block">
        <Detail title="Status" icon="traffic-light" ready={mainRecord}>
          <span
            class:has-text-warning={mainRecord?.status === 'inProgress'}
            class:has-text-success={mainRecord?.status === 'completed'}
            class:has-text-info={mainRecord?.status === 'published'}
            class:has-text-danger={mainRecord?.status === 'cancelled'}
          >
            {auditStatuses[mainRecord?.status] ?? mainRecord?.status}
          </span>
          {#if mainRecord?.billed}
            <span title="Abgerechnet"><Icon icon="euro-sign" /></span>
          {/if}
        </Detail>
      </div>
    {/if}

    <div class="panel-block">
      <Detail title="Mitarbeiter" icon="user" ready={mainRecord} onlyIf={mainRecord?.type !== 'civil'}>
        {#if mainRecord?.user}
          {mainRecord.user.fullName}
        {:else}
          (nicht zugewiesen)
        {/if}
      </Detail>

      <Detail title="Mitarbeiter" icon="user-ninja" ready={mainRecord} onlyIf={mainRecord?.type === 'civil'}>
        (Ziviltechniker)
      </Detail>
    </div>

    {#if mainRecord?.notes && !appInfo.user.isCustomer}
      <div class="panel-block">
        <Detail title="Notizen" icon="sticky-note">
          {mainRecord.notes.split('\n').join('; ')}
        </Detail>
      </div>
    {/if}

    {#if mainRecord?.coverNotes && !appInfo.user.isCustomer}
      <div class="panel-block">
        <Detail title="Hinweise am Deckblatt" icon="info-circle">
          {ellipsis(mainRecord.coverNotes.split('\n').join('; '), 100)}
        </Detail>
      </div>
    {/if}

    {#if !appInfo.user.isCustomer}
      <div class="panel-block">
        <Detail title="Protokolle nach Prüfkörpertyp" icon="chart-pie">
          {#if items.length}
            <div class="field is-grouped is-grouped-multiline" style="display: inline-flex;">
              {#each Object.entries(getTypeAggregation(items)) as [type, count]}
                {#if count}
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">{PREFIXES[type]}</span>
                      <span class="tag is-info">{count}</span>
                    </div>
                  </div>
                {/if}
              {/each}
            </div>
          {:else}
            -
          {/if}
        </Detail>
        <Detail title="Arbeitsdauer" icon="stopwatch" onlyIf={mainRecord?.type !== 'civil'}>
          {items.length ? TimeFormat.fromS(items.reduce((sum, item) => sum + item.auditTimeInSeconds ?? 0, 0)) : '-'}
        </Detail>
      </div>
    {/if}

    {#if appInfo.user.isCustomer && mainRecord && items.length && mainRecord?.type !== 'civil'}
      <div class="panel-block">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column is-12">
              <Button iconLeft="file-invoice" type="is-link" outlined class="is-fullwidth" on:click={() => generateReport(mainRecord)}>Protokollmappe</Button>
            </div>
          </div>
        </div>
      </div>
    {/if}
  </svelte:fragment>

  <svelte:fragment slot="actions" let:mainRecord let:items>
    {#if mainRecord}
      {#if items.length && mainRecord.type !== 'civil'}
        <a href={undefined} class="dropdown-item has-text-info" on:click={() => generateReport(mainRecord)}><Icon icon="file-invoice" /> Protokollmappe</a>
        <hr class="dropdown-divider" />
      {:else if appInfo.user.isCustomer}
        <em class="dropdown-item">Keine Aktionen verfügbar</em>
      {/if}
      {#if appInfo.user.admin}
        <a href={undefined} class="dropdown-item" on:click={() => assign(mainRecord)}><Icon icon="user-tag" /> Zuweisen</a>
        <hr class="dropdown-divider" />
        {#if mainRecord.status === 'planned'}
          <a href={undefined} class="dropdown-item has-text-success" on:click={() => setStatus(mainRecord, 'inProgress')}><Icon icon="play" /> Beginnen</a>
        {/if}
        {#if mainRecord.status === 'inProgress' && items.length}
          <a href={undefined} class="dropdown-item has-text-success" on:click={() => setStatus(mainRecord, 'completed')}><Icon icon="check-circle" /> Abschließen</a>
        {/if}
        {#if mainRecord.status === 'inProgress'}
          <a href={undefined} class="dropdown-item has-text-danger" on:click={() => setStatus(mainRecord, 'cancelled')}><Icon icon="times-circle" /> Abbrechen</a>
        {/if}
      {/if}
      {#if !appInfo.user.isCustomer}
        {#if mainRecord.status === 'completed' && !items.every(l => l.verifiedBy) }
          <a href={undefined} class="dropdown-item has-text-success" on:click={() => markAllVerified(mainRecord, items)}><Icon icon="stamp" /> Alle Protokolle als kontrolliert markieren</a>
        {/if}
        {#if mainRecord.status === 'completed' && items.every(l => l.verifiedBy) }
          <a href={undefined} class="dropdown-item has-text-success" on:click={() => setStatus(mainRecord, 'published')}><Icon icon="check-double" /> Ergebnis freigeben</a>
        {/if}
      {/if}
      {#if appInfo.user.admin}
        {#if mainRecord.status === 'published'}
          {#if !mainRecord.billed}
            <a href={undefined} class="dropdown-item has-text-success" on:click={() => markBilled(mainRecord)}><Icon icon="euro-sign" /> Als abgerechnet markieren</a>
          {/if}
          <a href={undefined} class="dropdown-item" on:click={() => setStatus(mainRecord, 'completed')}><Icon icon="unlink" /> Freigabe aufheben</a>
        {/if}
        {#if mainRecord.status === 'completed' || mainRecord.status === 'published' || mainRecord.status === 'cancelled'}
          <a href={undefined} class="dropdown-item" on:click={() => setStatus(mainRecord, 'inProgress')}><Icon icon="undo" /> Wiederaufnehmen</a>
        {/if}
        <hr class="dropdown-divider" />
      {/if}
      {#if !appInfo.user.isCustomer}
        <a href={undefined} class="dropdown-item" on:click={() => generateAuditObjectList(mainRecord)}><Icon icon="list-alt" /> Prüfkörperliste drucken</a>
        {#if mainRecord.type !== 'civil'}
          <a href={undefined} class="dropdown-item" on:click={() => generateAuditObjectPhotoGrid(mainRecord)}><Icon icon="images" /> Foto-Übersicht drucken</a>
          {#if items.some(l => (l.overallState === 'red' || l.overallState === 'yellow'))}
            <a href={undefined} class="dropdown-item" on:click={() => generateIssueList(mainRecord)}><Icon icon="dumpster-fire" /> Mängelliste drucken</a>
            <a href={undefined} class="dropdown-item" on:click={() => batchServiceRequest(items, v => (actionInProgress = v), () => silentReload({ id }))}><Icon icon="fast-forward" /> Massen-Reparaturauftrag</a>
          {/if}
          <a href={undefined} class="dropdown-item" on:click={() => openInAppView(mainRecord)}><Icon icon="mobile-alt" /> In Außendienst-Ansicht öffnen</a>
        {/if}
      {/if}
    {/if}
  </svelte:fragment>

  <span class="list-entry" class:has-text-danger={record.overallState === 'red'} class:has-text-warning={record.overallState === 'yellow'} class:has-text-success={record.overallState === 'green'}>
    {record.auditObjectData?.publicId}: {record.auditObjectData?.manufacturer ?? ''} {record.auditObjectData?.serialNo ?? ''} {!record.auditObjectData?.manufacturer && !record.auditObjectData?.serialNo ? 'Unbekannt' : ''}
  </span>
</ResourceView>
