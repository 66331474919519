<script>
  export let closeSelf
  export let onSave = () => {}
  export let auditObject
  export let title = `Service-Anforderung zu ${auditObject.publicId}`

  import Modal from '../components/Modal.svelte'
  import { Button, Dialog, Toast, Field } from 'svelma'
  import FormField from '../components/FormField.svelte'
  import FileField from '../components/FileField.svelte'
  import { escape } from '../lib/utils'
  import { apiCall } from '../lib/api'
  import { applyRequired } from '../lib/validation'
  import { appInfo } from '../lib/appInfo'

  const record = {
    text: '',
    photoUrl: null,
    accepted: false
  }
  let emailSending = false
  let fieldErrors = {}
  let imageUploading = false

  async function send () {
    fieldErrors = {}
    applyRequired(record, fieldErrors, ['text'])
    if (!record.accepted) fieldErrors.accepted = 'Dieser Hinweis muss durch Anhaken der Checkbox bestätigt werden.'
    if (Object.keys(fieldErrors).length) {
      Toast.create({ message: 'Die Angaben sind ungültig oder unvollständig!', type: 'is-danger' })
      return
    }

    emailSending = true
    try {
      const result = await apiCall('GET', '/auditLogs', {
        order: '-date',
        select: 'id',
        filter: JSON.stringify({ auditObject: auditObject.id }),
        limit: 1
      })

      record.type = 'serviceRequest'
      record.user = appInfo.user.id
      record.auditObject = auditObject.id
      record.auditLog = result[0]?.id

      await apiCall('POST', '/serviceLogs', record)
      Toast.create({ message: 'Service-Anforderung wurde versendet!', type: 'is-success' })
      onSave(record)
      closeSelf()
    } catch (e) {
      console.error(e)
      Dialog.alert({ message: escape(e.serverErrorMessage || `Beim Senden der Anforderung ist ein Fehler aufgetreten - bitte erneut versuchen!\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
    }
    emailSending = false
  }
</script>

<Modal {title} closeable on:close={closeSelf}>
  <form on:submit|preventDefault={() => send()}>
    <fieldset disabled={emailSending}>
      <div class="columns">
        <FormField label="Anfragetext" type="textarea" col=12 bind:value={record.text} error={fieldErrors.text} />
      </div>

      <div class="columns">
        <FileField label="Foto-Anhang (optional)" icon="image" col=12 bind:value={record.photoUrl} error={fieldErrors.photoUrl} bind:loading={imageUploading} />
      </div>

      <div class="columns">
        <Field type={fieldErrors.accepted ? 'is-danger' : ''} message={fieldErrors.accepted} class="column is-12">
          <label class="checkbox">
            <input type="checkbox" bind:checked={record.accepted}>
            Ich möchte hiermit rechtsverbindlich die beschriebene Dienstleistung in Auftrag geben und bin mir bewusst, dass dafür Kosten anfallen können.
          </label>
        </Field>
      </div>
    </fieldset>
  </form>

  <svelte:fragment slot="footer">
    <Button type="is-primary" on:click={send} loading={emailSending} disabled={imageUploading}>Absenden</Button>
    <Button on:click={closeSelf}>Abbrechen</Button>
  </svelte:fragment>
</Modal>
