<script>
  export let closeSelf
  export let onSave = () => {}
  export let type

  import ResourceForm from '../components/ResourceForm.svelte'
  import { cleanErrorObject, trimFields } from '../lib/validation'
  import FormField from '../components/FormField.svelte'
  import StringList from '../components/StringList.svelte'
  import auditObjectTypes from '../lib/auditObjectTypes'
  import { Icon, Button, Notification, Toast, Input, Field } from 'svelma'
  import { download, generateRandomString } from '../lib/utils'
  import { OVERVIEW_PHOTO_GROUP, getAllGroups, getActualLabel, getPreparedAuditQuestions } from '../lib/auditQuestions'
  import { convert as toRomanNumeral } from 'roman-numeral'
  import { intToExcelCol as toExcelColumn } from 'excel-column-name'
  import clipboard from '../stores/clipboard'
  import { open } from '../stores/viewStack'
  import ViewAuditQuestionsPreview from './ViewAuditQuestionsPreview.svelte'
  import Dropdown from '../components/Dropdown.svelte'
  import { appInfo } from '../lib/appInfo'

  let record
  let fieldErrors = {}
  let formElement
  let validate
  let activeId = null

  $: groups = getAllGroups(record)
  $: groupsWithoutOverviewPhoto = groups.filter(g => g.label !== OVERVIEW_PHOTO_GROUP)

  const typeName = auditObjectTypes[type]

  if (!typeName) throw new Error(`Bad audit object type ${type}`)

  function ensureTmpIds (record) {
    // Note: _tmpId is used to just temporarily identify something for its error and for keying in Svelte. It doesn't exist in the DB.
    // Also generate empty questions in case of dangling references to avoid crashes in UI
    for (const row of record.layoutRows) {
      row._tmpId = generateRandomString(6)
      for (const column of row.columns) {
        column._tmpId = generateRandomString(6)
        for (const group of column.groups) {
          group._tmpId = generateRandomString(6)
          for (const questionId of group.questions) {
            if (!record.questions[questionId]) record.questions[questionId] = { label: '', description: '', yellowPresets: [], redPresets: [], photoOptional: false }
          }
        }
      }
    }

    return record
  }

  function getGroupNumber (groupObject) {
    return groups.indexOf(groupObject) + 1
  }

  function getFullQuestionNumber (questionId) { // incl. group number
    const group = groups.find(g => g.questions?.includes(questionId))
    const groupNumber = groups.indexOf(group) + 1
    const questionNumber = (group.questions?.indexOf(questionId) ?? 0) + 1
    return `${groupNumber}.${questionNumber}`
  }

  function findGroup (group) {
    const rowIndex = record.layoutRows.findIndex(r => r.columns.some(c => c.groups.includes(group)))
    const row = record.layoutRows[rowIndex]
    const columnIndex = row?.columns.findIndex(c => c.groups.includes(group))
    const column = row?.columns?.[columnIndex]
    const groupIndex = column?.groups?.indexOf(group)
    return { row, rowIndex, column, columnIndex, groupIndex }
  }

  function findQuestion (questionId) {
    const groupIndex = groupsWithoutOverviewPhoto.findIndex(g => g.questions.includes(questionId))
    const group = groupsWithoutOverviewPhoto[groupIndex]
    const questionIndex = group.questions.indexOf(questionId)
    return { group, groupIndex, questionIndex }
  }

  function invalidate () {
    record = record
  }

  function setActiveId (value) {
    activeId = value

    if (activeId) {
      setTimeout(() => {
        const activeItem = formElement.querySelector('.item.is-active')
        if (activeItem) {
          activeItem.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
          activeItem.querySelector('input')?.focus()
        }
      }, 100)
    }
  }

  function editNothing () {
    setActiveId(null)
  }

  function addGroup (column) {
    const group = { label: '', questions: [], _tmpId: generateRandomString(6) }
    column.groups.push(group)
    invalidate()
    editGroup(group)
  }

  function pasteGroup (column) {
    if ($clipboard?.type !== 'group') throw new Error('No group in clipboard')
    const data = JSON.parse(JSON.stringify($clipboard.content))

    const group = { label: data.label, questions: [], _tmpId: generateRandomString(6) }
    for (const question of data.questions) {
      const id = generateRandomString(6)
      record.questions[id] = question
      group.questions.push(id)
    }

    column.groups.push(group)
    invalidate()
    editGroup(group)
  }

  function editGroup (group) {
    setActiveId(group._tmpId)
  }

  function copyGroup (group) {
    const data = {
      label: group.label,
      questions: group.questions.map(id => record.questions[id])
    }

    $clipboard = {
      type: 'group',
      content: JSON.parse(JSON.stringify(data))
    }

    Toast.create({ message: 'Gruppe in ERP-Zwischenablage kopiert!', type: 'is-info' })
  }

  function moveGroupUp (group) {
    editNothing()

    const { row, rowIndex, column, columnIndex, groupIndex } = findGroup(group)

    if (groupIndex > 0) {
      // Move group within column
      column.groups.splice(groupIndex - 1, 2, group, column.groups[groupIndex - 1])
    } else if (column.groups.length > 1) {
      // Move group to new column
      column.groups.splice(groupIndex, 1)
      row.columns.splice(columnIndex - 1, 0, { _tmpId: generateRandomString(6), groups: [group] })
    } else if (columnIndex > 0) {
      // Move group to previous column
      row.columns.splice(columnIndex, 1)
      row.columns[columnIndex - 1].groups.push(group)
    } else if (row.columns.length > 1) {
      // Move column to new row
      row.columns.splice(columnIndex, 1)
      record.layoutRows.splice(rowIndex - 1, 0, { _tmpId: generateRandomString(6), columns: [column] })
    } else if (rowIndex > 0) {
      // Move column to previous row
      record.layoutRows.splice(rowIndex, 1)
      record.layoutRows[rowIndex - 1].columns.push(column)
    } else {
      throw new Error('Cannot move further')
    }

    invalidate()
  }

  function moveGroupDown (group) {
    editNothing()

    const { row, rowIndex, column, columnIndex, groupIndex } = findGroup(group)

    if (groupIndex < column.groups.length - 1) {
      // Move group within column
      column.groups.splice(groupIndex, 2, column.groups[groupIndex + 1], group)
    } else if (column.groups.length > 1) {
      // Move group to new column
      column.groups.splice(groupIndex, 1)
      row.columns.splice(columnIndex + 1, 0, { _tmpId: generateRandomString(6), groups: [group] })
    } else if (columnIndex < row.columns.length - 1) {
      // Move group to next column
      row.columns.splice(columnIndex, 1)
      row.columns[columnIndex].groups.unshift(group)
    } else if (row.columns.length > 1) {
      // Move column to new row
      row.columns.splice(columnIndex, 1)
      record.layoutRows.splice(rowIndex + 1, 0, { _tmpId: generateRandomString(6), columns: [column] })
    } else if (rowIndex < record.layoutRows.length - 1) {
      // Move column to next row
      record.layoutRows.splice(rowIndex, 1)
      record.layoutRows[rowIndex].columns.unshift(column)
    } else {
      throw new Error('Cannot move further')
    }

    invalidate()
  }

  function deleteGroup (group) {
    const row = record.layoutRows.find(r => r.columns.some(c => c.groups.includes(group)))
    const column = row?.columns.find(c => c.groups.includes(group))

    column.groups = column.groups.filter(g => g !== group)

    for (const questionId of group.questions) delete record.questions[questionId]

    if (column.groups.length === 0) row.columns = row.columns.filter(c => c !== column)
    if (row.columns.length === 0) record.layoutRows = record.layoutRows.filter(r => r !== row)

    invalidate()
    editNothing()
  }

  function addQuestion (group) {
    if (group.label === OVERVIEW_PHOTO_GROUP) throw new Error('Cannot add question to overview photo group')

    const questionId = generateRandomString(6)
    const question = {
      label: '',
      description: '',
      yellowPresets: [],
      redPresets: [],
      photoOptional: false
    }

    record.questions[questionId] = question
    group.questions.push(questionId)
    invalidate()
    editQuestion(questionId)
  }

  function pasteQuestion (group) {
    if ($clipboard?.type !== 'question') throw new Error('No question in clipboard')
    if (group.label === OVERVIEW_PHOTO_GROUP) throw new Error('Cannot add question to overview photo group')
    const question = JSON.parse(JSON.stringify($clipboard.content))
    const questionId = generateRandomString(6)

    record.questions[questionId] = question
    group.questions.push(questionId)
    invalidate()
    editQuestion(questionId)
  }

  function editQuestion (questionId) {
    setActiveId(questionId)
  }

  function copyQuestion (questionId) {
    const question = { ...record.questions[questionId] }

    $clipboard = {
      type: 'question',
      content: JSON.parse(JSON.stringify(question))
    }

    Toast.create({ message: 'Kriterum in ERP-Zwischenablage kopiert!', type: 'is-info' })
  }

  function moveQuestionUp (questionId) {
    editNothing()

    const { group, groupIndex, questionIndex } = findQuestion(questionId)

    if (questionIndex > 0) {
      // Move question within group
      group.questions.splice(questionIndex - 1, 2, questionId, group.questions[questionIndex - 1])
    } else {
      // Move to previous group
      if (groupIndex > 0) {
        group.questions.splice(questionIndex, 1)
        groupsWithoutOverviewPhoto[groupIndex - 1].questions.push(questionId)
      } else {
        throw new Error('Cannot move further')
      }
    }

    invalidate()
  }

  function moveQuestionDown (questionId) {
    editNothing()

    const { group, groupIndex, questionIndex } = findQuestion(questionId)

    if (questionIndex < group.questions.length - 1) {
      // Move question within group
      group.questions.splice(questionIndex, 2, group.questions[questionIndex + 1], questionId)
    } else {
      // Move to next group
      if (groupIndex < groupsWithoutOverviewPhoto.length - 1) {
        group.questions.splice(questionIndex, 1)
        groupsWithoutOverviewPhoto[groupIndex + 1].questions.unshift(questionId)
      } else {
        throw new Error('Cannot move further')
      }
    }

    invalidate()
  }

  function deleteQuestion (questionId) {
    const group = groups.find(g => g.questions.includes(questionId))
    if (!group) return

    group.questions = group.questions.filter(id => id !== questionId)
    delete record.questions[questionId]

    invalidate()
    editNothing()
  }

  function bodyClick (event) {
    if (!event.target.closest('.item.is-active, .question-content')) editNothing()
  }

  function preview () {
    const preparedData = getPreparedAuditQuestions(record)
    open(ViewAuditQuestionsPreview, { preparedData })
  }

  async function exportRecord () {
    await download(JSON.stringify(record), 'application/json', `auditQuestions_${type}.json`)
  }

  let fileElement
  function importRecord () {
    fileElement.value = ''
    fileElement.click()
  }

  function handleUpload () {
    if (!fileElement.files.length) return

    const fileReader = new FileReader()
    fileReader.onload = event => {
      const data = JSON.parse(event.target.result)
      if (!data.layoutRows || !data.questions) throw new Error('Invalid import file')

      record = ensureTmpIds(data)
      fieldErrors = {}
      editNothing()
    }
    fileReader.readAsText(fileElement.files[0])
  }
</script>

<style lang="scss">
  .columns {
    margin-bottom: 0 !important;
  }

  .row-list {
    border-bottom: 1px solid $border;

    .item {
      border-top: 1px solid $border;
      padding: 0.2em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 3em;

      .item-buttons {
        text-align: right;

        & > span {
          display: inline-block;
        }
      }

      @media (max-width: 768px) {
        flex-wrap: wrap;

        & > * {
          flex: 0 0 100%;
        }

        .item-buttons > span {
          margin-top: 5px;
        }
      }

      @media not all and (hover: none) {
        &:hover {
          background-color: #fbfbfb;

          &.selectable {
            background-color: whitesmoke;
            cursor: pointer;
          }
        }
      }

      &.selectable.is-active {
        font-weight: bold;
        position: sticky;
        top: -20px;
        z-index: 5;

        @media not all and (hover: none) {
          &:not(:hover) {
            background-color: #f8f8f8;
          }
        }

        @media (hover: none) {
          background-color: #f8f8f8;
        }

        & > span > :global(span.icon) {
          color: $link;
        }
      }

      .item-label {
        flex: 1;
        padding: 0 0.5em;
      }
    }


    .q-row {
      .item { padding-left: 0.2em; }

      .q-column {
        .item { padding-left: 1.2em; }

        .q-group {
          .item { padding-left: 2.2em; }

          .q-question {
            .item { padding-left: 3.2em; }

            .question-content {
              padding-left: 4.2em;
              padding-top: 0.5em;
              padding-bottom: 0.5em;
            }
          }
        }
      }
    }
  }
</style>

<svelte:body on:click={bodyClick} />

<ResourceForm
  {closeSelf} {onSave}
  id="auditQuestions/{type}"
  hideId
  title="Prüfkriterien für {typeName}"
  handleLoad={async (id, record, create, parentId) => {
    return ensureTmpIds(record)
  }}
  validateRecord={async record => {
    editNothing()

    const fieldErrors = {
      groups: {},
      questions: {}
    }

    const groups = getAllGroups(record)

    // These things should normally not happen anyway, but we check it anyway just to make sure - in case some sort
    // of bug or edge case allows it to happen, we at least don't want to save that mess.
    const overviewPhotoGroups = groups.filter(g => g.label === OVERVIEW_PHOTO_GROUP)
    if (overviewPhotoGroups.length !== 1) {
      fieldErrors._overall = 'Überblicksfoto muss genau einmal existieren (BUG)'
    } else if (overviewPhotoGroups[0].questions?.length) {
      fieldErrors._overall = 'Überblicksfoto-Gruppe kann keine Fragen enthalten (BUG)'
    }

    // Drop invalid question references
    for (const group of groups) {
      group.questions = group.questions.filter(id => record.questions[id])
    }

    // Check for bad groups
    for (const group of groups) {
      if (!group.questions?.length && group.label !== OVERVIEW_PHOTO_GROUP) {
        fieldErrors.groups[(group._tmpId = group._tmpId ?? generateRandomString(6))] = 'Gruppe kann nicht leer sein'
      }

      group.label = group.label?.trim()

      if (!group.label) {
        fieldErrors.groups[(group._tmpId = group._tmpId ?? generateRandomString(6))] = 'Gruppe muss einen Namen haben'
      }
    }

    // Drop empty columns
    for (const row of record.layoutRows) {
      row.columns = row.columns.filter(c => c.groups.length)
    }

    // Drop empty rows
    record.layoutRows = record.layoutRows.filter(r => r.columns.length)

    // Drop orphaned questions, sanitize presets and check for bad ones
    for (const id in record.questions) {
      if (!groups.some(g => g.questions.includes(id))) {
        delete record.questions[id]
      } else {
        trimFields(record.questions[id], ['label', 'description'])
        record.questions[id].yellowPresets = record.questions[id].yellowPresets.map(p => p.trim()).filter(p => p)
        record.questions[id].redPresets = record.questions[id].redPresets.map(p => p.trim()).filter(p => p)
        if (!record.questions[id].label) fieldErrors.questions[id] = 'Kriterium muss einen Namen haben'
      }
    }

    cleanErrorObject(fieldErrors)

    return { record, fieldErrors }
  }}
  handleSave={async record => {
    return ensureTmpIds(record)
  }}
  resourcePath="/settings"
  resourceName="Einstellungen"
  readonly={!appInfo.user.admin}
  bind:validate
  bind:record
  bind:fieldErrors
  bind:formElement
>
  {#if fieldErrors._overall}
    <Notification type="is-danger" showClose={false}>
      {fieldErrors._overall}
    </Notification>
  {/if}

  <div class="row-list" on:keydown={e => { if (e.key === 'Enter') { editNothing(); e.preventDefault() } }}>
    {#each Object.entries(record.layoutRows) as [rowIndex, row] (row._tmpId)}
      <div class="q-row">
        <div class="item">
          <span><Icon icon="grip-horizontal" /> Zeile {toRomanNumeral(Number(rowIndex) + 1)}</span>
        </div>
        {#each Object.entries(row.columns) as [columnIndex, column] (column._tmpId)}
          <div class="q-column">
            <div class="item">
              <span><Icon icon="grip-vertical" /> Spalte {toRomanNumeral(Number(rowIndex) + 1)}.{toExcelColumn(Number(columnIndex) + 1)}</span>
            </div>
            {#each column.groups as group (group._tmpId)}
              <div class="q-group">
                <div class="item" class:selectable={group.label !== OVERVIEW_PHOTO_GROUP} on:click|stopPropagation={() => editGroup(group)} class:is-active={activeId === group._tmpId} class:has-background-danger-light={fieldErrors.groups?.[group._tmpId]}>
                  <span>
                    <Icon icon={group.label === OVERVIEW_PHOTO_GROUP ? 'image' : 'tasks'} /> Gruppe {getGroupNumber(group)}:
                  </span>
                  <span class="item-label">
                    {#if group.label !== OVERVIEW_PHOTO_GROUP && activeId === group._tmpId}
                      <Input expanded bind:value={group.label} placeholder="(unbenannt)" />
                    {:else}
                      {getActualLabel(group.label)}
                      {#if fieldErrors.groups?.[group._tmpId]}
                        <span class="has-text-danger"><Icon icon="exclamation-circle" customClass="has-text-danger" /> {fieldErrors.groups?.[group._tmpId]}</span>
                      {/if}
                    {/if}
                  </span>
                  <span class="item-buttons">
                    <span on:click|stopPropagation={() => {}}>
                      <Button size="is-small" outlined type="is-primary" on:click={() => editGroup(group)} title="Bearbeiten" disabled={group.label === OVERVIEW_PHOTO_GROUP}><Icon icon="pen" /></Button>
                      <Button size="is-small" outlined type="is-info" on:click={() => copyGroup(group)} title="Kopieren" disabled={group.label === OVERVIEW_PHOTO_GROUP}><Icon icon="copy" /></Button>
                      <Button size="is-small" outlined type="is-dark" on:click={() => moveGroupUp(group)} title="Nach oben bewegen" disabled={group === groups[0] && column.groups.length === 1 && row.columns.length === 1}><Icon icon="arrow-up" /></Button>
                      <Button size="is-small" outlined type="is-dark" on:click={() => moveGroupDown(group)} title="Nach unten bewegen" disabled={group === groups[groups.length - 1] && column.groups.length === 1 && row.columns.length === 1}><Icon icon="arrow-down" /></Button>
                      <Button size="is-small" outlined type="is-danger" on:click={() => deleteGroup(group)} title="Löschen" disabled={group.label === OVERVIEW_PHOTO_GROUP}><Icon icon="trash" /></Button>
                    </span>
                  </span>
                </div>
                {#each group.questions as questionId (questionId)}
                  <div class="q-question">
                    <div class="item selectable" on:click|stopPropagation={() => editQuestion(questionId)} class:is-active={activeId === questionId} class:has-background-danger-light={fieldErrors.questions?.[questionId]}>
                      <span>
                        <Icon icon="clipboard-check" /> Kriterium {getFullQuestionNumber(questionId)}:
                      </span>
                      <span class="item-label">
                        {#if activeId === questionId}
                          <Input expanded bind:value={record.questions[questionId].label} placeholder="(unbenannt)" />
                        {:else}
                          {getActualLabel(record.questions[questionId]?.label)}
                          {#if fieldErrors.questions?.[questionId]}
                            <span class="has-text-danger"><Icon icon="exclamation-circle" customClass="has-text-danger" /> {fieldErrors.questions?.[questionId]}</span>
                          {/if}
                        {/if}
                      </span>
                      <span class="item-buttons">
                        <span on:click|stopPropagation={() => {}}>
                          <Button size="is-small" outlined type="is-primary" on:click={() => editQuestion(questionId)} title="Bearbeiten"><Icon icon="pen" /></Button>
                          <Button size="is-small" outlined type="is-info" on:click={() => copyQuestion(questionId)} title="Kopieren"><Icon icon="copy" /></Button>
                          <Button size="is-small" outlined type="is-dark" on:click={() => moveQuestionUp(questionId)} title="Nach oben bewegen" disabled={group === groupsWithoutOverviewPhoto[0] && questionId === group.questions[0]}><Icon icon="arrow-up" /></Button>
                          <Button size="is-small" outlined type="is-dark" on:click={() => moveQuestionDown(questionId)} title="Nach unten bewegen" disabled={group === groupsWithoutOverviewPhoto[groupsWithoutOverviewPhoto.length - 1] && questionId === group.questions[group.questions.length - 1]}><Icon icon="arrow-down" /></Button>
                          <Button size="is-small" outlined type="is-danger" on:click={() => deleteQuestion(questionId)} title="Löschen"><Icon icon="trash" /></Button>
                        </span>
                      </span>
                    </div>

                    {#if activeId === questionId}
                      <div class="question-content">
                        <div class="columns">
                          <FormField label="Beschreibung für Mitarbeiter" type="textarea" col=12 bind:value={record.questions[questionId].description} />
                        </div>
                        <div class="columns">
                          <StringList label="Vorschläge Gelb" icon="comment-dots" col=6 bind:value={record.questions[questionId].yellowPresets} />
                          <StringList label="Vorschläge Rot" icon="comment-dots" col=6 bind:value={record.questions[questionId].redPresets} />
                        </div>
                        <div class="columns">
                          <div class="column is-6"></div>
                          <Field class="column is-6">
                            <label class="checkbox">
                              <input type="checkbox" bind:checked={record.questions[questionId].photoOptional}>
                              Foto bei Rot ist optional
                            </label>
                          </Field>
                        </div>
                      </div>
                    {/if}

                  </div>
                {/each}

                {#if group.label !== OVERVIEW_PHOTO_GROUP}
                  <div class="q-question new">
                    <div class="item selectable" on:click|stopPropagation={() => addQuestion(group)}>
                      <span><Icon icon="plus" /> Neues Prüfkriterium</span>
                      <span class="item-buttons">
                        <span on:click|stopPropagation={() => {}}>
                          <Button size="is-small" outlined type="is-primary" on:click={() => addQuestion(group)} title="Hinzufügen"><Icon icon="plus" /></Button>
                          <Button size="is-small" outlined type="is-info" on:click={() => pasteQuestion(group)} title="Einfügen" disabled={$clipboard?.type !== 'question'}><Icon icon="paste" /></Button>
                        </span>
                      </span>
                    </div>
                  </div>
                {/if}
              </div>
            {/each}

            <div class="q-group new">
              <div class="item selectable" on:click|stopPropagation={() => addGroup(column)}>
                <span><Icon icon="plus" /> Neue Gruppe</span>
                <span class="item-buttons">
                  <span on:click|stopPropagation={() => {}}>
                    <Button size="is-small" outlined type="is-primary" on:click={() => addGroup(column)} title="Hinzufügen"><Icon icon="plus" /></Button>
                    <Button size="is-small" outlined type="is-info" on:click={() => pasteGroup(column)} title="Einfügen" disabled={$clipboard?.type !== 'group'}><Icon icon="paste" /></Button>
                  </span>
                </span>
              </div>
            </div>
          </div>
        {/each}
      </div>
    {/each}
  </div>

  <div slot="buttons" class="left-buttons" let:ready>
    <Button type="is-info" class="is-hidden-mobile" on:click={preview} disabled={!ready}>Vorschau</Button>
    <Button type="is-default" class="is-hidden-mobile" on:click={exportRecord} disabled={!ready}>Export</Button>
    <Button type="is-default" class="is-hidden-mobile" on:click={importRecord} disabled={!ready}>Import</Button>

    <Dropdown forceClose={!ready} class="is-hidden-tablet is-up">
      <Button slot="trigger" class="no-min-width" type="is-default" disabled={!ready}><Icon icon="ellipsis-h" /></Button>

      <a href={undefined} class="dropdown-item has-text-info" on:click={preview}>Vorschau</a>
      <a href={undefined} class="dropdown-item" on:click={exportRecord}>Export</a>
      <a href={undefined} class="dropdown-item" on:click={importRecord}>Import</a>
    </Dropdown>

    <input type="file" style="display: none;" bind:this={fileElement} on:change={handleUpload} accept="*.json" />
  </div>
</ResourceForm>
