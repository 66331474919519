'use strict'

class SelectOptions extends Array {
  selectOptions (withUnknown = true) {
    return (withUnknown ? [[null, 'Unbekannt']] : []).concat(this.map(v => [v, v]))
  }
}

export const fireClasses = new SelectOptions(
  'T0',
  'EI2-30-C',
  'EI2-60-C',
  'EI2-90-C',
  'E30-C',
  'E60-C',
  'T30',
  'T60',
  'T90',
  'R30',
  'R60',
  'Sonstige'
)

export const doorVariations = new SelectOptions(
  'Geschlossen',
  'Gelocht',
  'Teilverglast mit Sichtfenster',
  'Gänzlich verglast',
  'Sonstige'
)

export const gateVariations = new SelectOptions(
  'Geschlossen',
  'Gelocht',
  'Teilverglast mit Sichtfenster',
  'Gänzlich verglast',
  'Sonstige'
)

export const doorTypes = new SelectOptions(
  'Automatische Drehtüre',
  'Automatische Schiebetüre',
  'Sonstige'
)

export const gateTypes = new SelectOptions(
  'Rolltor',
  'Rollgitter',
  'Sektionaltor',
  'Schiebetor',
  'Flügeltor',
  'Seitensektionaltor',
  'Schnelllauftor',
  'Kipptor'
)

export const numberOfWingsOptions = new SelectOptions(
  'Einflügelig',
  'Zweiflügelig',
  'Mehrflügelig'
)
