<script>
  export let closeSelf
  export let onSave = () => {}

  import ResourceForm from '../components/ResourceForm.svelte'
  import { applyRequired, trimFields } from '../lib/validation'
  import FormField from '../components/FormField.svelte'
  import SwitchField from '../components/SwitchField.svelte'
  import { Button, Field, Dialog, Toast } from 'svelma'
  import { escape } from '../lib/utils'
  import { apiCall } from '../lib/api'
  import { appInfo } from '../lib/appInfo'

  let record
  let fieldErrors = {}

  let emailSending = false

  async function testEmail () {
    const to = await Dialog.prompt({
      message: 'E-Mail-Adressat für Test-Mail eingeben',
      showCancel: true,
      prompt: record.auditCompletionNotificationRecipient || record.customerServiceRequestRecipient,
      confirmText: 'Senden',
      cancelText: 'Abbrechen',
      type: 'is-info'
    })

    if (to) {
      emailSending = true
      try {
        await apiCall('POST', '/settings/testEmail', { to, ...record })
        Toast.create({ message: 'Test-E-Mail wurde versendet!', type: 'is-success' })
      } catch (e) {
        console.error(e)
        Dialog.alert({ message: escape(e.serverErrorMessage || `Beim Senden der Test-E-Mail ist ein Fehler aufgetreten - bitte Einstellungen überprüfen!\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
      }
      emailSending = false
    }
  }
</script>

<ResourceForm
  {closeSelf} {onSave}
  id='~'
  title="Allgemeine Einstellungen"
  select="-auditQuestions,-barcodePrint,-auditObjectTypeSpecificSettings,-generalAuditNotes"
  validateRecord={async record => {
    const fieldErrors = {}

    applyRequired(record, fieldErrors, [
      'companyAddress',
      'companyEmail',
      'companyWebsite'
    ])

    trimFields(record, [
      'auditCompletionNotificationRecipient',
      'customerServiceRequestRecipient',
      'smtpHost',
      'smtpFrom',
      'smtpUser',
      'smtpPassword'
    ])

    return { record, fieldErrors }
  }}
  resourcePath="/settings"
  resourceName="Einstellungen"
  readonly={!appInfo.user.admin}
  hideId
  bind:record
  bind:fieldErrors
>
  <div class="columns">
    <FormField label="Firmenadresse" type="textarea" col=12 bind:value={record.companyAddress} error={fieldErrors.companyAddress} />
  </div>

  <div class="columns">
    <FormField label="Firmen-E-Mail" icon="at" col=6 bind:value={record.companyEmail} error={fieldErrors.companyEmail} />
    <FormField label="Firmen-Webseite" icon="globe-americas" col=6 bind:value={record.companyWebsite} error={fieldErrors.companyWebsite} />
  </div>

  <div class="columns">
    <FormField label="Empfänger für E-Mail-Benachrichtigung bei Prüftermin-Abschluss" icon="at" col=12 bind:value={record.auditCompletionNotificationRecipient} error={fieldErrors.auditCompletionNotificationRecipient} />
  </div>

  <div class="columns">
    <FormField label="Empfänger für E-Mail-Benachrichtigung bei Kunden-Service-Anfrage" icon="at" col=12 bind:value={record.customerServiceRequestRecipient} error={fieldErrors.customerServiceRequestRecipient} />
  </div>

  <hr />

  <div class="columns">
    <FormField label="SMTP-Hostname" icon="server" col=5 bind:value={record.smtpHost} error={fieldErrors.smtpHost} />
    <FormField label="SMTP-Absender" icon="at" col=5 bind:value={record.smtpFrom} error={fieldErrors.smtpFrom} />
    <SwitchField label="SMTP via SSL" col=2 bind:value={record.smtpSsl} error={fieldErrors.smtpSsl} />
  </div>

  <div class="columns">
    <FormField label="SMTP-Benutzername" icon="user" col=5 bind:value={record.smtpUser} error={fieldErrors.smtpUser} />
    <FormField label="SMTP-Passwort" icon="star-of-life" col=5 autocomplete="new-password" type="password" passwordReveal bind:value={record.smtpPassword} error={fieldErrors.smtpPassword} />
    <Field label="Test" class="column is-2">
      <Button type="is-info" on:click={testEmail} loading={emailSending}>Test</Button>
    </Field>
  </div>
</ResourceForm>
