<script>
  export let label = null
  export let icon = null
  export let value = null
  export let error = null
  export let hint = null
  export let col = null
  export let readonly = false
  export let disabled = false

  import { Field, Input, Button, Icon } from 'svelma'
  import { open } from '../stores/viewStack'
  import ViewBarcodeScanner from '../views/ViewBarcodeScanner.svelte'

  $: if (value === undefined) value = null

  async function scanForBarcode () {
    open(ViewBarcodeScanner, {
      onScan: code => {
        if (!readonly && !disabled) value = code
      }
    })
  }
</script>

{#key error}
  <Field {label} type={error ? 'is-danger' : ''} message={error || hint} class="{$$props.class ?? ''} {col ? `column is-${col}` : ''}">
    <div class="field-body">
      <div class="field has-addons">
        <Input bind:value {icon} class={error ? 'is-danger' : ''} expanded {...readonly ? { readonly: true } : {}} {...disabled ? { disabled: true } : {}} on:focus />
        <div class="control"><Button nativeType="button" on:click={scanForBarcode} disabled={readonly || disabled} title="QR-/Barcode scannen"><Icon icon="qrcode" /></Button></div>
      </div>
    </div>
  </Field>
{/key}
