<script>
  export let closeSelf
  export let onSave = () => {}
  export let create = false
  export let id = null
  export let parentId = null
  export let title
  export let embeddedRecord = null

  import ResourceForm from '../components/ResourceForm.svelte'
  import { apiCall } from '../lib/api'
  import { appInfo } from '../lib/appInfo'
  import { applyRequired, trimFields } from '../lib/validation'
  import FormField from '../components/FormField.svelte'
  import SwitchField from '../components/SwitchField.svelte'
  import SelectField from '../components/SelectField.svelte'
  import { Dialog } from 'svelma'
  import TypePreSelector from '../components/TypePreSelector.svelte'
  import auditObjectTypes from '../lib/auditObjectTypes'
  import { fireClasses, doorVariations, gateVariations, doorTypes, gateTypes, numberOfWingsOptions } from '../lib/auditObjectEnums'
  import { escape } from '../lib/utils'
  import BarcodeField from '../components/BarcodeField.svelte'
  import { PREFIXES } from '../lib/publicIdPrefixes'

  let record
  let fieldErrors = {}
  let propertyName = '...'
  let customerName = '...'

  $: if (record) {
    const str = (record._idSuffix || '0').split('.').map(x => x.padStart(4, '0')).join('.')
    record.idNumber = Number(str.replace(/\./g, (_, index) => str.indexOf('.') === index ? '.' : ''))
  }
  $: if (record) record.publicId = (record.type && record._idSuffix) ? `${PREFIXES[record.type] ?? '?'}-${record._idSuffix}` : null
</script>

<ResourceForm
  {closeSelf} {onSave} {create} {id} {parentId} {title}
  getTitle={record => embeddedRecord ? `Lokale Daten für ${record.publicId || 'Prüfkörper'}` : ((record.publicId || record.manufacturer) ? `${record.publicId ?? '???'}: ${record.manufacturer ?? ''} ${record.serialNo ?? ''} ${!record.manufacturer && !record.serialNo ? 'Unbekannt' : ''}` : undefined)}
  parentIdField="property"
  initRecord={async parentId => {
    const [highestRec] = await apiCall('GET', '/auditObjects', { order: '-idNumber', select: 'idNumber', filter: JSON.stringify({ property: parentId }), limit: 1 })
    const idNumber = Math.floor(highestRec?.idNumber ?? 0) + 1
    return {
      property: parentId,
      publicId: `?-${idNumber}`,
      idNumber,
      decommissioned: false
    }
  }}
  handleLoad={async (id, record, create, parentId) => {
    if (embeddedRecord) record = JSON.parse(JSON.stringify(embeddedRecord))
    // The following happens regardless of whether it's an embeddedRecord or not
    record._idSuffix = record.publicId?.replace(/^.*?-/, '')
    if (embeddedRecord) return record

    try {
      if (record.property) {
        const property = await apiCall('GET', `/properties/${record.property}`, { select: 'name,customer', populate: JSON.stringify([['customer', 'company,customerNo']]) })
        propertyName = property.name
        customerName = property.customer ? `${property.customer.company} (#${property.customer.customerNo})` : null
      } else {
        propertyName = null
        customerName = null
      }
    } catch (e) {
      console.error(`Failed to load name for ${record.property}`, e)
      propertyName = record.property
      customerName = '?'
    }
  }}
  validateRecord={async record => {
    const fieldErrors = {}

    applyRequired(record, fieldErrors, [
      'type',
      'publicId'
    ])

    trimFields(record, [
      'manufacturer',
      'serialNo',
      'barcode',
      'floor',
      'engine',
      'controller',
      'springTension',
      'commander',
      'notes'
    ])

    let cancel = false

    if (!Object.keys(fieldErrors).length) {
      const fieldsToCheck = {
        manufacturer: 'Hersteller',
        serialNo: 'Seriennummer',
        year: 'Baujahr',
        ...record.type === 'door'
          ? { doorVariation: 'Türblattausführung' }
          : {},
        ...record.type === 'gate'
          ? { gateVariation: 'Torblattausführung' }
          : {},
        ...record.type === 'fireDoor' || record.type === 'fireGate'
          ? { fireClass: 'Klassifikation' }
          : {},
        ...['door', 'gate', 'fireDoor', 'fireGate'].includes(record.type)
          ? {
            clearPassageWidth: 'Lichte Durchgangsbreite',
            clearPassageHeight: 'Lichte Durchgangshöhe'
          }
          : {},
        ...record.type === 'door'
          ? { doorType: 'Türtyp' }
          : {},
        ...record.type === 'gate'
          ? { gateType: 'Tortyp' }
          : {},
        ...record.type === 'door' || record.type === 'gate' || record.type === 'boomBarrier'
          ? {
            engine: 'Motor',
            controller: 'Steuerung'
          }
          : {},
        ...['door', 'fireDoor', 'fireGate'].includes(record.type)
          ? { numberOfWings: 'Flügelanzahl' }
          : {},
        ...record.type === 'boomBarrier'
          ? {
            weight: 'Gewicht',
            barLength: 'Baumlänge'
          }
          : {},
        ...record.type === 'gate' || record.type === 'boomBarrier'
          ? { springTension: 'Federspannung' }
          : {},
        ...record.type === 'door' || record.type === 'gate' || record.type === 'boomBarrier'
          ? { commander: 'Befehlsgeber der Anlage' }
          : {}
      }

      const emptyFields = Object.entries(fieldsToCheck).filter(([key]) => !record[key]).map(([key, name]) => name)

      if (emptyFields.length) {
        cancel = !await Dialog.confirm({
          message: `Die folgenden Felder sind leer und werden als unbekannt gespeichert, ist das korrekt?<br><br><ul>${emptyFields.map(n => `<li>&bull; ${escape(n)}</li>`).join('')}</ul>`,
          confirmText: 'Ja',
          cancelText: 'Nein',
          type: 'is-warning'
        })
      }
    }

    return { record, fieldErrors, cancel }
  }}
  handleSave={async record => {
    if (embeddedRecord) embeddedRecord = JSON.parse(JSON.stringify(record))
  }}
  resourcePath="/auditObjects"
  resourceName="Prüfkörper"
  resourceGender="m"
  readonly={!appInfo.user.admin}
  hideId={appInfo.user.isCustomer}
  saveEnabled={!!record?.type}
  bind:record
  bind:fieldErrors
  saveLabel={embeddedRecord ? 'OK' : undefined}
>
  <TypePreSelector types={auditObjectTypes} bind:value={record.type}>
    <div class="columns">
      <FormField label="ID" col=3 bind:value={record._idSuffix} error={fieldErrors.publicId} prefix="{PREFIXES[record.type] ?? '?'}-" />
      <SelectField label="Typ" icon="dice-d6" col=3 bind:value={record.type} error={fieldErrors.type} options={Object.entries(auditObjectTypes)} />
      {#if !embeddedRecord}
        <FormField label="Kunde" icon="industry" col=3 value={customerName} readonly />
        <FormField label="Liegenschaft" icon="building" col=3 value={propertyName} readonly />
      {/if}
    </div>

    <div class="columns">
      <BarcodeField label="Barcode-Nummer" icon="tag" col={(record.oldId ? 7 : 10) - (record.type === 'door' || record.type === 'fireDoor' ? 2 : 0)} bind:value={record.barcode} error={fieldErrors.barcode} />
      {#if record.oldId}
        <FormField label="Alte ID" col=3 value={record.oldId} readonly />
      {/if}
      {#if record.type === 'door' || record.type === 'fireDoor'}
        <FormField label="Stock" icon="layer-group" col=2 bind:value={record.floor} error={fieldErrors.floor} />
      {/if}
      <SwitchField label="Stillgelegt?" col=2 bind:value={record.decommissioned} error={fieldErrors.decommissioned} />
    </div>

    <div class="columns">
      <FormField label="Hersteller" icon="hammer" col=5 bind:value={record.manufacturer} error={fieldErrors.manufacturer} placeholder="Unbekannt" />
      <FormField label="Seriennummer" icon="hashtag" col=5 bind:value={record.serialNo} error={fieldErrors.serialNo} placeholder="Unbekannt" />
      <FormField label="Baujahr" type="number" icon="calendar" col=2 bind:value={record.year} error={fieldErrors.year} placeholder="Unbekannt" />
    </div>

    {#if ['door', 'gate', 'fireDoor', 'fireGate'].includes(record.type)}
      <div class="columns">
        <FormField label="Flügelgewicht" type="number" icon="weight-hanging" col=3 bind:value={record.wingWeight} error={fieldErrors.wingWeight} suffix="kg" placeholder="Unbekannt" />
        {#if record.type === 'door'}
          <SelectField label="Türblattausführung" icon="th-large" col=3 bind:value={record.doorVariation} error={fieldErrors.doorVariation} options={doorVariations.selectOptions()} />
        {:else if record.type === 'gate'}
          <SelectField label="Torblattausführung" icon="th-large" col=3 bind:value={record.gateVariation} error={fieldErrors.gateVariation} options={gateVariations.selectOptions()} />
        {:else if record.type === 'fireDoor' || record.type === 'fireGate'}
          <SelectField label="Klassifikation" icon="certificate" col=3 bind:value={record.fireClass} error={fieldErrors.fireClass} options={fireClasses.selectOptions()} />
        {/if}
        <FormField label="Lichte Durchgangsbreite" type="number" icon="arrows-alt-h" col=3 bind:value={record.clearPassageWidth} error={fieldErrors.clearPassageWidth} suffix="mm" placeholder="Unbekannt" />
        <FormField label="Lichte Durchgangshöhe" type="number" icon="arrows-alt-v" col=3 bind:value={record.clearPassageHeight} error={fieldErrors.clearPassageHeight} suffix="mm" placeholder="Unbekannt" />
      </div>
    {/if}

    {#if record.type === 'door' || record.type === 'gate'}
      <div class="columns">
        {#if record.type === 'door'}
          <SelectField label="Türtyp" icon="sliders-h" col=3 bind:value={record.doorType} error={fieldErrors.doorType} options={doorTypes.selectOptions()} />
        {:else if record.type === 'gate'}
          <SelectField label="Tortyp" icon="sliders-h" col=3 bind:value={record.gateType} error={fieldErrors.gateType} options={gateTypes.selectOptions()} />
        {/if}
        <FormField label="Motor" icon="cogs" col=3 bind:value={record.engine} error={fieldErrors.engine} placeholder="Unbekannt" />
        <FormField label="Steuerung" icon="tachometer-alt" col=3 bind:value={record.controller} error={fieldErrors.controller} placeholder="Unbekannt" />
        {#if record.type === 'door'}
          <SelectField label="Flügelanzahl" icon="door-open" col=3 bind:value={record.numberOfWings} error={fieldErrors.numberOfWings} options={numberOfWingsOptions.selectOptions()} />
        {:else if record.type === 'gate'}
          <FormField label="Federspannung" icon="level-up-alt" type="text" col=3 bind:value={record.springTension} error={fieldErrors.springTension} placeholder="Unbekannt" />
        {/if}
      </div>
    {/if}

    {#if record.type === 'boomBarrier'}
      <div class="columns">
        <FormField label="Gewicht" type="number" icon="weight-hanging" col=4 bind:value={record.weight} error={fieldErrors.weight} suffix="kg" placeholder="Unbekannt" />
        <FormField label="Baumlänge" type="number" icon="ruler" col=4 bind:value={record.barLength} error={fieldErrors.barLength} suffix="mm" placeholder="Unbekannt" />
        <FormField label="Federspannung" icon="level-up-alt" type="text" col=4 bind:value={record.springTension} error={fieldErrors.springTension} placeholder="Unbekannt" />
      </div>
      <div class="columns">
        <FormField label="Motor" icon="cogs" col=6 bind:value={record.engine} error={fieldErrors.engine} placeholder="Unbekannt" />
        <FormField label="Steuerung" icon="tachometer-alt" col=6 bind:value={record.controller} error={fieldErrors.controller} placeholder="Unbekannt" />
      </div>
    {/if}

    {#if record.type === 'fireDoor' || record.type === 'fireGate'}
      <div class="columns">
        {#if record.type === 'fireDoor'}
          <SwitchField label="Ausgewiesener Fluchtweg?" col=3 bind:value={record.escapeRoute} error={fieldErrors.escapeRoute} />
          <SwitchField label="E-Öffner/Motorschloss?" col=3 bind:value={record.electricOpener} error={fieldErrors.electricOpener} />
        {:else}
          <div class="column is-6"></div>
        {/if}
        <div class="column is-3"></div>
        <SelectField label="Flügelanzahl" icon="door-open" col=3 bind:value={record.numberOfWings} error={fieldErrors.numberOfWings} options={numberOfWingsOptions.selectOptions()} />
      </div>
    {/if}

    {#if record.type === 'door' || record.type === 'gate' || record.type === 'boomBarrier'}
      <div class="columns">
        <FormField label="Befehlsgeber der Anlage" icon="hand-point-right" col=12 bind:value={record.commander} error={fieldErrors.commander} placeholder="Unbekannt" />
      </div>
    {/if}

    <div class="columns">
      <FormField label="Interne Notizen" type="textarea" col=12 bind:value={record.notes} error={fieldErrors.notes} />
    </div>
  </TypePreSelector>
</ResourceForm>
