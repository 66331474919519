<script>
  export let topView
  export let deselectWhenTop = true

  import { createEventDispatcher, tick } from 'svelte'

  export let selected = null
  let internallySelected = selected

  let prevTopView = topView

  const dispatch = createEventDispatcher()

  $: if (selected !== internallySelected) {
    internallySelected = selected
    dispatch('select', selected)
  }
  $: if (topView !== prevTopView) {
    prevTopView = topView
    if (topView && deselectWhenTop && selected !== null) {
      tick().then(() => { // Maybe this was part of an openAndReplace
        if (topView && deselectWhenTop && selected !== null) selected = null
      })
    }
  }

  function select (id) {
    selected = id
  }
</script>

<slot {selected} {select} />
