<script>
  export let topView
  export let closeSelf
  export let closeHigher
  export let openAndReplace

  import ResourceView from '../components/ResourceView.svelte'
  import Detail from '../components/Detail.svelte'
  import { Button } from 'svelma'
  import ViewTagPrintSettings from './ViewTagPrintSettings.svelte'
  import FormField from '../components/FormField.svelte'
  import { apiUrl } from '../lib/env'

  let actionInProgress
  let silentReload

  let numberOfPages = 1
  let loading = false

  let mainRecord

  $: numberOfTags = (numberOfPages * mainRecord?.barcodePrint?.perPage?.horizontally * mainRecord?.barcodePrint?.perPage?.vertically) || 0

  async function submit () {
    loading = true

    try {
      window.open(`${apiUrl}/barcodePdf?numberOfPages=${Number(numberOfPages)}`)
    } finally {
      loading = false
    }
  }
</script>

<style lang="scss">
  .panel-block :global(.field) {
    flex: 1;
  }
</style>

<ResourceView
  {topView} {closeSelf} {closeHigher} {openAndReplace}
  id='~'
  title="Etikettendruck"
  bind:actionInProgress
  bind:silentReload
  mainSelect="barcodePrint"
  resourcePath="/settings"
  resourceName="Einstellungen"
  ViewMainForm={ViewTagPrintSettings}
  allowCreate={false}
  allowEdit={true}
  allowDelete={false}
  editTitle="Seite einrichten"
  bind:mainRecord
>
  <svelte:fragment slot="header">
    <div class="panel-block">
      {#if mainRecord}
        <span>
          <Detail title="Seitenrand links" icon="long-arrow-alt-left">
            {mainRecord.barcodePrint?.pageMargins?.left} mm
          </Detail>
          <Detail title="Seitenrand oben" icon="long-arrow-alt-up">
            {mainRecord.barcodePrint?.pageMargins?.top} mm
          </Detail>
          <Detail title="Seitenrand rechts" icon="long-arrow-alt-right">
            {mainRecord.barcodePrint?.pageMargins?.right} mm
          </Detail>
          <Detail title="Seitenrand unten" icon="long-arrow-alt-down">
            {mainRecord.barcodePrint?.pageMargins?.bottom} mm
          </Detail>
        </span>
      {:else}
        ...
      {/if}
    </div>

    <div class="panel-block">
      {#if mainRecord}
        <span>
          <Detail title="Anzahl Spalten" icon="grip-lines-vertical">
            {mainRecord.barcodePrint?.perPage?.horizontally}
          </Detail>
          <Detail title="Anzahl Zeilen" icon="grip-lines">
            {mainRecord.barcodePrint?.perPage?.vertically}
          </Detail>
          <Detail title="Mindestabstand waagrecht" icon="arrows-alt-h">
            {mainRecord.barcodePrint?.minPadding?.horizontally} mm
          </Detail>
          <Detail title="Mindestabstand senkrecht" icon="arrows-alt-v">
            {mainRecord.barcodePrint?.minPadding?.vertically} mm
          </Detail>
        </span>
      {:else}
        ...
      {/if}
    </div>

    <div class="panel-block">
      {#if mainRecord}
        <span>
          <Detail title="Code-Typ" icon="qrcode">
            {{ qr: 'QR-Code', upc: 'Barcode (UPC-A)' }[mainRecord.barcodePrint?.codeType] ?? '?'}
          </Detail>
        </span>
      {:else}
        ...
      {/if}
    </div>
  </svelte:fragment>

  <svelte:fragment slot="footer">
    <div class="panel-block">
      <FormField label="Anzahl Seiten" type="number" icon="copy" min=1 bind:value={numberOfPages} />
    </div>

    <div class="panel-block">
      <Button iconLeft="print" type="is-primary" outlined class="is-fullwidth" disabled={!(numberOfTags > 0)} on:click={submit} {loading}>{numberOfTags > 0 ? `${numberOfTags} Etiketten drucken` : `Drucken`}</Button>
    </div>
  </svelte:fragment>
</ResourceView>
