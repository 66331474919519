<script>
  export let topView
  export let closeSelf
  export let closeHigher
  export let openAndReplace
  export let id
  export let title
  export let defaultTitle
  export let width
  export let onSave = () => {}
  export let loaded = false
  export let actionInProgress = false
  export let hideId = id === '~'
  export let mainRecord
  export let mainSelect // undefined
  export let mainPopulate // undefined
  export let getTitle
  export let getChildTitle
  export let order // undefined
  export let select = 'id' // basically nothing
  export let filter // undefined
  export let populate // undefined
  export let handleLoad // undefined - signature is: async (id, mainRecord, loadItems, keepViews) => ({ items?, mainRecord? })
  export let items = []
  export let getItems
  export let icon
  export let childIcon
  export let resourcePath
  export let resourceName = 'Datensatz'
  export let childResourcePath
  export let childResourceName = 'Datensatz'
  export let childResourceGender = 'm'
  export let childLabelField
  export let ViewMainForm
  export let mainProps = {}
  export let ViewChildDetails
  export let childDetailsProps = {}
  export let ViewChildForm
  export let handleOpenDetails
  export let tabs
  export let tab
  export let search // function
  export let allowCreate = true
  export let allowCreateDespiteReadonly = false
  export let allowEdit = false
  export let allowDelete = false
  export let readonly = false
  export let editTitle = 'Ändern'
  export let viewTitle = 'Ansehen'
  export let deleteTitle = 'Löschen'
  export let canDelete = () => true
  export let afterDelete = async () => {}
  export let rowClass = ''

  $: childNewWord = ({ m: 'Neuer', f: 'Neue', n: 'Neues' }[childResourceGender])
  $: if (mainRecord && getTitle) title = getTitle(mainRecord)

  import { apiCall } from '../lib/api'
  import { open } from '../stores/viewStack'
  import Loader from '../components/Loader.svelte'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import Pane from '../components/Pane.svelte'
  import PaneRow from '../components/PaneRow.svelte'
  import PaneList from '../components/PaneList.svelte'
  import { Button, Input, Toast, Dialog, Icon } from 'svelma'
  import regexEscape from 'regex-escape'
  import { tick } from 'svelte'
  import Dropdown from './Dropdown.svelte'

  export let handleCreateNewChildResource = async (id, mainRecord, afterCreate, ViewChildForm) => open(ViewChildForm, { create: true, parentId: id, onSave: afterCreate, readonly }, true)

  export let load = async function load (keepViews = false) {
    if (!keepViews) {
      loaded = false
      closeHigher()
    }
    if (resourcePath && id) {
      mainRecord = await apiCall('GET', id === '~' ? resourcePath : `${resourcePath}/${id}`, { select: mainSelect, populate: mainPopulate })
    }

    const loadItems = async () => await apiCall('GET', childResourcePath, { order, select, filter, populate })

    if (handleLoad) {
      const result = await handleLoad(id, mainRecord, loadItems, keepViews)
      if (result?.mainRecord !== undefined) mainRecord = result.mainRecord
      if (result?.items !== undefined) items = result.items
    } else if (childResourcePath) {
      items = await loadItems()
    }

    loaded = true
  }

  export let loadingPromise = load() // This must be at the end because it accesses variables

  // To avoid having items jump around
  export let silentReload = async function silentReload (record) {
    onSave(record)
    const promise = load(true)
    try {
      await promise
    } finally {
      loadingPromise = promise
    }
  }

  export let selected

  function openDetails (record = {}) {
    if (!record.id) {
      closeHigher()
    } else {
      const title = getChildTitle?.(record)
      if (ViewChildDetails) openAndReplace(ViewChildDetails, { id: record.id, onSave: silentReload, title, ...childDetailsProps })
    }
    handleOpenDetails?.(record)
  }

  async function afterCreate (record) {
    await tick()
    selected = record.id
    await silentReload(record)
  }

  function onSelect () {
    const record = items.find(i => i.id === selected) || { id: selected }
    openDetails(record)
  }

  async function deleteThis () {
    if (!canDelete(mainRecord, items)) {
      await Dialog.alert({ message: `${resourceName} kann nicht gelöscht werden!`, type: 'is-danger', icon: 'exclamation-circle' })
      return
    }

    if (await Dialog.confirm({
      message: `${resourceName} ${title} wirklich löschen?`,
      confirmText: 'Ja',
      cancelText: 'Nein',
      type: 'is-danger'
    })) {
      actionInProgress = true
      try {
        await apiCall('DELETE', `${resourcePath}/${id}`)
        Toast.create({ message: `${resourceName} wurde gelöscht.`, type: 'is-success' })
        await afterDelete()
        onSave({ id })
        closeSelf()
      } catch (e) {
        console.error(e)
        Dialog.alert({ message: escape(e.serverErrorMessage || `Die Änderungen konnten nicht gespeichert werden! Bitte erneut versuchen.\n\nTechnische Informationen: ${e}`, true), type: 'is-danger', icon: 'exclamation-circle' })
        actionInProgress = false
      }
    }
  }

  let creating = false
  async function createNewChildResource (id, mainRecord, afterCreate, ViewChildForm) {
    creating = true
    try {
      await handleCreateNewChildResource(id, mainRecord, afterCreate, ViewChildForm)
    } finally {
      creating = false
    }
  }

  export let searchQuery = ''
  $: searchRegex = searchQuery.trim() ? new RegExp(`\\b${regexEscape(searchQuery.trim())}`, 'i') : null
</script>

<style lang="scss">
  .id-display {
    padding-top: 2px;
    padding-bottom: 2px;

    span {
      font-size: 75%;
    }
  }

  .actions :global(.dropdown-content a.is-disabled) {
    text-decoration: none;
    cursor: not-allowed;
    opacity: 0.8;
  }
</style>

<Pane {icon} title={title || defaultTitle} closeable reloadable on:close on:close={closeSelf} on:reload on:reload={() => (loadingPromise = load(true))} {tabs} bind:tab {width}>
  <svelte:fragment slot="header">
    {#if id && !hideId}
      <div class="panel-block id-display">
        <span>Interne ID: {id}</span>
      </div>
    {/if}

    <slot name="header" {mainRecord} {items} {tab} />

    {#if allowEdit || allowDelete}
      <div class="panel-block">
        <div class="container">
          <div class="columns is-mobile">
            {#if allowEdit}
              <div class="column is-{((allowDelete && !readonly) || $$slots.actions) ? 6 : 12}">
                <Button iconLeft={readonly ? 'eye' : 'pen'} type="is-link" outlined class="is-fullwidth" disabled={actionInProgress} on:click={() => open(ViewMainForm, { id, title, onSave: r => { if (!selected) closeHigher(); return silentReload(r) }, mainProps, readonly }, true)}>{readonly ? viewTitle : editTitle}</Button>
              </div>
            {/if}
            {#if (allowDelete && !readonly) && !$$slots.actions}
              <div class="column is-{allowEdit ? 6 : 12}">
                <Button iconLeft="trash" type="is-danger" outlined class="is-fullwidth" disabled={!loaded || !canDelete(mainRecord, items)} loading={actionInProgress} on:click={() => deleteThis()}>{deleteTitle}</Button>
              </div>
            {:else if $$slots.actions}
              <div class="column is-{allowEdit ? 6 : 12} actions">
                <Dropdown forceClose={!loaded || actionInProgress} class="is-right">
                  <Button slot="trigger" iconRight="angle-down" type="is-default" outlined class="is-fullwidth" disabled={!loaded} loading={actionInProgress}>Aktionen</Button>

                  <slot name="actions" {mainRecord} {items} />

                  {#if allowDelete && !readonly}
                    <hr class="dropdown-divider" />
                    <a href={undefined} class="dropdown-item has-text-danger" class:is-disabled={!loaded || actionInProgress || !canDelete(mainRecord, items)} on:click={e => { if (!e.target.closest('a').classList.contains('is-disabled')) { deleteThis() } else { e.stopPropagation() } }}><Icon icon="trash" /> {deleteTitle}</a>
                  {/if}
                </Dropdown>
              </div>
            {/if}
          </div>
        </div>
      </div>
    {/if}

    {#if search}
      <div class="panel-block">
        <Input icon="search" placeholder="Suche" bind:value={searchQuery} />
      </div>
    {/if}

    <slot name="belowSearch" />
  </svelte:fragment>

  <slot name="subHeader" slot="subHeader" {tab} />

  {#await loadingPromise}
    <Loader />
  {:then}
    <PaneList bind:selected let:select {topView} on:select on:select={onSelect}>
      {#each getItems ? getItems(items, tab) : items as record (record.id)}
        {#if !searchRegex || search?.(searchQuery, searchRegex, record)}
          <PaneRow {selected} {select} id={record.id} icon={typeof childIcon === 'function' ? childIcon(record, tab) : childIcon} class={rowClass}>
            {#if childLabelField}
              <span class="list-entry">{record[childLabelField]}</span>
            {/if}
            <slot {record} {tab} />
          </PaneRow>
        {/if}
      {/each}

      <slot name="staticItems" {tab} />
    </PaneList>
  {:catch error}
    <ErrorDisplay {error} />
  {/await}

  <svelte:fragment slot="footer">
    {#if allowCreate && (!readonly || allowCreateDespiteReadonly)}
      <div class="panel-block">
        <Button iconLeft="plus" type="is-link" outlined class="is-fullwidth" loading={creating} on:click={() => createNewChildResource(id, mainRecord, afterCreate, ViewChildForm)}>{childNewWord} {childResourceName}</Button>
      </div>
    {/if}

    <slot name="footer" {tab} />
  </svelte:fragment>
</Pane>
